import React from "react"
import { Button, Form, Row, Col, Modal } from "antd"
import { faq, loading } from "redux/app"
import { useSelector } from "react-redux"
import { ContentWrapper, FieldName, ActionButtons, LoadingSpinner, Card } from "./styles"
import { Spin, Space } from "antd"
import { useNavigate } from "react-router-dom"
import TextArea from "antd/lib/input/TextArea"
import { useDispatch } from "react-redux"
import { useState } from "react"
import { useEffect } from "react"
import { singleFaq, updateFaq } from "redux/app/actions/faq"
import "./style.css"
const EditFAQ = ({ open, onCancel, faqId }) => {
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const faqData = useSelector(faq)

	useEffect(() => {
		dispatch(singleFaq(faqId))
	}, [faqId])
	useEffect(() => {
		form.setFieldsValue({
			question: faqData.question,
			answer: faqData.answer,
		})
	}, [faqData])
	const onFinish = (values) => {
		dispatch(updateFaq(faqId, values))
		onCancel()
	}
	return (
		<>
			<Modal width={800} open={open} onCancel={onCancel} footer={null} title="Edit FAQ">
				<ContentWrapper>
					<div className="main-modal">
							<Form
								className="addfaq-form"
								form={form}
								autoComplete="off"
								onFinish={onFinish}
							>
								<Row>
									<Col span={24}>
										<FieldName className="fieldName">
											Question <span>*</span>
										</FieldName>
										<Form.Item
											name="question"
											rules={[
												{
													required: true,
													message: "Please enter Question",
												},
												{ whitespace: true },
												{ min: 3 },
											]}
										>
											<TextArea />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<FieldName className="fieldName">
											Answer <span>*</span>
										</FieldName>
										<Form.Item
											name="answer"
											rules={[
												{ required: true, message: "Please enter Answer" },
												{ whitespace: true },
												{ min: 3 },
											]}
										>
											<TextArea />
										</Form.Item>
									</Col>
								</Row>
								<ActionButtons className="footer-btns">
									{/* <Button
										className="action-btn"
										onClick={() => {
											onCancel()
										}}
									>
										Cancel
									</Button> */}
									<Button className="action-btn save-btn " htmlType="submit">
										Update FAQ
									</Button>
								</ActionButtons>
							</Form>
					</div>
				</ContentWrapper>
			</Modal>
		</>
	)
}

export default EditFAQ
