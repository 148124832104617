import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper } from "./style"
import Eye from "assets/icons/eye.png"
import { Card, Popconfirm, Table } from "antd"
import { StatusBtn } from "./style"
import { useDispatch } from "react-redux"
import {useNavigate} from "react-router-dom"
import { useSelector } from "react-redux"
import { content } from "redux/app";
import { deleteContent, delete_content, getAllContent, updateContent } from "redux/app/actions/content"
import { Button } from "antd"
import { Link } from "react-router-dom"
import moment  from "moment"
import EditContent from "pages/AddContent/EditContent"
import "../EventManagement/styles.css"



const ContentPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const dispatch = useDispatch()
	const contentList = useSelector(content)
	const [contentId,setContentId]=useState("")
	const [viewContent,setViewContent]=useState(false);
	const [editContent,setEditContent]=useState(false)
	const navigate=useNavigate()

	useEffect(() => {
		dispatch(getAllContent())
	}, [])
	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Page Title",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY h:mm A")}</span>
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(updateContent(row?._id, !row?.status))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.status ? (
							<StatusBtn style={{ cursor: "pointer",backgroundColor:"green" }}>
								Active
							</StatusBtn>
						) : (
							<StatusBtn style={{ cursor: "pointer" }}>
								Inactive
							</StatusBtn>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<Link
						onClick={() => {
							setContentId(row._id)
							setEditContent(true)
						}}
					>
						<i
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
							}}
						></i>
					</Link>
					{/* <Popconfirm
						title="Are you sure , you want to delete the record?"
						onConfirm={() => {
							dispatch(delete_content(row._id))
						}}
						onCancel={() => {}}
						okText="Yes"
						cancelText="No"
					>
						{" "}
						<span style={{ cursor: "pointer" }}>
							<i
								class="fa-regular fa-trash-can"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</span>{" "}
					</Popconfirm> */}
				</>
			),
		},
	]
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Content Management</h2>
							<p>List of all content</p>
						</div>
						{/* <div className="right-in">
							<ActionBtn title="Add Page" link="/add-content" />
						</div> */}
					</div>
					<Card>
					<div className="card-header">
						<h3>Content Management</h3>
					</div>
					<Table
						pagination={{
							onChange(current, pageSize) {
								setPage(current)
								setPaginationSize(pageSize)
							},
							defaultPageSize: 10,
							hideOnSinglePage: false,
							showSizeChanger: true,
						}}
						columns={columns}
						dataSource={contentList}
					/>
					</Card>
					<EditContent open={editContent} onCancel={()=>{setEditContent(false)}} contentId={contentId}/>

					<Button type="primary" onClick={()=>{navigate("/add-content")}}>Add Content</Button>

				</div>
					
			</ContentWrapper>
		</>
	)
}

export default ContentPage
