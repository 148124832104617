import React from "react"
import { Input, Form, Modal, Select } from "antd"
import { booking, scannerTickets, ticket, ticketScanner } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"
import "./style.css"
import { singleTicketScanner } from "redux/app/actions/ticketScanner"
import { singleBooking } from "redux/app/actions/booking"

const ViewBooking = ({ open, handleOk, ticketId }) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const singleBookingData = useSelector(booking)

	useEffect(() => {
		dispatch(singleBooking(ticketId))
	}, [ticketId])

	useEffect(() => {
		form.setFieldsValue({
			totalAmount: singleBookingData?.totalAmount,
			organizer: singleBookingData?.organizer?.name,
			userId: singleBookingData?.userId?.name,
			eventId: singleBookingData?.eventId?.name,
			booking_fees: singleBookingData?.booking_fees,
			ticket_quantity: singleBookingData?.ticket_quantity,
			ticketsData: singleBookingData?.ticketsData?.map((value) => value.ticket_batch_name),
		})
	}, [singleBookingData])

	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="View Booking Detail"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form form={form} autoComplete="off">
						<div className="container createevent">
							<div className="addevent">
								<FieldName className="fieldName">Event Name</FieldName>
								<Form.Item
									name="eventId"
									rules={[{ required: true, message: "Please enter eventId" }]}
								>
									<Input disabled />
								</Form.Item>
								<div>
									<FieldName className="fieldName">Organizer Name</FieldName>
									<Form.Item
										name="organizer"
										rules={[
											{ required: true, message: "Please enter organizer" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Ticket Type</FieldName>
									<Form.Item
										name="ticketsData"
										rules={[
											{
												required: true,
												message: "Please enter ticketsData",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Ticket Quantity</FieldName>
									<Form.Item
										name="ticket_quantity"
										rules={[
											{ required: true, message: "Please enter organizer" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>

								{/* <div>
									<FieldName className="fieldName">Access Code</FieldName>
									<Form.Item
										name="access_code"
										rules={[
											{ required: true, message: "Please enter access_code" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Email</FieldName>
									<Form.Item
										name="email"
										rules={[{ required: true, message: "Please enter Email" }]}
									>
										<Input disabled />
									</Form.Item>
								</div> */}
							</div>
							<div>
								<div>
									<FieldName className="fieldName">User Name</FieldName>
									<Form.Item
										name="userId"
										rules={[{ required: true, message: "Please enter userId" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Total Amount</FieldName>
									<Form.Item
										name="totalAmount"
										rules={[
											{
												required: true,
												message: "Please enter total amount",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Booking Fees</FieldName>
									<Form.Item
										name="booking_fees"
										rules={[
											{
												required: true,
												message: "Please enter booking_fees",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default ViewBooking
