import React, { useState } from "react"
import { Button, Input, Form, Upload, Card, Modal } from "antd"
import { loading } from "redux/app"
import { useSelector, useDispatch } from "react-redux"
import { CreateInterest, UpdateAInterest } from "redux/app/actions/interests"
import { ContentWrapper, InputWrapper, FieldName, ActionButtons, LoadingSpinner } from "./style"
import { Spin, Space } from "antd"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import Placeholder from "assets/icons/placeholder.png"



const AddInterest = ({ open, handleCancel }) => {
	const [data, setData] = useState({})
	const [submitted, setSubmitted] = useState(false)
	const [imgFile, setImgFile] = useState([])
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [genreModal, setGenreModal] = useState(false)
	const dataLoading = useSelector(loading)
	const beforeUpload = () => {
		return false
	}
	const onFinish = (values) => {
		form.setFieldsValue({
			image: data?.image,
		})
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null

		dispatch(CreateInterest(values, image))
		navigate(-1)
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={()=>handleCancel(form.resetFields())}
				title="Add New Genre"
				width={900}
				height={500}
				footer={null}
			>
				<div className="main-modal">
					<Form
						className="addgenre-form"
						onFinish={onFinish}
						form={form}
						autoComplete="off"
					>
						<InputWrapper>
							<div className="avatar-wrapper">
								<Upload
									name="image"
									listType="picture-card"
									beforeUpload={beforeUpload}
									action={process.env.Action}
									fileList={imgFile}
									onChange={({ fileList: newFileList }) => {
										setImgFile(newFileList)
									}}
								>
									{imgFile?.length < 1 && (
										<img style={{ width: "100%", borderRadius: "100%" }} src={Placeholder} />
									)}
								</Upload>
							</div>
						</InputWrapper>
						<div>
							<FieldName
							className="fieldName"
								style={{ color: "#000", fontFamily: "Myriad Pro,sans-serif" }}
							>
								Name <span>*</span>
							</FieldName>
							<Form.Item
								name="name"
								rules={[
									{ required: true, message: "Please enter Genre Name" },
									{ whitespace: true },
									{ min: 3 },
								]}
							>
								<Input />
							</Form.Item>
						</div>
						<ActionButtons className="footer-btns">
							<Button
								className="action-btn"
								onClick={() => {
									navigate(-1)
									form.resetFields()
								}}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								className="action-btn save-btn"
								style={{marginLeft: "10px"}}
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Save
							</Button>
							<LoadingSpinner>
								{dataLoading && (
									<Space size="middle">
										<Spin size="large" />
									</Space>
								)}
							</LoadingSpinner>
						</ActionButtons>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default AddInterest
