import React, { useState } from "react"
import { Input, Form, Modal, Select, Button, Upload } from "antd"
import { events, profileData } from "redux/app"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { InputWrapper, FieldName } from "./style"
import { getAllEvents } from "redux/app/actions/event"
import "./style.css"
import { notification } from "antd"
import Placeholder from "assets/icons/placeholder.png"
import { singleTicketScanner, addTicketScanner } from "redux/app/actions/ticketScanner"

const AddTicketScanner = ({ open, handleOk, ticketId }) => {
	const [data, setData] = useState({})
	const [submitted, setSubmitted] = useState(false)
	const userProfileData = useSelector(profileData)
	const Organizer_Events = useSelector(events)
	const dispatch = useDispatch()
	const [organizerId, setOrganizerId] = useState("")
	const [Event_id, setEventId] = useState("")
	const [form] = Form.useForm()
	const [imgFile, setImgFile] = useState([])
	const beforeUpload = () => {
		return false
	}

	function onChange(value) {
		console.log(`selected ${value}`)
		setEventId(value)
	}

	function onBlur() {
		console.log("blur")
	}

	function onFocus() {
		console.log("focus")
	}

	function onSearch(val) {
		console.log("search:", val)
	}

	useEffect(() => {
		dispatch(singleTicketScanner(ticketId))
	}, [dispatch, ticketId])

	useEffect(() => {
		dispatch(getAllEvents())
	}, [dispatch])

	useEffect(() => {
		setOrganizerId(userProfileData?._id)
	}, [userProfileData._id])

	const editATicket = (value) => {
		form.setFieldsValue({
			image: data?.image,
		})
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		console.log(imgFile.length, "imgFile imgFileimgFile")
		return imgFile.length === 0
			? notification["error"]({
					message: "Please upload image file!",
					duration: 2,
			  })
			: (dispatch(addTicketScanner(value, Event_id, image)), handleOk())
	}
	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="Add Ticket Scanner"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form onFinish={editATicket} form={form} autoComplete="off">
						<div className="container createevent new-ticket">
							<div className="addevent">
								<InputWrapper>
									<div className="avatar-wrapper">
										<Upload
											name="image"
											listType="picture-card"
											beforeUpload={beforeUpload}
											action={process.env.Action}
											fileList={imgFile}
											onChange={({ fileList: newFileList }) => {
												setImgFile(newFileList)
											}}
										>
											{imgFile?.length < 1 && (
												<img
													alt="one"
													style={{ width: "100%", borderRadius: "100%" }}
													src={Placeholder}
												/>
											)}
										</Upload>
									</div>
								</InputWrapper>
								<div>
									<FieldName className="fieldName">Ticket scanner name</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Ticket Scanner Email
									</FieldName>
									<Form.Item
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div className="organizer-div">
									<FieldName className="fieldName">Add Event</FieldName>
									<Form.Item
										name="send_to"
										rules={[
											{
												required: true,
												message: "Please select events",
											},
										]}
									>
										<Select
											mode="multiple"
											showSearch
											style={{ width: 400 }}
											placeholder="Please select events"
											optionFilterProp="children"
											onChange={onChange}
											onFocus={onFocus}
											onBlur={onBlur}
											onSearch={onSearch}
											filterOption={(input, option) => {
												console.log(
													option.props.label,
													input,
													"option.props.label, input"
												)
												return (
													option.props.label
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												)
											}}
											options={
												Organizer_Events?.map((i) => ({
													label: i.name,
													value: i._id,
												})) ?? []
											}
										/>
									</Form.Item>
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button className="cancel-btn" onClick={handleOk}>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Add Ticket Scanner
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default AddTicketScanner
