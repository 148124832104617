import React, { useState } from "react"
import { Input, Form, Modal, Select, Button } from "antd"
import { events, organizer, ticket } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { editTicket, singleTicket } from "redux/app/actions/ticket"
import moment from "moment"
import { getOrganizerEvents, getOrganizers } from "redux/app/actions/event"
import "./style.css"
import { Option } from "antd/lib/mentions"

const EditTicket = ({ open, handleOk, ticketId }) => {
	const organizerData = useSelector(organizer)
	const Organizer_Events = useSelector(events)
	const [data, setData] = useState({})
	const dispatch = useDispatch()
	const [organizerId, setOrganizerId] = useState("")
	const [submitted, setSubmitted] = useState(false)
	const [Event_id, setEventId] = useState("")
	const [form] = Form.useForm()
	const ticketData = useSelector(ticket)

	useEffect(() => {
		dispatch(singleTicket(ticketId))
	}, [ticketId])

	useEffect(() => {
		dispatch(getOrganizers())
	}, [])

	useEffect(() => {
		setData(ticketData)
		if (ticketData?.eventId) {
			setEventId(ticketData?.eventId?._id)
		}
		if (ticketData?.userId) {
			setOrganizerId(ticketData?.userId?._id)
		}
	}, [ticketData])
	useEffect(() => {
		console.log(organizerId, "organizer id")
		if (organizerId.length > 0) {
			dispatch(getOrganizerEvents(organizerId))
		}
	}, [organizerId])

	useEffect(() => {
		form.setFieldsValue({
			ticket_batch_name: data?.ticket_batch_name,
			ticket_sale_start_date: moment(data?.ticket_sale_start_date).format("YYYY-MM-DD"),
			ticket_sale_start_time: data.ticket_sale_start_time,
			// ticket_sale_end_date: moment(data?.ticket_sale_end_date).format("YYYY-MM-DD"),
			// ticket_sale_end_time: data.ticket_sale_end_time,
			max_ticket: data?.max_ticket,
			min_ticket: data?.min_ticket,
			ticket_quantity: data?.ticket_quantity,
			price: data?.price,
		})
	}, [data])
	const editATicket = (value) => {
		dispatch(editTicket(value, organizerId, Event_id, ticketId))
		handleOk()
	}
	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="Edit Ticket"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form onFinish={editATicket} form={form} autoComplete="off">
						<div className="container createevent new-ticket">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">Batch Name</FieldName>
									<Form.Item
										name="ticket_batch_name"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div className="organizer-div">
									<FieldName className="fieldName">Add Event</FieldName>
									<Select
										value={Event_id}
										onChange={(value) => {
											setEventId(value)
										}}
										style={{ width: 380 }}
									>
										{Organizer_Events?.map((item) => (
											<Option value={item?._id}>{item?.name}</Option>
										))}
									</Select>
								</div>
								<div>
									<FieldName className="fieldName">Quantity</FieldName>
									<Form.Item
										name="ticket_quantity"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Start Sale Date</FieldName>
									<Form.Item
										name="ticket_sale_start_date"
										rules={[
											{ required: true, message: "Please enter Start Date" },
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
								{/* <div>
									<FieldName className="fieldName">End Sale Date</FieldName>
									<Form.Item
										name="ticket_sale_end_date"
										rules={[
											{ required: true, message: "Please enter Start Date" },
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div> */}
								<div>
									<FieldName className="fieldName">
										Minimum Ticket By Order
									</FieldName>
									<Form.Item
										name="min_ticket"
										rules={[
											{
												required: true,
												message: "Please enter Minimum Tickets",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
							</div>
							<div>
								<div className="event-div">
									<FieldName className="fieldName">Add Organizer</FieldName>
									<Select
										value={organizerId}
										onChange={(value) => {
											setOrganizerId(value)
										}}
										style={{ width: 380 }}
									>
										{organizerData.map((item) => (
											<Option value={item._id}>{item.name}</Option>
										))}
									</Select>
								</div>
								<div>
									<FieldName className="fieldName">Price</FieldName>
									<Form.Item
										name="price"
										rules={[
											{
												required: true,
												message: "Please enter Price",
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Start Sale Time</FieldName>
									<Form.Item
										name="ticket_sale_start_time"
										rules={[
											{ required: true, message: "Please enter End Time" },
										]}
									>
										<Input type="time" />
									</Form.Item>
								</div>
								{/* <div>
									<FieldName className="fieldName">End Sale Time</FieldName>
									<Form.Item
										name="ticket_sale_end_time"
										rules={[
											{ required: true, message: "Please enter End Time" },
										]}
									>
										<Input type="time" />
									</Form.Item>
								</div> */}
								<div>
									<FieldName className="fieldName">
										Maximum Tickets By Order
									</FieldName>
									<Form.Item
										name="max_ticket"
										rules={[
											{
												required: true,
												message: "Please enter Maximum Tickets",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button className="cancel-btn" onClick={handleOk}>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn save-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Edit Ticket
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default EditTicket
