import React, { useState } from "react"
import { Layout } from "antd"
import { useNavigate } from "react-router-dom"
import NavMenu from "../NavMenu"
import { LogoWrapper } from "./styles"
import logo from "assets/icons/logoblack.png"
import theme from "styles/Theme"

const { Sider } = Layout

const Sidebar = () => {
	const [collapsed, setCollapsed] = useState(false)
	const navigate = useNavigate()

	return (
		<Sider
			collapsible
			width={220}
			collapsedWidth={55}
			collapsed={collapsed}
			onCollapse={(c) => setCollapsed(c)}
			style={{
				paddingLeft: 0,
				paddingRight: 0,
				boxShadow: "0 0 3px 3px #ebeaea",
			}}
		>
			<LogoWrapper className="custom-logo" onClick={() => navigate("/")}>
				{collapsed ? <span style={{width:"auto" , color: "#000", fontWeight: "700", fontSize: "32px", textAlign: "center"}}><img src={logo} alt="" /></span> : <span style={{width:"auto", margin:'0 auto', color: "#000" , fontWeight: "700", fontSize: "22px", textAlign: "center"}}><img src={logo} alt="" /></span>}
			</LogoWrapper>

			<NavMenu collapsed={collapsed} />
		</Sider>
	)
}

export default Sidebar
