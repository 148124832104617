import styled from "styled-components"
import theme from "styles/Theme"

export const LayoutWrapper = styled.div`
	height: 100%;
	background: #ffffff;
	// overflow-y: hidden;

	.ant-menu-inline {
		border-right: none;
	}
	.main-contnt {
		padding:50px 40px 40px 40px;
	}
	
	.header-toprow {
		background: #ffffff;
		z-index: 1;
	}
	.card-dashboard p {
		font-size: 16px;
		margin: 0;
	}
	
	h5.events-total {
		margin: 0;
		color: #002140;
		font-size: 30px;
		font-weight: 600;
	}
	.card-dashboard {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.card-dashboard {
		background: #fff;
		padding: 20px;
		border-radius: 10px;
		box-shadow: 0 0 7px 4px #235cea1a;
		margin-right: 10px;
	}
	.ant-menu-title-content {
		color: #fff;
	}
	.chart-first {
		border: 1px solid #eee;
		border-radius: 10px;
		margin: 60px 10px 40px 10px;
		padding: 20px !important;
	}
	#chart {
		width: 100% !important;
	}
	.ant-menu-item > .anticon {
		font-size: 1.25rem !important;
		margin-right: 0.625rem;
	}

	.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
		margin: 0;
		padding: 0.55rem 0 0 1.25rem !important;
		border-radius: 0 !important;
		& > .anticon {
			transform: translateY(0.375rem) translateX(-0.125rem);
		}
	}

	.ant-menu-item {
		width: auto !important;
		height: 3rem !important;
		border-radius: 0.25rem !important;
		padding-left: 0.875rem !important;
	}

	.ant-menu-item-selected .ant-menu-title-content {
		font-size: 14px;
		color: #ffffff;
		.ant-btn {
			background-color: #235cea !important;
			border-color: #235cea !important;
		}
	}

	.ant-menu-item-selected {
		background: #235cea !important;
		width: auto !important;
		color: white !important;
		height: 3rem !important;
		border-radius: 0.25rem !important;
		transition: none;
		svg {
			path {
				fill: white;
			}
		}
	}

	.ant-menu-item::after {
		border-right: none !important;
	}

	.ant-menu-vertical {
		border-right: none;
	}

	.ant-layout-sider-light .ant-layout-sider-trigger {
		left: 0;
	}
	.ant-layout-sider-trigger {
		width: 219px;
		left: 0;
	}
	::placeholder {
		color: #000 !important;
	}
	
	.ant-layout-sider {
		background: "#fff !important";
	}
	.ant-layout-sider-children {
		background: #fff;
	}
 
`

export const AddProjectIcon = styled.div`
	width: 3.5rem;
	height: 3.5rem;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f4f4f5;
	margin-right: 20px;
	border-radius: 5px;
	text-align: center;
`

export const AddProjectWrapper = styled.div`
	.top-row {
		border-bottom: 1px solid ${theme.colors.border};
		padding-bottom: 10px;
	}

	.ant-input-affix-wrapper {
		border-color: #f4f4f5;
		&:hover,
		&.ant-input-affix-wrapper-focused {
			border-radius: 4px;
			border-color: #014c97 !important;
			box-shadow: none;
		}
	}

	.ant-input {
		border-color: #f4f4f5;
		border-radius: 4px;
		box-shadow: none;
		resize: none;
		margin: 0;
		&:focus {
			border-color: #014c97;
		}
		&:hover {
			border-color: #014c97;
			box-shadow: none;
		}
	}

	.ant-input-show-count-suffix {
		color: #bfbfbf;
	}
	.ant-input-textarea-show-count {
		&::after {
			color: #bfbfbf !important;
		}
	}
	
	table {
		thead {
			th {
				background-color: #fff;
				border: none;
				font-weight: bold;
				color: #393939;
				&::before {
					display: none;
				}
			}
		}
		tbody {
			tr {
				transition: 0.3s all ease-in-out;
				&:hover {
					background-color: #f4f4f5;
					td {
						background-color: transparent !important;
					}
				}
				td {
					color: #939597;
				}
			}
		}
	}
`

export const InputWrapper = styled.div`
	font-family: Myriad Pro;
	.project-heading {
		font-size: 1.125rem;
		font-weight: 600;
	}
	.project-description {
		font-size: 0.81rem;
		font-weight: 400;
	}
`

export const TopHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 98%;
`

export const TopHeaderLeftSide = styled.div`
	display: flex;
	align-items: center;

	.ant-btn-primary {
		padding: 8px 30px;
		height: auto;
		border: none;
		background: #235cea;
	}
`

export const BodyWrapper = styled.div`
	align-items: center;
	justify-content: center;
	min-height: 80vh;
	display: flex;
` // flex-direction: column;
// margin-top: 200px !important;
