import { Button, Card, Popconfirm, Spin, Table } from "antd"
import Search from "antd/lib/transfer/search"
import React, { useEffect, useState } from "react"
import { ContentWrapper } from "./style"
import GreyStar from "../../assets/icons/greystar.png"
import ProfileNot from "../../assets/icons/profile.png"
import YellowStar from "../../assets/icons/yellowstar.png"
import "./styles.css"
import { deleteNotification, getAllNotification } from "redux/app/actions/notification"
import { notifications, loading } from "redux/app"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { AlertOutlined } from "@ant-design/icons"

export const Notification = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [selectOneRow, setSelectOneRow] = useState({})
	const [loading, setLoading] = useState(false)
	const [star, setStar] = useState(false)
	const getNotifications = useSelector(notifications)
	const refresh = () => window.location.reload(false)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const start = () => {
		setLoading(true)
		// ajax request after empty completing
		setTimeout(() => {
			setSelectedRowKeys([])
			setLoading(false)
		}, 1000)
	}
	const hasSelected = selectedRowKeys.length > 0

	useEffect(() => {
		dispatch(getAllNotification())
	}, [])

	const columns = [
		// {
		// 	render: (row) => (
		// 		<>
		// 			<img
		// 				src={star ? YellowStar : GreyStar}
		// 				alt=""
		// 				style={{ height: "24px", cursor: "pointer" }}
		// 				onClick={() => {
		// 					setStar(true)
		// 				}}
		// 			/>
		// 		</>
		// 	),
		// },

		{
			key: ["user", "content"],
			render: (item, row) => {
				return (
					<div>
						<div className="information-div">
							<div>
								<img
									src={
										row?.user?.image?.length > 0 ? row?.user?.image : ProfileNot
									}
									placeholder="Image"
								/>
							</div>
							<div>
								<h6 className="blue m-0">{row?.user?.name}</h6>
								<p className="gray mb-0">
									{row?.content.length > 20 &&
										row?.content.substring(0, 99) + "..."}
								</p>
							</div>
						</div>
					</div>
				)
			},
			// dataIndex: '_id',
			// sorter: (a, b) => a._id?.user?.name.localeCompare(b._id?.user?.name),
		},
		{
			dataIndex: "_id",
			key: "_id",
			title: (value) => {
				return (
					<div>
						<div className="icons-div">
							{/* <div>
								<i
									class="fa fa-refresh"
									aria-hidden="true"
									onClick={()=>{getNotifications ?? {refresh}}}
								></i>
							</div> */}
							{/* <div>
								<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							</div> */}
							<div>
								{/* <i
									class="fa fa-trash"
									aria-hidden="true"
									onClick={() =>
										dispatch(deleteNotification(selectedRowKeys.join(",")))
									}
								></i> */}
								
								<Popconfirm
									title="Are you sure , you want to delete the notification?"
									onConfirm={() => {
										hasSelected &&
											dispatch(deleteNotification(selectedRowKeys.join(",")))
									}}
									onCancel={() => {}}
									okText="Yes"
									cancelText="No"
								>
									{" "}
									<span>
										<i
											class="fa fa-trash"
											aria-hidden="true"
											// onClick={
											// 	hasSelected &&
											// 	dispatch(
											// 		deleteNotification(selectedRowKeys.join(","))
											// 	)
											// }
										></i>
									</span>{" "}
								</Popconfirm>
							</div>
						</div>
					</div>
				)
			},
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value) => {
				return <span>{moment(value).format("MMMM D, YYYY")}</span>
			},
		},
	]

	return (
		<ContentWrapper>
			<div className="main-contnt">
				<div className="genre-box">
					<p>
						Notifications <span>Welcome to Event</span>
					</p>
				</div>
				<div className="main-div">
					<span style={{ marginLeft: 8 }}>{hasSelected}</span>
				</div>
				<Card>
					<Table
						rowKey={(record) => record.id}
						rowSelection={{
							selectedRowKeys,
							onChange: (selectedRowKeys) => {
								setSelectedRowKeys(selectedRowKeys)
							},
						}}
						columns={columns}
						dataSource={getNotifications}
						// loading={{
						// 	indicator: (
						// 		<div>
						// 			<Spin size="large" />
						// 		</div>
						// 	),
						// 	spinning: getNotifications?.length,
						// }}
					/>
				</Card>
			</div>
		</ContentWrapper>
	)
}
