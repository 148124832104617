import React, { useState } from "react"
import { Input, Form, Row, Col, Modal, Button, Spin, Space } from "antd"
import { loading, singleContent } from "redux/app"
import { useDispatch, useSelector } from "react-redux"
import { ContentWrapper, FieldName, Card, ActionButtons, LoadingSpinner } from "./styles"
import { useEffect } from "react"
import "./style.css"
import { getSingleContent, update_Content } from "redux/app/actions/content"
import { convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "draft-js/dist/Draft.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { stateFromHTML } from "draft-js-import-html"

const EditContent = ({ open, onCancel, contentId }) => {
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [form] = Form.useForm()
	const contentData = useSelector(singleContent)
	const dispatch = useDispatch()
	const dataLoading = useSelector(loading)
	const [html, setHtml] = useState(null)

	useEffect(() => {
		dispatch(getSingleContent(contentId))
	}, [contentId])

	useEffect(() => {
		setHtml(contentData?.content)
		form.setFieldsValue({
			type: contentData?.type,
			title: contentData?.title,
		})
		const contentState = stateFromHTML(contentData?.content)
		const editorState = EditorState.createWithContent(contentState)
		setEditorState(editorState)
	}, [contentData])

	const editContent = (value) => {
		dispatch(update_Content(contentId, value, html))
		onCancel()
	}
	const handleChange = (state) => {
		setEditorState(state)
		convertContentToHTML()
	}
	const convertContentToHTML = () => {
		const currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
		setHtml(currentContentAsHTML)
	}

	return (
		<>
			<Modal
				footer={null}
				width={900}
				title="Update Content"
				open={open}
				onCancel={onCancel}
			>
				<Form form={form} onFinish={editContent} autoComplete="off">
					<Row>
						<Col span={12}>
							<FieldName className="fieldName">
								Page Type <span>*</span>
							</FieldName>
							<Form.Item
								name="type"
								rules={[
									{
										required: true,
										message: "Please enter page type",
									},
								]}
							>
								<Input readOnly />
							</Form.Item>
						</Col>
						<Col span={12}>
							<FieldName className="fieldName">
								Page Title <span>*</span>
							</FieldName>
							<Form.Item
								name="title"
								rules={[
									{
										required: true,
										message: "Please enter Page Title",
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col span={24}>
							<FieldName className="fieldName">
								Content <span>*</span>
							</FieldName>
							<Editor editorState={editorState} onEditorStateChange={handleChange} />
						</Col>
					</Row>
					<ActionButtons className="footer-btns">
						<Button
							onClick={() => {
								onCancel()
							}}
							className="action-btn"
						>
							Cancel
						</Button>
						<Button className="action-btn save-btn" type="primary" htmlType="submit" style={{marginLeft: "15px"}}>
							Update Content
						</Button>
						<LoadingSpinner>
							{dataLoading && (
								<Space size="middle">
									<Spin size="large" />
								</Space>
							)}
						</LoadingSpinner>
					</ActionButtons>
				</Form>
			</Modal>
		</>
	)
}

export default EditContent
