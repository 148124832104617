import React from "react"
import { Button, Form,Row, Col, Modal } from "antd"
import { faq, loading } from "redux/app"
import { useSelector } from "react-redux"
import { ContentWrapper, FieldName, ActionButtons, LoadingSpinner, Card } from "./styles"
import TextArea from "antd/lib/input/TextArea"
import { useDispatch } from "react-redux"

import { useEffect } from "react"
import { singleFaq } from "redux/app/actions/faq"

const ViewFaq = ({open, onCancle, faqId }) => {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const faqData = useSelector(faq)


	useEffect(() => {
		dispatch(singleFaq(faqId))
	}, [faqId])
	useEffect(() => {
		form.setFieldsValue({
			question: faqData.question,
			answer: faqData.answer,
		})
	}, [faqData])

	return (
		<>
			<Modal open={open} width={800} onCancel={onCancle} footer={null}>
				<ContentWrapper>
					<div className="main-contnt">
						<div className="top-header">
							<div className="left-in">
								<h2 className="mb-0">View FAQ</h2>
							</div>
						</div>
						<Card>
							<Form
								className="addfaq-form"
								form={form}
								autoComplete="off"
							>
								<Row>
									<Col span={24}>
										<FieldName className="fieldName">
											Question <span>*</span>
										</FieldName>
										<Form.Item
											name="question"
										>
											<TextArea disabled />
										</Form.Item>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<FieldName className="fieldName">
											Answer <span>*</span>
										</FieldName>
										<Form.Item
											name="answer"
									
										>
											<TextArea disabled />
										</Form.Item>
									</Col>
								</Row>
								
							</Form>
						</Card>
					</div>
				</ContentWrapper>
			</Modal>
		</>
	)
}

export default ViewFaq
