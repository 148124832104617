import React from "react"
import { Button, Input, Form, Modal } from "antd"
import { FieldName } from "../EventManagement/style"
import "./styles.css"
import { axiosIntercepter } from "redux/axios"
import { notification } from "antd"
import moment from "moment"

const PublishEvent = ({ open, handleCancel, eventId }) => {
	const [form] = Form.useForm()

	const publishNow = async () => {
		try {
			await axiosIntercepter.post("/event/change_status", {
				status: "publish",
				eventId,
			})
			notification["success"]({
				message: "Event published successfully!",
				duration: 2,
			})
			handleCancel()
		} catch (e) {
			notification["error"]({
				message: e.message,
				duration: 2,
			})
		}
	}

	const schedule = async (values) => {
		const date = values.date
		const publish_date = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
		const payload = {
			publish_date,
			publish_time: values.time,
			eventId,
			status: "scheduled",
		}
		try {
			await axiosIntercepter.post("/event/change_status", payload)
			notification["success"]({
				message: "Event scheduled successfully!",
				duration: 2,
			})
			handleCancel()
		} catch (e) {
			notification["error"]({
				message: e.message,
				duration: 2,
			})
		}
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={() => handleCancel(form.resetFields())}
				title="Do you want to publish this event?"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form form={form} onFinish={schedule}>
						<div className="container createevent add-ticket">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">Schedule Date</FieldName>
									<Form.Item
										name="date"
										rules={[
											{
												required: true,
												message: "Please enter schedule date",
											},
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">Schedule Time</FieldName>
									<Form.Item
										name="time"
										rules={[
											{
												required: true,
												message: "Please enter schedule time",
											},
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input type="time" />
									</Form.Item>
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								style={{ marginRight: "15px" }}
								onClick={publishNow}
							>
								Publish Now
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
							>
								Schedule
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default PublishEvent
