import { notification } from "antd"
import {
	requestCompleted,
	requestFailure,
	requestStart,
	addNewContent,
	removeContent,
	setContent,
	updateContentStatus,
	setSingleContent,
} from "redux/app"
import { axios } from 'lib/axios'
import { axiosIntercepter } from "redux/axios"

export function addContent(payload,html,navigate) {
	return async (dispatch) => {

		var data = JSON.stringify({
			type:payload.type,
			status:payload.status,
			title:payload.title,
			content:html
		})

		var config = {
			method: "post",
			url: "/content",
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
				"Content-Type": "application/json",
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: response.data.status_message,
						duration: 2,
					})
					dispatch(addNewContent(response.data.data))
					navigate('/content')
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Content Does Not Create Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function getAllContent() {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: "/content",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setContent(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteContent(payload, navigate) {
	return async (dispatch) => {
		var config = {
			method: "delete",
			url: `/content/${payload}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Content Deleted Successfully",
						duration: 2,
					})
					dispatch(removeContent(payload))
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Content Does Not Delete Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function updateContent(id, status) {
	return async (dispatch) => {
		var data = JSON.stringify({ status })
		var config = {
			method: "patch",
			url: `/content/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Status Updated Successfully",
						duration: 2,
					})
					dispatch(updateContentStatus({id, status}))
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Status Does Not Updated Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function delete_content(id) {
	return async (dispatch) => {
		 await axiosIntercepter.patch(`/content/delete/${id}`)
		return dispatch(removeContent(id))
	}
}

export function getSingleContent(id){
	return async (dispatch) => {
	const response=await axiosIntercepter.get(`/content?id=${id}`)
	   return dispatch(setSingleContent(response.data))
   }
}
export function update_Content(id,payload,html) {
	return async (dispatch) => {
		var data = JSON.stringify({
			type:payload.type,
			title:payload.title,
			content:html
		})
		var config = {
			method: "patch",
			url: `/content/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Content Updated Successfully",
						duration: 2,
					})
					 dispatch(getAllContent())
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Content Does Not Updated Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}