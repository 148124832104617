import React, { useState } from "react"
import { Button, Input, Form, Modal, Select, Upload, InputNumber } from "antd"
import { coupon, events, singleEvent } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "../EventManagement/style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getAllEvents, getEvent } from "redux/app/actions/event"
import { addTicket } from "redux/app/actions/ticket"
import "./style.css"
import { Option } from "antd/lib/mentions"
import { createOffer } from "redux/app/actions/offers"
import { addCoupon, getAllCoupon, singleCoupon } from "redux/app/actions/coupon"

const AddCoupon = ({ open, handleCancel, eventId, ticketCreated }) => {
	const dispatch = useDispatch()
	const couponData = useSelector(coupon)
	const [form] = Form.useForm()
	const [submitted, setSubmitted] = useState(false)
	const [status, setStatus] = useState(false)
	const [event_id, setEventId] = useState("")
	const event = useSelector(events)
	const [addCoverImage, setAddCoverImage] = useState([])
	const [data, setData] = useState({})

	useEffect(() => {
		dispatch(getAllCoupon())
	}, [])

	const createCoupon = (value) => {
		form.setFieldsValue({
			image: data?.image,
		})
		const coverImage = addCoverImage["0"] ? addCoverImage["0"]["originFileObj"] : null
		console.log(coverImage, "coverimage")
		dispatch(addCoupon(value, coverImage, status))

		handleCancel()
	}

	const beforeUpload = () => {
		return false
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={() => handleCancel(form.resetFields())}
				title="Add New Coupon"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<div className="event-upload">
						<Upload
							action={process.env.Action}
							listType="picture-card"
							fileList={addCoverImage}
							beforeUpload={beforeUpload}
							onChange={({ fileList: newFileList }) => {
								setAddCoverImage(newFileList)
							}}
						>
							{addCoverImage?.length < 1 && <span className="pls-icon">+</span>}
						</Upload>
					</div>
					<Form form={form} onFinish={createCoupon}>
						<div className="container createevent add-ticket">
							<div className="addevent">
								<div className="eventNameDiv">
									<FieldName className="fieldName">Title</FieldName>
									<Form.Item
										name="name"
										rules={[
											{
												required: true,
												message: "Please enter name",
											},
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Coupon Code</FieldName>
									<Form.Item
										name="coupon_code"
										rules={[
											{
												required: true,
												message: "Please enter Coupon code",
											},
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Max Users</FieldName>
									<Form.Item
										name="max_users"
										rules={[
											{
												required: true,
												message: "Please enter max_users",
											},
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Coupon Discount</FieldName>
									<Form.Item
										name="coupon_discount"
										rules={[
											{
												required: true,
												message: "Please enter coupon_discount",
											},
											// {
											// 	max: 2,
											// 	message: "Value should be less than 90",
											// },
											
										]}
									>
										<Input type="number"/>
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">Description</FieldName>
									<Form.Item
										name="description"
										rules={[
											{
												required: true,
												message: "Please enter description",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Expiry Date</FieldName>
									<Form.Item
										name="expiry_date"
										rules={[
											{
												required: true,
												message: "Please enter Expiry Date",
											},
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Select status</FieldName>
									<Select
										placeholder="Select"
										onChange={(value) => {
											setStatus(value)
										}}
										style={{ width: "100%" }}
									>
										<Option value={true}>Active</Option>
										<Option value={false}>Inactive</Option>
									</Select>
								</div>
								<div></div>
							</div>
						</div>
						<div className="modal-btns">
							<Button
								className="cancel-btn"
								onClick={() => {
									handleCancel(form.resetFields())
								}}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default AddCoupon
