import React, { useEffect, useState } from "react"
import { Button, Input, Form, Row, Col, Upload, Modal } from "antd"
import { userProfile } from "redux/app"
import { useSelector } from "react-redux"
import {
	ContentWrapper,
	InputWrapper,
	FieldName,
	ActionButtons,
	LoadingSpinner,
	Card,
} from "./styles"
import { useNavigate, useParams } from "react-router-dom"
import { getUserProfileById, updateProfileById } from "redux/app/actions/auth"
import Placeholder from "assets/icons/placeholder.png"
import { useDispatch } from "react-redux"

const EditUser = ({ open, handleCancel, userId }) => {
	const userData = useSelector(userProfile)
	const params = useParams()
	const [data, setData] = useState({})
	const [submitted, setSubmitted] = useState(false)
	const [imgFile, setImgFile] = useState([])
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (userId.length > 0) {
			dispatch(getUserProfileById(userId))
		}
	}, [userId])

	const beforeUpload = () => {
		return false
	}
	useEffect(() => {
		setData(userData)
		if (userData?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: userData?.image,
				},
			])
		}
	}, [userData])

	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			phoneNumber: data?.phoneNumber,
			email: data?.email,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])

	const updateUser = (values) => {
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		dispatch(updateProfileById(values, image, userId))
		handleCancel()
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={handleCancel}
				title="Edit User"
				width={900}
				height={500}
				footer={null}
			>
				<div className="avatar-wrapper" style={{ justifyContent: "center" }}>
							<Upload
								listType="picture-card"
								beforeUpload={beforeUpload}
								action={process.env.Action}
								fileList={imgFile}
								onChange={({ fileList: newFileList }) => {
									setImgFile(newFileList)
								}}
								onRemove={(value) => {
									setImgFile([])
								}}
							>
								{imgFile?.length < 1 && (
									<img style={{ width: "100%" }} src={Placeholder} />
								)}
							</Upload>
						</div>
						<div className="mt-30">
							<Form onFinish={updateUser} form={form} autoComplete="off">
								<Row className="profile-form">
									<Col span={12}>
										<FieldName
											style={{
												color: "#000",
												fontFamily: "Myriad Pro,sans-serif",
											}}
										>
											Name
										</FieldName>
										<Form.Item
											name="name"
											rules={[
												{ required: true, message: "Name is required" },
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<FieldName
											style={{
												color: "#000",
												fontFamily: "Myriad Pro,sans-serif",
											}}
										>
											Email
										</FieldName>
										<Form.Item name="email">
											<Input disabled />
										</Form.Item>
									</Col>
									<Col span={24}>
										<FieldName
											style={{
												color: "#000",
												fontFamily: "Myriad Pro,sans-serif",
											}}
										>
											Phone Number
										</FieldName>
										<Form.Item
											name="phoneNumber"
											// rules={[
											// 	{
											// 		required: true,
											// 		message: "Phone number is required",
											// 	},
											// 	{
											// 		pattern: /^[0-9]+$/,
											// 		message: "Incorrect Phone Number",
											// 	},
											// 	{
											// 		min: 5,
											// 		message:
											// 			"Phone Number must be minimum 9 characters.",
											// 	},
											// 	{
											// 		max: 15,
											// 		message:
											// 			"Phone Number must be minimum 12 characters.",
											// 	},
											// ]}
										>
											<Input disabled />
										</Form.Item>
									</Col>
									<Col span={24} style={{ textAlign: "right" }}>
										<Button
											className="action-btn save-btn "
											type="primary"
											htmlType="submit"
											onClick={() => {
												setSubmitted(true)
											}}
										>
											Update User
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
			</Modal>
		</>
	)
}

export default EditUser
