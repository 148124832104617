import { notification } from "antd"
import moment  from "moment"
import {
	removeTicket,
	requestCompleted,
	requestStart,
	setTicket,
	setTickets,
} from "redux/app"
import { axiosIntercepter } from "redux/axios"
import { axios } from "lib/axios"

export function getAllTickets() {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/ticket`)
		return dispatch(setTickets(response.data))
	}
}
export function singleTicket(id) {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/ticket/${id}`)
		return dispatch(setTicket(response.data))
	}
}
export function addTicket(payload, organizerId, eventId) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			ticket_batch_name: payload.ticket_batch_name,
			ticket_quantity: payload.ticket_quantity,
			price: payload.price,
			ticket_sale_start_date:moment(payload.ticket_sale_start_date).format("DD/MM/YYYY"),
			ticket_sale_end_date:moment(payload.ticket_sale_end_date).format("DD/MM/YYYY"),
			ticket_sale_start_time: payload.ticket_sale_start_time,
			ticket_sale_end_time: payload.ticket_sale_end_time,
			min_ticket: payload.min_tickets,
			max_ticket: payload.max_tickets,
			eventId: eventId,
			userId: organizerId,
		})

		var config = {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/ticket/admin",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTickets())
					notification["success"]({
						message: "Ticket added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Ticket not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function editTicket(payload, organizerId, eventId, id) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			ticket_batch_name: payload.ticket_batch_name,
			ticket_quantity: payload.ticket_quantity,
			price: payload.price,
			ticket_sale_start_date:moment(payload.ticket_sale_start_date).format("DD/MM/YYYY"),
			ticket_sale_start_time: payload.ticket_sale_start_time,
			min_ticket: payload.min_tickets,
			max_ticket: payload.max_tickets,
			eventId: eventId,
			userId: organizerId,
		})

		var config = {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/ticket/${id}`,
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTickets())
					notification["success"]({
						message: "Ticket Updated successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Ticket not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteTicket(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket/delete/${id}`)
		return dispatch(removeTicket({id}))
	}
}
export function getTickets(id){
	return async(dispatch)=>{
		const response=await axiosIntercepter.get(`/ticket/event/${id}`)
		return dispatch(setTickets(response.data))
	}
}
