import React, { useEffect, useState } from "react"
import { ContentWrapper, StatusBtn1 } from "./style"
import { Input, Card, Table, Spin, Popconfirm } from "antd"
import "./style.css"
import { axiosIntercepter } from "redux/axios"
import { upperFirst } from "lodash"

const Requests = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const [requests, setRequests] = useState([])
	const [loading, setLoading] = useState(false)
	const [searchText, setSearchText] = useState("")

	const getRequests = () => {
		setLoading(true)
		axiosIntercepter.get("/user/admin/switchRequests").then((res) => {
			setRequests(res.data)
			setLoading(false)
		})
	}
	useEffect(() => {
		getRequests()
	}, [])

	const approveRequest = (id) => {
		setLoading(true)
		axiosIntercepter
			.get(`/user/admin/approve/${id}`)
			.then((res) => {
				setRequests(res.data)
				setLoading(false)
			})
			.catch((err) => setLoading(false))
	}

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "User",
			dataIndex: "user",
			key: "user",
			filteredValue: [searchText],
			onFilter: function (value, record) {
				return (
					String(record?.user?.name).toLowerCase().includes(value.toLowerCase()) ||
                    String(record?.type).toLowerCase().includes(value.toLowerCase())
				)
			},
			render: (item) => item?.name,
			sorter: (a, b) => a?.name.localeCompare(b?.name),
		},
		{
			title: "Role",
			dataIndex: "type",
			key: "type",
			render: (item) => upperFirst(item),
			sorter: (a, b) => a?.type.localeCompare(b?.type),
		},
		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => {
				if (row?.status === "pending") {
					return (
						<Popconfirm
							title="Are you sure you want to approve this request?"
							onConfirm={() => approveRequest(row._id)}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							<span style={{ cursor: "pointer" }}>
								<StatusBtn1 style={{ cursor: "pointer" }}>Approve</StatusBtn1>
							</span>
						</Popconfirm>
					)
				}
				return "Accepted"
			},
		},
	]

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<p>
							Requests<span>Switch role requests</span>
						</p>
					</div>
					<Card>
						<div className="card-header user-manage-search">
							<h3>Request List</h3>
							<Input.Search
								onSearch={(value) => {
									setSearchText(value)
								}}
								onChange={(e) => {
									setSearchText(e.target.value)
								}}
								placeholder="Search"
							/>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={requests}
							loading={{
								indicator: (
									<div>
										<Spin size="large" />
									</div>
								),
								spinning: loading,
							}}
						/>
					</Card>
				</div>
			</ContentWrapper>
		</>
	)
}

export default Requests
