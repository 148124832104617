import React, { useState } from "react"
import { Input, Form, Modal, Select, Button, Upload } from "antd"
import { events, organizer, ticketScanner } from "redux/app"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { InputWrapper, FieldName } from "./style"
import { getAllEvents, getOrganizerEvents } from "redux/app/actions/event"
import "./style.css"
import { editTicketScanner, singleTicketScanner } from "redux/app/actions/ticketScanner"
import Placeholder from "assets/icons/placeholder.png"

const EditTicketScanner = ({ open, handleOk, ticketId }) => {
	const [submitted, setSubmitted] = useState(false)
	const organizerData = useSelector(organizer)
	const [imgFile, setImgFile] = useState([])
	const Organizer_Events = useSelector(events)
	const [data, setData] = useState({})
	const dispatch = useDispatch()
	const [organizerId, setOrganizerId] = useState("")
	const [Event_id, setEventId] = useState("")
	const [form] = Form.useForm()
	const [defVal, setdefVal] = useState()
	const ticketData = useSelector(ticketScanner)

	const beforeUpload = () => {
		return false
	}

	function onChange(value) {
		// console.log(`selected ${value}`)
		setEventId(value)
	}

	function onBlur() {
		console.log("blur")
	}

	function onFocus() {
		console.log("focus")
	}

	function onSearch(val) {
		console.log("search:", val)
	}

	useEffect(() => {
		dispatch(singleTicketScanner(ticketId))
	}, [dispatch, ticketId])

	useEffect(() => {
		dispatch(getAllEvents())
	}, [dispatch])

	useEffect(() => {
		setData(ticketData)
		if (ticketData?.eventId) {
			setEventId(ticketData?.eventId[0]?._id)
		}
		if (ticketData?.userId) {
			setOrganizerId(ticketData?.userId?._id)
		}
	}, [ticketData])
	useEffect(() => {
		if (organizerId.length > 0) {
			dispatch(getOrganizerEvents(organizerId))
		}
	}, [dispatch, organizerId])

	useEffect(() => {
		if (data?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: data?.image,
				},
			])
		}
	}, [data])
	useEffect(() => {
		if (data && data?.events) {
			const selected = data?.events.map((i) => {
				return i._id
			})
			setEventId(selected)
			setdefVal(selected)
		}
	}, [data])


	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			events: data?.events?._id,
			email: data?.email,
			image: data?.image,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [form, data])

	const editATicket = (value) => {
		form.setFieldsValue({
			image: data?.image,
		})
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		dispatch(editTicketScanner(value, Event_id, ticketId, image))
		handleOk()
	}
	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="Edit Ticket Scanner"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form onFinish={editATicket} form={form} autoComplete="off">
						<div className="container createevent new-ticket">
							<div className="addevent">
								<InputWrapper>
									<div className="avatar-wrapper">
										<Upload
											name="image"
											listType="picture-card"
											beforeUpload={beforeUpload}
											action={process.env.Action}
											fileList={imgFile}
											onChange={({ fileList: newFileList }) => {
												setImgFile(newFileList)
											}}
											onRemove={() => {
												setImgFile([])
											}}
										>
											{imgFile?.length < 1 && (
												<img
													alt="one"
													style={{ width: "100%", borderRadius: "100%" }}
													src={Placeholder}
												/>
											)}
										</Upload>
									</div>
								</InputWrapper>
								<div>
									<FieldName className="fieldName">Ticket scanner name</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Ticket Scanner Email
									</FieldName>
									<Form.Item
										name="email"
										rules={[{ required: true, message: "Please enter email" }]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div className="organizer-div">
									<FieldName className="fieldName">Add Event</FieldName>
									{defVal ? (
										<Form.Item
											name="send_to"
											// rules={[
											// 	{
											// 		required: true,
											// 		message: "Please select events",
											// 	},
											// ]}
										>
											<Select
												mode="multiple"
												showSearch
												style={{ width: 400 }}
												placeholder="Please select events"
												optionFilterProp="children"
												onChange={onChange}
												onFocus={onFocus}
												onBlur={onBlur}
												onSearch={onSearch}
												filterOption={(input, option) => {
													return (
														option.props.label
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													)
												}}
												defaultValue={defVal}
												options={
													Organizer_Events?.map((i) => ({
														label: i.name,
														value: i._id,
													})) ?? []
												}
											/>
										</Form.Item>
									) : (
										<p>Loading</p>
									)}
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button className="cancel-btn" onClick={handleOk}>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Edit Ticket Scanner
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default EditTicketScanner
