import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper, ButtonStyle, StatusBtn1, StatusBtn2, LoadingSpinner } from "./style"
import { Input, Button, Card, Popconfirm, Table, Spin ,Space} from "antd"
import { useSelector, useDispatch } from "react-redux"
import Eye from "assets/icons/eye.png"
import { bookings, faqs, loading, scannerTickets, tickets } from "redux/app"
import moment from "moment"
import { deleteTicket, getAllTickets } from "redux/app/actions/ticket"
import { Link } from "react-router-dom"
import AddTicket from "./AddTicket"
// import ViewTicket from "./ViewTicketScanner"
import EditTicket from "./EditTicketScanner"
import "./style.css"
import Search from "antd/lib/transfer/search"
import {
	activeTicketScanner,
	deleteTicketScanner,
	getAllTicketScanner,
} from "redux/app/actions/ticketScanner"
import { activeEvent } from "redux/app/actions/event"
import ViewTicketScanner from "./ViewBooking"
import EditTicketScanner from "./EditTicketScanner"
import { getAllBookings } from "redux/app/actions/booking"
import ViewBooking from "./ViewBooking"

const BookingPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const bookingData = useSelector(bookings)
	const dispatch = useDispatch()
	const [viewTicket, setViewTicket] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const dataLoading = useSelector(loading)
	const [ticketId, setTicketId] = useState("")
	const [viewEditTicket, setViewEditTicket] = useState(false)
	const [searchText, setSearchText] = useState("")
	const [filtererdData, setFiltererdData] = useState([])

	useEffect(() => {
		dispatch(getAllBookings())
	}, [])

	// useEffect(() => {
	// 	if (searchText === "") {
	// 		setFiltererdData(bookingData)
	// 	} else {
	// 		const tempData = bookingData?.filter((item) => {
	// 			return item?.eventId?.name.toLowerCase().includes(searchText)
	// 		})
	// 		setFiltererdData(tempData)
	// 	}
	// }, [searchText])

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Event Name",
			dataIndex: "eventId",
			key: "name",
			filteredValue: [searchText],
			render: (item) => item?.name,
			sorter: (a, b) => a.eventId?.name.localeCompare(b.eventId?.name),
		},
		{
			title: "Organizer Name",
			dataIndex: "organizer",
			key: "name",
			render: (value) => value?.name,
			sorter: (a, b) => a.organizer?.name.localeCompare(b.organizer?.name),
		},
		{
			title: "User Name",
			dataIndex: "userId",
			key: "name",
			render: (item) => item?.name,
			sorter: (a, b) => a.userId?.name.localeCompare(b.userId?.name),
		},

		{
			title: "Price (€)",
			dataIndex: "totalAmount",
			key: "totalAmount",
		},
		{
			title: "Ticket Type",
			key: "ticket_batch_name",
			render: (item) => item?.ticketsData[0]?.ticket_batch_name,
			sorter: (a, b) =>
				a.ticketsData?.ticket_batch_name.localeCompare(b.ticketsData?.ticket_batch_name),
		},

		{
			title: "Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY")}</span>
			},
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<img
							style={{ marginRight: "10px" }}
							src={Eye}
							alt=""
							className="eye"
							onClick={() => {
								setTicketId(row._id)
								setViewTicket(true)
							}}
						/>
					</div>
				</>
			),
		},
	]

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<p>
							Bookings<span>All Bookings</span>
						</p>
					</div>
					<Card>
						<div className="card-header user-manage-search">
							<h3>Bookings</h3>
							{/* <Input.Search
								onSearch={(value) => {
									setSearchText(value)
								}}
								onChange={(e) => {
									setSearchText(e.target.value)
								}}
								placeholder="Search....."
							/> */}
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={bookingData}
							loading={{
								indicator: (
									<div>
										<Spin size="large" />
									</div>
								),
								spinning: !bookingData.length,
							}}
						/>
					</Card>
				</div>
				<AddTicket open={isModalOpen} handleCancel={hideModal} />
				<ViewBooking
					open={viewTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewTicket(false)
					}}
				/>
				{/* <EditTicketScanner
					open={viewEditTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewEditTicket(false)
					}}
				/> */}
			</ContentWrapper>
		</>
	)
}

export default BookingPage
