import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Menu, Collapse, Select } from "antd"
import { SettingOutlined } from "@ant-design/icons"
import { MenuWrapper } from "./styles"
const NavMenu = ({ collapsed }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { pathname } = location
	const [currentActive, setCurrentActive] = useState(pathname)
	const { Panel } = Collapse
	const { Option } = Select
	useEffect(() => {
		setCurrentActive(pathname)
		console.log(pathname, currentActive)
	}, [pathname, currentActive])

	const handleClick = (e) => {
		setCurrentActive(e.key)
	}
	const [expandIconPosition, setExpandIconPosition] = useState("start")
	const onPositionChange = (newExpandIconPosition) => {
		setExpandIconPosition(newExpandIconPosition)
	}
	const onChange = (key) => {
		console.log(key)
	}
	
	return (
		<MenuWrapper>
			<Menu mode="inline" theme="light" onClick={handleClick} selectedKeys={[currentActive]}>
				<Menu.Item onClick={() => navigate("/dashboard")} key="/dashboard">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						viewBox="0 0 30 30"
					>
						<g
							id="dashboard_5_"
							data-name="dashboard (5)"
							transform="translate(0 0)"
							opacity="0.6"
						>
							<path
								id="Path_14"
								data-name="Path 14"
								d="M11.887,10.281H2.249A2.251,2.251,0,0,1,0,8.032V2.249A2.251,2.251,0,0,1,2.249,0h9.638a2.251,2.251,0,0,1,2.249,2.249V8.032A2.251,2.251,0,0,1,11.887,10.281ZM2.249,1.928a.321.321,0,0,0-.321.321V8.032a.322.322,0,0,0,.321.321h9.638a.322.322,0,0,0,.321-.321V2.249a.322.322,0,0,0-.321-.321Zm0,0"
							/>
							<path
								id="Path_15"
								data-name="Path 15"
								d="M11.887,231.324H2.249A2.251,2.251,0,0,1,0,229.075V215.581a2.251,2.251,0,0,1,2.249-2.249h9.638a2.251,2.251,0,0,1,2.249,2.249v13.494A2.251,2.251,0,0,1,11.887,231.324ZM2.249,215.26a.322.322,0,0,0-.321.321v13.494a.321.321,0,0,0,.321.321h9.638a.322.322,0,0,0,.321-.321V215.581a.322.322,0,0,0-.321-.321Zm0,0"
								transform="translate(0 -201.324)"
							/>
							<path
								id="Path_16"
								data-name="Path 16"
								d="M289.22,351.613h-9.638a2.251,2.251,0,0,1-2.249-2.249v-5.783a2.251,2.251,0,0,1,2.249-2.249h9.638a2.251,2.251,0,0,1,2.249,2.249v5.783A2.251,2.251,0,0,1,289.22,351.613Zm-9.638-8.353a.322.322,0,0,0-.321.321v5.783a.322.322,0,0,0,.321.321h9.638a.321.321,0,0,0,.321-.321v-5.783a.321.321,0,0,0-.321-.321Zm0,0"
								transform="translate(-261.469 -321.613)"
							/>
							<path
								id="Path_17"
								data-name="Path 17"
								d="M289.22,17.992h-9.638a2.251,2.251,0,0,1-2.249-2.249V2.249A2.251,2.251,0,0,1,279.581,0h9.638a2.251,2.251,0,0,1,2.249,2.249V15.743A2.251,2.251,0,0,1,289.22,17.992ZM279.581,1.928a.322.322,0,0,0-.321.321V15.743a.322.322,0,0,0,.321.321h9.638a.321.321,0,0,0,.321-.321V2.249a.321.321,0,0,0-.321-.321Zm0,0"
								transform="translate(-261.469)"
							/>
						</g>
					</svg>
					Dashboard
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/genres")} key="/genres">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="27.999"
						viewBox="0 0 28 27.999"
					>
						<g
							id="tickets_4_"
							data-name="tickets (4)"
							transform="translate(0 -0.007)"
							opacity="0.6"
						>
							<path
								id="Path_100901"
								data-name="Path 100901"
								d="M26.818,18.988A1.868,1.868,0,0,0,28,17.254V13.722a1.9,1.9,0,0,0-1.9-1.9h-.194a1.9,1.9,0,0,0-.16-2.5l-2.5-2.5a1.868,1.868,0,0,0-2.062-.39.994.994,0,0,1-1.311-1.311,1.868,1.868,0,0,0-.39-2.062l-2.5-2.5a1.9,1.9,0,0,0-2.685,0L3.043,11.823H1.9a1.9,1.9,0,0,0-1.9,1.9v3.532a1.868,1.868,0,0,0,1.182,1.734.994.994,0,0,1,0,1.854A1.869,1.869,0,0,0,0,22.576v3.532a1.9,1.9,0,0,0,1.9,1.9H26.1a1.9,1.9,0,0,0,1.9-1.9V22.576a1.869,1.869,0,0,0-1.182-1.734.994.994,0,0,1,0-1.854ZM15.468,1.727a.252.252,0,0,1,.356,0l2.5,2.5a.211.211,0,0,1,.048.232A2.641,2.641,0,0,0,21.853,7.94a.211.211,0,0,1,.232.048l2.5,2.5a.252.252,0,0,1,0,.356l-.982.982H19.31L12.341,4.855ZM11.176,6.019l5.8,5.8H5.372ZM1.647,26.108V22.576a.211.211,0,0,1,.13-.2,2.641,2.641,0,0,0,0-4.926.211.211,0,0,1-.13-.2V13.722A.252.252,0,0,1,1.9,13.47h18.69V26.359H1.9a.252.252,0,0,1-.252-.252Zm24.706-8.854a.211.211,0,0,1-.13.2,2.641,2.641,0,0,0,0,4.926.211.211,0,0,1,.13.2v3.532a.252.252,0,0,1-.252.252H22.235V13.47H26.1a.252.252,0,0,1,.252.252Z"
							/>
							<path
								id="Path_100902"
								data-name="Path 100902"
								d="M105,284.991h12.353v1.647H105Z"
								transform="translate(-99.235 -269.337)"
							/>
							<path
								id="Path_100903"
								data-name="Path 100903"
								d="M105,344.991h12.353v1.647H105Z"
								transform="translate(-99.235 -326.043)"
							/>
							<path
								id="Path_100904"
								data-name="Path 100904"
								d="M105,404.991h12.353v1.647H105Z"
								transform="translate(-99.235 -382.749)"
							/>
						</g>
					</svg>
					Genres
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/userManagement")} key="/userManagement">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30.4"
						height="25.275"
						viewBox="0 0 30.4 25.275"
					>
						<g
							id="group_7_"
							data-name="group (7)"
							transform="translate(0.2 -43.529)"
							opacity="0.6"
						>
							<g id="Group_2" data-name="Group 2" transform="translate(0 54.534)">
								<g id="Group_1" data-name="Group 1">
									<path
										id="Path_33"
										data-name="Path 33"
										d="M25.605,231.949H22.669a4.357,4.357,0,0,0-1.155.156A4.4,4.4,0,0,0,17.6,229.68H12.4A4.4,4.4,0,0,0,8.486,232.1a4.357,4.357,0,0,0-1.155-.156H4.395A4.414,4.414,0,0,0,0,236.372V241.1a2.648,2.648,0,0,0,2.637,2.654H27.363A2.648,2.648,0,0,0,30,241.1v-4.725A4.414,4.414,0,0,0,25.605,231.949ZM8.01,234.1v7.878H2.637a.883.883,0,0,1-.879-.885v-4.725a2.648,2.648,0,0,1,2.637-2.654H7.331a2.613,2.613,0,0,1,.69.093C8.014,233.908,8.01,234,8.01,234.1Zm12.222,7.878H9.768V234.1a2.648,2.648,0,0,1,2.637-2.654H17.6a2.648,2.648,0,0,1,2.637,2.654Zm8.01-.885a.883.883,0,0,1-.879.885H21.99V234.1c0-.1,0-.2-.011-.292a2.616,2.616,0,0,1,.69-.093h2.937a2.648,2.648,0,0,1,2.637,2.654Z"
										transform="translate(0 -229.68)"
										stroke="#000"
										stroke-width="0.4"
									/>
								</g>
							</g>
							<g id="Group_4" data-name="Group 4" transform="translate(1.971 48.565)">
								<g id="Group_3" data-name="Group 3">
									<path
										id="Path_34"
										data-name="Path 34"
										d="M37.344,126.5a3.93,3.93,0,1,0,3.93,3.93A3.935,3.935,0,0,0,37.344,126.5Zm0,6.092a2.161,2.161,0,1,1,2.161-2.161A2.164,2.164,0,0,1,37.344,132.6Z"
										transform="translate(-33.414 -126.504)"
										stroke="#000"
										stroke-width="0.4"
									/>
								</g>
							</g>
							<g id="Group_6" data-name="Group 6" transform="translate(9.749 43.729)">
								<g id="Group_5" data-name="Group 5">
									<path
										id="Path_35"
										data-name="Path 35"
										d="M172.213,43.729a5.251,5.251,0,1,0,5.251,5.251A5.257,5.257,0,0,0,172.213,43.729Zm0,8.733a3.482,3.482,0,1,1,3.482-3.482A3.486,3.486,0,0,1,172.213,52.462Z"
										transform="translate(-166.962 -43.729)"
										stroke="#000"
										stroke-width="0.4"
									/>
								</g>
							</g>
							<g
								id="Group_8"
								data-name="Group 8"
								transform="translate(20.169 48.565)"
							>
								<g id="Group_7" data-name="Group 7">
									<path
										id="Path_36"
										data-name="Path 36"
										d="M349.224,126.5a3.93,3.93,0,1,0,3.93,3.93A3.935,3.935,0,0,0,349.224,126.5Zm0,6.092a2.161,2.161,0,1,1,2.161-2.161A2.164,2.164,0,0,1,349.224,132.6Z"
										transform="translate(-345.294 -126.504)"
										stroke="#000"
										stroke-width="0.4"
									/>
								</g>
							</g>
						</g>
					</svg>
					User Management
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/booking")} key="/booking">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						version="1.2"
						viewBox="0 0 32 32"
						width="32"
						height="32"
					>
						<title>booking (1)</title>
						<defs>
							<image
								width="32"
								height="32"
								id="img1"
								href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAAXNSR0IB2cksfwAAAPZQTFRFZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dn6MNfZwAAAFJ0Uk5TALiqsrGrt6W++eXy7FHM+v9QUuTd/vz9zkhKzZyKnZCVkZvf7j7hNxsxRHv19mkwBmV3Arw1hgHSiBfVjD/pLxA85z06LC058e3qQ/cz9EFJ40iu+1UAAAE+SURBVHicrZN5T8JAEMXHW0qlA91lVVBUVrRyKCjeirctnnz/L+PMVgwGtmmi74/XdPeXzMxmHoDR1DT7zCz73Dz7wiKMKuOwZ112N8vuZIZ3SzkPLfJyeYACoi+ELAqSMl5UxqUQPmIeluWKvcSqLIHn8295jX29wl7ZYN/cMpgHWIUEVTEFoHSCVBrg7z2kAbYdKlfbic92A4CgRgfO3g9Qx4ZuYsvc77veAbSwqRtYHwHa0CHg8KjcdfEYCOhAewJwcooKz2AS8F3i/AIvwQC/S1xdc5M9urm5NY0Ed3Tg3//jOzzw2OKR1oLfnxbhSXDN5/EmQ6m1DMebHI4JYQQQhfZ3SAD6IjIALbQyQCT6I8DLK4VAv1G0OA0Usu47fcOPdGPGa28TrX0cHIs+KTgUvYGwaMDRg0IpMbxfMWA+cuXujPcAAAAASUVORK5CYII="
							/>
						</defs>
						<style></style>
						<use id="Background" href="#img1" x="0" y="0" />
					</svg>
					Booking
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/events")} key="/event">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28.837"
						height="30"
						viewBox="0 0 28.837 30"
					>
						<g id="calendar_10_" data-name="calendar (10)" opacity="0.6">
							<path
								id="Path_37"
								data-name="Path 37"
								d="M194.791,282.275a.862.862,0,0,0,1.219,0l3.077-3.077a.862.862,0,0,0-1.219-1.219l-2.468,2.468-1.125-1.125a.862.862,0,0,0-1.219,1.219Zm0,0"
								transform="translate(-181.653 -261.367)"
							/>
							<path
								id="Path_38"
								data-name="Path 38"
								d="M136.948,213.5a6.843,6.843,0,1,0-6.843-6.843A6.851,6.851,0,0,0,136.948,213.5Zm0-11.962a5.119,5.119,0,1,1-5.119,5.119A5.125,5.125,0,0,1,136.948,201.54Zm0,0"
								transform="translate(-122.529 -187.616)"
							/>
							<path
								id="Path_39"
								data-name="Path 39"
								d="M26.019,1.886h-1.59v-1a.872.872,0,1,0-1.743,0v1H20.974v-1a.872.872,0,1,0-1.743,0v1H9.607v-1a.872.872,0,1,0-1.743,0v1H6.151v-1a.872.872,0,1,0-1.743,0v1H2.818A2.845,2.845,0,0,0,0,4.751V27.135A2.845,2.845,0,0,0,2.818,30h23.2a2.845,2.845,0,0,0,2.818-2.865V4.751a2.845,2.845,0,0,0-2.818-2.865Zm1.075,25.249a1.086,1.086,0,0,1-1.075,1.093H2.818a1.086,1.086,0,0,1-1.075-1.093V9.252H27.094ZM1.743,4.751A1.086,1.086,0,0,1,2.818,3.658h1.59v1a.872.872,0,1,0,1.743,0v-1H7.864v1a.872.872,0,1,0,1.743,0v-1h9.624v1a.872.872,0,1,0,1.743,0v-1h1.713v1a.872.872,0,1,0,1.743,0v-1h1.589a1.086,1.086,0,0,1,1.075,1.093V7.48H1.743Zm0,0"
								transform="translate(0 0)"
							/>
						</g>
					</svg>
					Events
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/featured-events")} key="/featuredEvent">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28.837"
						height="30"
						viewBox="0 0 28.837 30"
					>
						<g id="calendar_10_" data-name="calendar (10)" opacity="0.6">
							<path
								id="Path_37"
								data-name="Path 37"
								d="M194.791,282.275a.862.862,0,0,0,1.219,0l3.077-3.077a.862.862,0,0,0-1.219-1.219l-2.468,2.468-1.125-1.125a.862.862,0,0,0-1.219,1.219Zm0,0"
								transform="translate(-181.653 -261.367)"
							/>
							<path
								id="Path_38"
								data-name="Path 38"
								d="M136.948,213.5a6.843,6.843,0,1,0-6.843-6.843A6.851,6.851,0,0,0,136.948,213.5Zm0-11.962a5.119,5.119,0,1,1-5.119,5.119A5.125,5.125,0,0,1,136.948,201.54Zm0,0"
								transform="translate(-122.529 -187.616)"
							/>
							<path
								id="Path_39"
								data-name="Path 39"
								d="M26.019,1.886h-1.59v-1a.872.872,0,1,0-1.743,0v1H20.974v-1a.872.872,0,1,0-1.743,0v1H9.607v-1a.872.872,0,1,0-1.743,0v1H6.151v-1a.872.872,0,1,0-1.743,0v1H2.818A2.845,2.845,0,0,0,0,4.751V27.135A2.845,2.845,0,0,0,2.818,30h23.2a2.845,2.845,0,0,0,2.818-2.865V4.751a2.845,2.845,0,0,0-2.818-2.865Zm1.075,25.249a1.086,1.086,0,0,1-1.075,1.093H2.818a1.086,1.086,0,0,1-1.075-1.093V9.252H27.094ZM1.743,4.751A1.086,1.086,0,0,1,2.818,3.658h1.59v1a.872.872,0,1,0,1.743,0v-1H7.864v1a.872.872,0,1,0,1.743,0v-1h9.624v1a.872.872,0,1,0,1.743,0v-1h1.713v1a.872.872,0,1,0,1.743,0v-1h1.589a1.086,1.086,0,0,1,1.075,1.093V7.48H1.743Zm0,0"
								transform="translate(0 0)"
							/>
						</g>
					</svg>
					Featured Events
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/tickets")} key="/event">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="27.999"
						viewBox="0 0 28 27.999"
					>
						<g
							id="tickets_4_"
							data-name="tickets (4)"
							transform="translate(0 -0.007)"
							opacity="0.6"
						>
							<path
								id="Path_100901"
								data-name="Path 100901"
								d="M26.818,18.988A1.868,1.868,0,0,0,28,17.254V13.722a1.9,1.9,0,0,0-1.9-1.9h-.194a1.9,1.9,0,0,0-.16-2.5l-2.5-2.5a1.868,1.868,0,0,0-2.062-.39.994.994,0,0,1-1.311-1.311,1.868,1.868,0,0,0-.39-2.062l-2.5-2.5a1.9,1.9,0,0,0-2.685,0L3.043,11.823H1.9a1.9,1.9,0,0,0-1.9,1.9v3.532a1.868,1.868,0,0,0,1.182,1.734.994.994,0,0,1,0,1.854A1.869,1.869,0,0,0,0,22.576v3.532a1.9,1.9,0,0,0,1.9,1.9H26.1a1.9,1.9,0,0,0,1.9-1.9V22.576a1.869,1.869,0,0,0-1.182-1.734.994.994,0,0,1,0-1.854ZM15.468,1.727a.252.252,0,0,1,.356,0l2.5,2.5a.211.211,0,0,1,.048.232A2.641,2.641,0,0,0,21.853,7.94a.211.211,0,0,1,.232.048l2.5,2.5a.252.252,0,0,1,0,.356l-.982.982H19.31L12.341,4.855ZM11.176,6.019l5.8,5.8H5.372ZM1.647,26.108V22.576a.211.211,0,0,1,.13-.2,2.641,2.641,0,0,0,0-4.926.211.211,0,0,1-.13-.2V13.722A.252.252,0,0,1,1.9,13.47h18.69V26.359H1.9a.252.252,0,0,1-.252-.252Zm24.706-8.854a.211.211,0,0,1-.13.2,2.641,2.641,0,0,0,0,4.926.211.211,0,0,1,.13.2v3.532a.252.252,0,0,1-.252.252H22.235V13.47H26.1a.252.252,0,0,1,.252.252Z"
							/>
							<path
								id="Path_100902"
								data-name="Path 100902"
								d="M105,284.991h12.353v1.647H105Z"
								transform="translate(-99.235 -269.337)"
							/>
							<path
								id="Path_100903"
								data-name="Path 100903"
								d="M105,344.991h12.353v1.647H105Z"
								transform="translate(-99.235 -326.043)"
							/>
							<path
								id="Path_100904"
								data-name="Path 100904"
								d="M105,404.991h12.353v1.647H105Z"
								transform="translate(-99.235 -382.749)"
							/>
						</g>
					</svg>
					Tickets
				</Menu.Item>
				<Menu.Item onClick={() => navigate("/requests")} key="/event">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="27.999"
						viewBox="0 0 28 27.999"
					>
						<g
							id="tickets_4_"
							data-name="tickets (4)"
							transform="translate(0 -0.007)"
							opacity="0.6"
						>
							<path
								id="Path_100901"
								data-name="Path 100901"
								d="M26.818,18.988A1.868,1.868,0,0,0,28,17.254V13.722a1.9,1.9,0,0,0-1.9-1.9h-.194a1.9,1.9,0,0,0-.16-2.5l-2.5-2.5a1.868,1.868,0,0,0-2.062-.39.994.994,0,0,1-1.311-1.311,1.868,1.868,0,0,0-.39-2.062l-2.5-2.5a1.9,1.9,0,0,0-2.685,0L3.043,11.823H1.9a1.9,1.9,0,0,0-1.9,1.9v3.532a1.868,1.868,0,0,0,1.182,1.734.994.994,0,0,1,0,1.854A1.869,1.869,0,0,0,0,22.576v3.532a1.9,1.9,0,0,0,1.9,1.9H26.1a1.9,1.9,0,0,0,1.9-1.9V22.576a1.869,1.869,0,0,0-1.182-1.734.994.994,0,0,1,0-1.854ZM15.468,1.727a.252.252,0,0,1,.356,0l2.5,2.5a.211.211,0,0,1,.048.232A2.641,2.641,0,0,0,21.853,7.94a.211.211,0,0,1,.232.048l2.5,2.5a.252.252,0,0,1,0,.356l-.982.982H19.31L12.341,4.855ZM11.176,6.019l5.8,5.8H5.372ZM1.647,26.108V22.576a.211.211,0,0,1,.13-.2,2.641,2.641,0,0,0,0-4.926.211.211,0,0,1-.13-.2V13.722A.252.252,0,0,1,1.9,13.47h18.69V26.359H1.9a.252.252,0,0,1-.252-.252Zm24.706-8.854a.211.211,0,0,1-.13.2,2.641,2.641,0,0,0,0,4.926.211.211,0,0,1,.13.2v3.532a.252.252,0,0,1-.252.252H22.235V13.47H26.1a.252.252,0,0,1,.252.252Z"
							/>
							<path
								id="Path_100902"
								data-name="Path 100902"
								d="M105,284.991h12.353v1.647H105Z"
								transform="translate(-99.235 -269.337)"
							/>
							<path
								id="Path_100903"
								data-name="Path 100903"
								d="M105,344.991h12.353v1.647H105Z"
								transform="translate(-99.235 -326.043)"
							/>
							<path
								id="Path_100904"
								data-name="Path 100904"
								d="M105,404.991h12.353v1.647H105Z"
								transform="translate(-99.235 -382.749)"
							/>
						</g>
					</svg>
					Requests
				</Menu.Item>
				<Collapse
					defaultActiveKey={["0"]}
					onChange={onChange}
					className="menu-collpase"
					expandIconPosition={expandIconPosition}
				>
					<Panel
						header="Discount"
						key="1"
						extra={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="29.08"
								height="30"
								viewBox="0 0 29.08 30"
							>
								<g
									id="discount_5_"
									data-name="discount (5)"
									transform="translate(0)"
									opacity="0.6"
								>
									<g
										id="Group_92006"
										data-name="Group 92006"
										transform="translate(0 0)"
									>
										<g
											id="Group_92005"
											data-name="Group 92005"
											transform="translate(0 0)"
										>
											<path
												id="Path_100955"
												data-name="Path 100955"
												d="M11.761,15.387a.871.871,0,0,0,0-.777l-1.13-2.289a2.592,2.592,0,0,1,1.135-3.458l2.274-1.194A.88.88,0,0,0,14.5,7.04l.445-2.51a2.628,2.628,0,0,1,2.972-2.137l2.548.357a.894.894,0,0,0,.747-.24L23.062.738a2.65,2.65,0,0,1,3.673,0l1.85,1.772a.894.894,0,0,0,.747.24l2.548-.357A2.627,2.627,0,0,1,34.852,4.53L35.3,7.04a.88.88,0,0,0,.461.629l2.274,1.194a2.592,2.592,0,0,1,1.135,3.458l-1.13,2.289a.871.871,0,0,0,0,.777l1.13,2.289a2.592,2.592,0,0,1-1.135,3.458l-2.274,1.194a.88.88,0,0,0-.461.629l-.445,2.51a2.622,2.622,0,0,1-2.593,2.164,2.73,2.73,0,0,1-.378-.026l-2.548-.357a.894.894,0,0,0-.747.24l-1.85,1.771a2.65,2.65,0,0,1-3.673,0l-1.85-1.771a.9.9,0,0,0-.747-.24l-2.548.357a2.627,2.627,0,0,1-2.972-2.137L14.5,22.957a.88.88,0,0,0-.461-.629l-2.274-1.194a2.592,2.592,0,0,1-1.135-3.458Zm.824,4.219L14.859,20.8a2.6,2.6,0,0,1,1.364,1.858l.445,2.509a.889.889,0,0,0,1.005.723l2.548-.357a2.641,2.641,0,0,1,2.207.71l1.85,1.771a.9.9,0,0,0,1.243,0l1.85-1.771a2.636,2.636,0,0,1,1.831-.736,2.709,2.709,0,0,1,.376.026l2.548.357a.889.889,0,0,0,1.005-.723l.445-2.51A2.6,2.6,0,0,1,34.939,20.8l2.274-1.194a.877.877,0,0,0,.384-1.17l-1.13-2.289a2.575,2.575,0,0,1,0-2.3l1.13-2.289a.877.877,0,0,0-.384-1.17L34.939,9.2a2.6,2.6,0,0,1-1.364-1.858l-.445-2.509a.889.889,0,0,0-1.005-.723l-2.548.357a2.642,2.642,0,0,1-2.207-.71L25.52,1.982a.9.9,0,0,0-1.243,0l-1.85,1.771a2.641,2.641,0,0,1-2.207.71l-2.548-.357a.889.889,0,0,0-1.005.723l-.445,2.51A2.6,2.6,0,0,1,14.859,9.2l-2.274,1.194a.877.877,0,0,0-.384,1.17l1.13,2.289a2.575,2.575,0,0,1,0,2.3L12.2,18.436A.877.877,0,0,0,12.585,19.606Z"
												transform="translate(-10.359 0.002)"
											/>
										</g>
									</g>
									<g
										id="Group_92008"
										data-name="Group 92008"
										transform="translate(7.382 7.842)"
									>
										<g id="Group_92007" data-name="Group 92007">
											<path
												id="Path_100956"
												data-name="Path 100956"
												d="M144.067,144.067a.942.942,0,0,1,1.332,0L157.832,156.5a.942.942,0,1,1-1.332,1.332L144.067,145.4A.942.942,0,0,1,144.067,144.067Z"
												transform="translate(-143.791 -143.791)"
											/>
										</g>
									</g>
									<g
										id="Group_92010"
										data-name="Group 92010"
										transform="translate(14.505 7.042)"
									>
										<g
											id="Group_92009"
											data-name="Group 92009"
											transform="translate(0)"
										>
											<path
												id="Path_100957"
												data-name="Path 100957"
												d="M146.256,123.116a3.454,3.454,0,1,1-3.454,3.454A3.458,3.458,0,0,1,146.256,123.116Zm0,5.024a1.57,1.57,0,1,0-1.57-1.57A1.572,1.572,0,0,0,146.256,128.14Z"
												transform="translate(-142.802 -123.116)"
											/>
										</g>
									</g>
									<g
										id="Group_92012"
										data-name="Group 92012"
										transform="translate(7.667 16.051)"
									>
										<g id="Group_92011" data-name="Group 92011">
											<path
												id="Path_100958"
												data-name="Path 100958"
												d="M264.376,280.61a3.454,3.454,0,1,1-3.454,3.454A3.458,3.458,0,0,1,264.376,280.61Zm0,5.024a1.57,1.57,0,1,0-1.57-1.57A1.572,1.572,0,0,0,264.376,285.634Z"
												transform="translate(-260.922 -280.61)"
											/>
										</g>
									</g>
								</g>
							</svg>
						}
					>
						<div className="inner-submenu">
							<Menu.Item onClick={() => navigate("/offers")} key="/offers">
								
								Offers
							</Menu.Item>
							<Menu.Item onClick={() => navigate("/coupon")} key="/coupon">
							
								Coupon
							</Menu.Item>
						</div>
					</Panel>
				</Collapse>

				<Menu.Item onClick={() => navigate("/ticket-scanner")} key="/ticket-scanner">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="28"
						viewBox="0 0 28 28"
					>
						<g id="user_9_" data-name="user (9)" opacity="0.6">
							<path
								id="Path_100943"
								data-name="Path 100943"
								d="M14,28a14,14,0,1,1,10.705-4.977,14.147,14.147,0,0,1-1.652,1.656A14,14,0,0,1,14,28ZM14,1.75A12.251,12.251,0,0,0,4.633,21.895a12.393,12.393,0,0,0,1.447,1.45,12.251,12.251,0,0,0,15.842,0,12.393,12.393,0,0,0,1.447-1.451A12.251,12.251,0,0,0,14,1.75Z"
							/>
							<path
								id="Path_100944"
								data-name="Path 100944"
								d="M165.25,74.5a5.25,5.25,0,1,1,5.25-5.25A5.256,5.256,0,0,1,165.25,74.5Zm0-8.75a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,165.25,65.75Z"
								transform="translate(-151.25 -60.5)"
							/>
							<path
								id="Path_100945"
								data-name="Path 100945"
								d="M98.113,236.637a.876.876,0,0,1-.849-1.088,7.882,7.882,0,0,0-7.523-9.8h-.23a7.882,7.882,0,0,0-7.523,9.8.875.875,0,0,1-1.7.426A9.628,9.628,0,0,1,89.489,224h.273a9.632,9.632,0,0,1,9.2,11.975A.875.875,0,0,1,98.113,236.637Z"
								transform="translate(-75.625 -211.75)"
							/>
						</g>
					</svg>
					Ticket Scanner User
				</Menu.Item>

				{/* <Menu.Item onClick={() => navigate("/notification")} key="/notification">
					<i class="fa-solid fa-bell"></i>
					Notification
				</Menu.Item> */}
				<Menu>
					<Collapse
						defaultActiveKey={["2"]}
						onChange={onChange}
						className="menu-collpase"
						expandIconPosition={expandIconPosition}
					>
						<Panel
							header="Notifications"
							key="2"
							extra={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.2"
									viewBox="0 0 32 32"
									width="32"
									height="32"
								>
									<title>notification</title>
									<defs>
										<image
											width="30"
											height="32"
											id="img"
											href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAgCAMAAAAynjhNAAAAAXNSR0IB2cksfwAAAVxQTFRFZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnZ2dnCXAY4QAAAHR0Uk5TABGb5poEyv/NBVDkNeV8sVRZtAFK0M4awv7Vt7XY/bMYMujuexkeg/beJB3HItsSvssK2aVL9Bst+jeyou8CLuLtK0fIRVK9UwaurzbWVgvgqVHfT9drrWo0+WgzoZ8Db93xbnp4H/edKJ7yYfxVPrnzuDz2VZy5AAABQklEQVR4nM2SazcCURSGd028pBKlNIiEoqFMLskl18Y95RKFyDW5+/9rmclS02nGZ8+Xc97zrHX2PutsIhUGI8eZDKRDUzNkWlp1tBltFosVZh1tQ7u9oxM2TelwdqGCy6lh3d2Ah+/p7fMC/QOs9Q3CPzSs7EYCQYyO1dsQB8H9G8Z5TITrdASTYi2Fo5hS22nMzKpzbM4TV8V5BOqLmbBQC2IQTC8WLC5VQwLLzEMcK1ithjWsM5pc2Kjuk5BYHUHyLy1h8x/orbgJ26zegXG3MlWJPXkE7Kzelw/5GFHqANZ0hrVEmTSHQ5GOcCw2SoVUVJ7KE2S1LVEWp3SGXP5ck3wOPqIL6HIpX1G4Eq4rFOFXFj+KP1m4KahL3UJyyD8t4U6zk/sHPJZKTyiHtDt99ioFX171XvL2/lH+/FKffAPBjDgqqHqZkwAAAABJRU5ErkJggg=="
										/>
									</defs>
									<style></style>
									<use id="Background" href="#img" x="1" y="0" />
								</svg>
							}
						>
							<div className="inner-submenu">
								<Menu.Item onClick={() => navigate("/notification")} key="/all-notifications">
									All Notifications
								</Menu.Item>
								<Menu.Item
									onClick={() => navigate("/send-notification")}
									key="/send-notification"
								>
									Send Notifications
								</Menu.Item>
							</div>
						</Panel>
					</Collapse>
					<Collapse
						defaultActiveKey={["0"]}
						onChange={onChange}
						className="menu-collpase"
						expandIconPosition={expandIconPosition}
					>
						<Panel
							header="Settings"
							key="1"
							extra={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="30"
									height="30"
									viewBox="0 0 30 30"
								>
									<g
										id="Settings"
										transform="translate(-0.066 0.048)"
										opacity="0.6"
									>
										<path
											id="Path_40"
											data-name="Path 40"
											d="M1839.347,582.227a2.93,2.93,0,0,1-2.115-.9.955.955,0,0,0-1.619.539,3.022,3.022,0,0,1-2.875,3.121,15.687,15.687,0,0,1-2.617-.043,2.852,2.852,0,0,1-2.5-2.905.992.992,0,0,0-.464-.895.929.929,0,0,0-1.2.167,2.942,2.942,0,0,1-2.4.884,2.882,2.882,0,0,1-1.8-.846c-.5-.5-1.009-.992-1.5-1.5a2.9,2.9,0,0,1,0-4.118.97.97,0,0,0-.707-1.72,2.917,2.917,0,0,1-2.908-2.922,16.518,16.518,0,0,1,.048-2.582,2.87,2.87,0,0,1,2.881-2.469.97.97,0,0,0,.7-1.714,2.909,2.909,0,0,1-.577-3.31,11.519,11.519,0,0,1,2.908-2.9,2.9,2.9,0,0,1,3.286.539.976.976,0,0,0,1.742-.712,2.9,2.9,0,0,1,2.9-2.889,16.925,16.925,0,0,1,2.617.043,2.879,2.879,0,0,1,2.46,2.862,1,1,0,0,0,.539.976.955.955,0,0,0,1.16-.237,2.953,2.953,0,0,1,3.776-.34,6.6,6.6,0,0,1,.761.7c.367.367.739.728,1.079,1.078a2.915,2.915,0,0,1,.032,4.167.959.959,0,0,0-.275,1.127.977.977,0,0,0,.977.582,2.907,2.907,0,0,1,2.924,2.938,16.549,16.549,0,0,1-.049,2.582,2.891,2.891,0,0,1-2.892,2.458.944.944,0,0,0-.987.652.954.954,0,0,0,.286,1.04,2.915,2.915,0,0,1,.593,3.31,10.982,10.982,0,0,1-2.978,2.976A2.857,2.857,0,0,1,1839.347,582.227Zm-.032-1.995a.982.982,0,0,0,.755-.329l1.306-1.3a.975.975,0,0,0,0-1.59,2.893,2.893,0,0,1-.809-1.968,2.944,2.944,0,0,1,2.956-3.008.944.944,0,0,0,1.025-1.03v-1.995a.922.922,0,0,0-.955-.965,3.317,3.317,0,0,1-.8-.1,2.943,2.943,0,0,1-1.37-4.927.954.954,0,0,0,0-1.5c-.448-.453-.9-.9-1.349-1.348a.939.939,0,0,0-1.43,0,2.962,2.962,0,0,1-5.066-2.118.933.933,0,0,0-.982-.976h-2.028a.938.938,0,0,0-.982.976,2.957,2.957,0,0,1-5.06,2.118.95.95,0,0,0-1.435,0l-1.279,1.278a.985.985,0,0,0-.038,1.617,2.881,2.881,0,0,1,.809,1.973,2.949,2.949,0,0,1-2.929,3,.949.949,0,0,0-1.079,1.078v1.93a.933.933,0,0,0,1,.992,3.321,3.321,0,0,1,.766.1,2.948,2.948,0,0,1,1.365,4.932.954.954,0,0,0,0,1.5l1.349,1.342a.95.95,0,0,0,1.435,0,2.951,2.951,0,0,1,5.055,2.113.938.938,0,0,0,.977.981h2.028a.933.933,0,0,0,.987-1,3.363,3.363,0,0,1,.1-.771,2.946,2.946,0,0,1,4.893-1.391,1.107,1.107,0,0,0,.793.377Z"
											transform="translate(-1816.55 -555.07)"
											fill="#020202"
										/>
										<path
											id="Path_41"
											data-name="Path 41"
											d="M1838.432,569.93a7.06,7.06,0,1,1-7.033,6.995,7.06,7.06,0,0,1,7.033-6.995Zm0,12.062a5.034,5.034,0,1,1,.011,0Z"
											transform="translate(-1823.405 -561.994)"
											fill="#020202"
										/>
									</g>
								</svg>
							}
						>
							<div className="inner-submenu">
								<Menu.Item onClick={() => navigate("/profile")} key="/profile">
									
									Personal Settings
								</Menu.Item>
								<Menu.Item onClick={() => navigate("/reset")} key="/reset">
									
									Password & Security
								</Menu.Item>
								<Menu.Item onClick={() => navigate("/faq")} key="/faq">
									
									FAQ
								</Menu.Item>
								<Menu.Item onClick={() => navigate("/content")} key="/content">
								
									Content Page
								</Menu.Item>
							</div>
						</Panel>
					</Collapse>
				</Menu>
				{/* <Menu.Item onClick={() => navigate("/help")} key="/help">
					<i class="fa-solid fa-file-lines"></i>
					Help and Support
				</Menu.Item> */}
			</Menu>
		</MenuWrapper>
	)
}

export default NavMenu
