import styled from "styled-components"
export const HomeWrapper = styled.div`
	.ant-table {
		background: none !important;
		margin-top: 10px;
	}
	
	.project-name {
		color: ${({ theme }) => theme.colors.darkGray};
		&:hover,
		span {
			&:hover {
				color: ${({ theme }) => theme.colors.primaryDark};
			}
		}
	}
	.ant-table tr td a, .ant-table tr td span {
		color: #000;
	}
	.ant-table tr th {
		font-size: 14px !important;
		font-family: 'Roboto' !important;
	}
	.ant-table-thead > tr > th {
		background: none !important;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		color: #393939;
		padding: 5px 10px;
		&::before {
			display: none;
		}
	}
	
	.ant-table-tbody > tr > td {
		color: #000;
		padding: 5px 10px !important;
		border: none !important;
	}
`

export const ActionButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	.ant-typography {
		margin: 0 2px;
		padding: 6px 6px 4px 6px;
		border-radius: 4px;
		transition: all 0.3s ease-in-out;
		&:hover {
			background: #393939;
		}
	}
`
export const Card = styled.div`
	flex-basis: 700px;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 3px 4px #eee;
`
export const SearchInput = styled.div`
	margin-right: 10px;
	.ant-input {
		width: 220px;
		margin-bottom: 10px;
		background: #f4f4f5;
		border-radius: 0.3rem;
		border-color: #f4f4f5;
		border-radius: 4px;
		box-shadow: none;
		resize: none;
		&:focus {
			border-color: #014c97;
		}
		&:hover {
			border-color: #014c97;
			box-shadow: none;
		}
	}
`

export const ProjectStatus = styled.span`
	text-transform: capitalize;
`

export const StatusBtn = styled.button`
	border:none;
	background:black;
	color:white;
	border-radius:4px;
	width:70px;
	height:28px;
	display:flex;
	justify-content:center;
	align-items:center;
`
