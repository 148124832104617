import { useEffect, useState } from "react"
import { Form, Input, Button, Space, Spin, Row, Col } from "antd"
import { Card, Wrapper, FieldName, Heading, FormWrapper, FlexCenter } from "./styles"
import { RegisterUser } from "redux/app/actions/auth"
import { useSelector, useDispatch } from "react-redux"
import { loading, setMessage } from "redux/app"
import { useNavigate } from "react-router-dom"

const Register = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const [form] = Form.useForm()

	const onFinish = async (values) => {
		dispatch(RegisterUser(values, navigate))
	}
	useEffect(() => {
		form.setFieldsValue({
			currentPassword: "",
			newPassword: "",
		})
	}, [form])

	return (
		<Wrapper>
			<div className="main-contnt">
				<div className="top-header">
					<div className="left-in">
						<h2 className="mb-0">Password Setting</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</div>
				</div>
				<Card>
					<FormWrapper>
						<Form
							form={form}
							layout="vertical"
							className="FormWrapper register-form"
							onFinish={onFinish}
						>
							<h6 className="password-title">Password & Security</h6>
							<Row>
								<Col span={8}>
									<Form.Item
										label="Current Password"
										name="currentPassword"
										dependencies={["currentPassword"]}
										rules={[
											{
												required: true,
												message: "Please enter current password.",
											},
											{
												pattern:
													/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
												message: "Incorrect password!",
											},
										]}
									>
										<Input.Password placeholder="Current Password" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label="New Password"
										name="newPassword"
										dependencies={["newPassword"]}
										rules={[
											{
												required: true,
												message: "Please enter new password.",
											},
											{
												pattern:
													/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
												message: "Incorrect password!",
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (
														!value ||
														getFieldValue("currentPassword") !== value
													) {
														return Promise.resolve()
													}
													return Promise.reject(
														new Error(
															"New password doesn’t same as current password."
														)
													)
												},
											}),
										]}
									>
										<Input.Password placeholder="New Password" />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										name="confirm"
										label="Confirm Password"
										dependencies={["newPassword"]}
										hasFeedback
										rules={[
											{
												required: true,
												message: "Please confirm your password.",
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (
														!value ||
														getFieldValue("newPassword") === value
													) {
														return Promise.resolve()
													}
													return Promise.reject(
														"The two passwords that you entered do not match!"
													)
												},
											}),
										]}
									>
										<Input.Password placeholder="Confirm Password" />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item
								className="footer-btns"
								wrapperCol={{ span: 24 }}
								style={{ textAlign: "right" }}
							>
								<Button className="cancel-btn" onClick={() => navigate(-1)}>
									Cancel
								</Button>
								<Button type="primary" htmlType="submit">
									Change Password
								</Button>
							</Form.Item>
						</Form>
					</FormWrapper>
					{dataLoading && (
						<FlexCenter>
							<Space size="medium">
								<Spin size="medium" />
							</Space>
						</FlexCenter>
					)}
				</Card>
			</div>
		</Wrapper>
	)
}

export default Register
