import { useEffect } from "react"
import { Space, Spin } from "antd"
import { FlexCenter } from "./styles"
import { EmailVerify } from "redux/app/actions/auth"
import { useSelector, useDispatch } from "react-redux"
import { loading, data, hasError } from "redux/app"
import { useNavigate } from "react-router-dom"

const EmailVerification = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const reduxData = useSelector(data)
	const hasErrors = useSelector(hasError)
	const search = window.location.search
	const params = new URLSearchParams(search)
	const payload = {
		user_id: params.get("user_id"),
		code: params.get("code"),
		intent: params.get("intent"),
	}

	useEffect(() => {
		dispatch(EmailVerify(payload, navigate))
	}, [])

	return (
		<>
			{dataLoading && (
				<FlexCenter>
					<Space size="medium">
						<Spin size="medium" />
					</Space>
				</FlexCenter>
			)}
			{reduxData && (
				<FlexCenter>
					<Space size="medium">
						<div
							style={{
								margin: "100px auto",
								fontSize: "20px",
								fontWeight: "700",
								padding: "40px",
								border: "1px solid black",
								borderRadius: "8px",
							}}
						>
							{reduxData.status_message}
						</div>
					</Space>
				</FlexCenter>
			)}
			{hasErrors && (
				<FlexCenter>
					<Space size="medium">
						<div
							style={{
								margin: "100px auto",
								fontSize: "20px",
								fontWeight: "700",
								padding: "40px",
								borde: "1px solid black",
								borderRadius: "8px",
							}}
						>
							Invalid link
						</div>
					</Space>
				</FlexCenter>
			)}
		</>
	)
}

export default EmailVerification
