import React from "react"
import { Input, Form, Modal, Select } from "antd"
import { ticket } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { singleTicket } from "redux/app/actions/ticket"
import moment from "moment"
import "./style.css"

const ViewTicket = ({ open, handleOk, ticketId }) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const ticketData = useSelector(ticket)

	useEffect(() => {
		dispatch(singleTicket(ticketId))
	}, [ticketId])

	useEffect(() => {
		form.setFieldsValue({
			ticket_batch_name: ticketData?.ticket_batch_name,
			ticket_sale_start_date: moment(ticketData.ticket_sale_start_date).format("MMMM D, YYYY h:mm A"),
			ticket_sale_start_time: moment(ticketData.ticket_sale_start_time, ["HH:mm"]).format("hh:mm a"),
			// ticket_sale_end_date: moment(ticketData.ticket_sale_end_date).format("MMMM D, YYYY h:mm A"),
			// ticket_sale_end_time: moment(ticketData.ticket_sale_end_time, ["HH:mm"]).format("hh:mm a"),
			max_ticket: ticketData?.max_ticket,
			min_ticket: ticketData?.min_ticket,
			userId:ticketData?.userId?.name,
			eventId:ticketData?.eventId?.name,
			ticket_quantity: ticketData?.ticket_quantity,
			price: ticketData?.price,
		})
	}, [ticketData])
	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="View Ticket"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form form={form} autoComplete="off">
						<div className="container createevent">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">
										Batch Name 
									</FieldName>
									<Form.Item
										name="ticket_batch_name"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div className="organizer-div">
									<FieldName className="fieldName">
										Event 
									</FieldName>
									<Form.Item
										name="eventId"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Quantity 
									</FieldName>
									<Form.Item
										name="ticket_quantity"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input disabled/>
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Start Sale Date 
									</FieldName>
									<Form.Item
										name="ticket_sale_start_date"
										rules={[
											{ required: true, message: "Please enter Start Date" },
										]}
									>
										<Input disabled/>
									</Form.Item>
								</div>
								{/* <div>
									<FieldName className="fieldName">
										End Sale Date 
									</FieldName>
									<Form.Item
										name="ticket_sale_end_date"
										rules={[
											{ required: true, message: "Please enter Start Date" },
										]}
									>
										<Input disabled/>
									</Form.Item>
								</div> */}
								<div>
									<FieldName className="fieldName">
										Minimum Ticket By Order 
									</FieldName>
									<Form.Item
										name="min_ticket"
										rules={[
											{
												required: true,
												message: "Please enter Minimum Tickets",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
							</div>
							<div>
								<div className="event-div">
									<FieldName className="fieldName">
										Add Organizer 
									</FieldName>
									<Form.Item
										name="userId"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Price 
									</FieldName>
									<Form.Item
										name="price"
										rules={[
											{
												required: true,
												message: "Please enter Price",
											},
											{
												pattern: /^\d+(\.\d{1,2})?$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Start Sale Time 
									</FieldName>
									<Form.Item
										name="ticket_sale_start_time"
									>
										<Input disabled />
									</Form.Item>
								</div>
								{/* <div>
									<FieldName className="fieldName">
										Start Sale Time 
									</FieldName>
									<Form.Item
										name="ticket_sale_end_time"
									>
										<Input disabled />
									</Form.Item>
								</div> */}
								<div>
									<FieldName className="fieldName">
										Maximum Tickets By Order 
									</FieldName>
									<Form.Item
										name="max_ticket"
										rules={[
											{
												required: true,
												message: "Please enter Maximum Tickets",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default ViewTicket
