import { notification } from "antd"
import {
    removeOffer,
    requestCompleted,
    requestStart,
    setOffer,
    setOffers,
    updateActiveOffer,

} from "redux/app"
import { axios } from 'lib/axios'
import { axiosIntercepter } from "redux/axios"
import moment  from "moment"


export function getAllOffers() {
    return async (dispatch) => {
        let response = await axiosIntercepter.get(`/offer`)
        return dispatch(setOffers(response.data))

    }
}
export function deleteOffer(id) {
    return async (dispatch) => {
     await axiosIntercepter.patch(`/offer/delete/${id}`)
     return dispatch(removeOffer({id})) 

    }
}
export function createOffer(payload,coverImage,eventId,status) {
    return async (dispatch) => {
        dispatch(requestStart())
        var formData = require("form-data")
        var data = new formData()
        data.append("image", coverImage)
        data.append("offer_discount", payload.offer_discount)
        data.append("event_offer_coupon_code", payload.event_offer_coupon_code)
        data.append("event_offer_validity", moment(payload.event_offer_validity).format("DD/MM/YYYY"))
        data.append("event_offer_max_quantity", payload.event_offer_max_quantity)
        data.append("status",status)
        data.append("eventId", eventId)
        var config = {
            method: "post",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: "/offer",
            data: data,
        }
        axios(config)
            .then(function (response) {
                if (response.data.status_code === 200) {
                    dispatch(requestCompleted())
                    dispatch(getAllOffers())
                    notification["success"]({
                        message: "Offer added successfully",
                        duration: 2,
                    })
                } else if (response.data.status_code === 401) {
                    notification["error"]({
                        message: response.data.status_message,
                        duration: 2,
                    })
                } else {
                    notification["error"]({
                        message: "Offer not added",
                        duration: 2,
                    })
                }
                dispatch(requestCompleted())
            })
            .catch(function (error) {
                notification["error"]({
                    message: "Error Occured",
                    duration: 2,
                })
                dispatch(requestCompleted())
            })
        dispatch(requestStart())
    }
}
export function getOffer(id) {
    return async (dispatch) => {
        let response = await axiosIntercepter.get(`/offer/${id}`)
        return dispatch(setOffer(response.data))

    }
}


export function activeStatus(id) {
    return async (dispatch) => {
        dispatch(requestStart())
        await axiosIntercepter.patch(`offer/active/${id}`);
        return dispatch(updateActiveOffer({ id }))

    }
}

export function updateOffer(payload,coverImage,id,status) {
    return async (dispatch) => {
        dispatch(requestStart())
        var formData = require("form-data")
        var data = new formData()
        if(coverImage){
            data.append("image", coverImage)
        }
        
        data.append("offer_discount", payload.offer_discount)
        data.append("event_offer_coupon_code", payload.event_offer_coupon_code)
        data.append("event_offer_validity", moment(payload.event_offer_validity).format("DD/MM/YYYY"))
        data.append("event_offer_max_quantity", payload.event_offer_max_quantity)
        data.append("status",status)
        var config = {
            method: "put",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `/offer/${id}`,
            data: data
        }
        axios(config)
            .then(function (response) {
                if (response.data.status_code === 200) {
                    dispatch(getAllOffers())
                    dispatch(requestCompleted())
                    notification["success"]({
                        message: "Offer Updated successfully",
                        duration: 2,
                    })
                } else if (response.data.status_code === 401) {
                    notification["error"]({
                        message: response.data.status_message,
                        duration: 2,
                    })
                } else {
                    notification["error"]({
                        message: "Offer not Updated",
                        duration: 2,
                    })
                }
                dispatch(requestCompleted())
            })
            .catch(function (error) {
                notification["error"]({
                    message: "Error Occured",
                    duration: 2,
                })
                dispatch(requestCompleted())
            })
        dispatch(requestStart())
    
}
}
export function getOfferByEventId(eventId) {
    return async (dispatch) => {
        let response = await axiosIntercepter.get(`/offer/event/${eventId}`)
        return dispatch(setOffers(response.data))

    }
}