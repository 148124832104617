import moment from "moment"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { gender, revenue } from "redux/app"
import { getGenders } from "redux/app/actions/booking"

function ApexChart() {
	const genderData = useSelector(gender)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)
	const [options, setOptions] = useState({
		series: [
			{
				name: "Gender",
				data: [genderData.male , genderData.female, genderData.other],
			},
		],
		options: {
			chart: {
				type: "bar",
				height: 150,
				width: "100%",
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "25%",
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: false,
			},

			stroke: {
				show: true,
				width: 3,
				colors: ["transparent"],
			},
			xaxis: {
				categories: ["Male", "Female", "Other"],
			},
			yaxis: {
				title: {
					text: "$ (Gender)",
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " Gender"
					},
				},
			},
		},
	})

	useEffect(() => {
		if (genderData) {
			setLoading(true)

			const male = genderData?.male ?? {}
			const female = genderData?.female ?? {}
			const other = genderData?.other ?? {}

			const copyOptions = { ...options }
			options.series[0].data = [male,female,other]
			setOptions(copyOptions)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
	}, [genderData])
	useEffect(() => {
		dispatch(getGenders())
	}, [])

	return (
		<div id="chart">
			{!loading && (
				<ReactApexChart
					options={options.options}
					series={options.series}
					type="bar"
					width="470"
					height={260}
				/>
			)}
		</div>
	)
}
export default ApexChart
