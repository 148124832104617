import React, { useState } from "react"
import { Button, Input, Form, Modal, Select, Upload } from "antd"
import { events, singleEvent } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "../EventManagement/style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getAllEvents, getEvent } from "redux/app/actions/event"
import { addTicket } from "redux/app/actions/ticket"
import "./styles.css"
import { Option } from "antd/lib/mentions"
import { createOffer } from "redux/app/actions/offers"
import PublishEvent from "./PublishEvent"

const AddOffer = ({ open, handleCancel, eventId, ticketCreated }) => {
	const dispatch = useDispatch()
	const eventData = useSelector(singleEvent)
	const [form] = Form.useForm()
	const [submitted, setSubmitted] = useState(false)
	const [status, setStatus] = useState(false)
	const [publishModal, setPublishModal] = useState(false)
	const [event_id, setEventId] = useState("")
	const event = useSelector(events)
	const [addCoverImage, setAddCoverImage] = useState([])

	useEffect(() => {
		if (eventId) {
			dispatch(getEvent(eventId))
		}
	}, [eventId])
	useEffect(() => {
		dispatch(getAllEvents())
	}, [])
	useEffect(() => {
		form.setFieldsValue({
			eventName: eventData?.name,
		})
	}, [eventData])

	const addOffer = (value) => {
		if (ticketCreated) {
			const coverImage = addCoverImage["0"] ? addCoverImage["0"]["originFileObj"] : null
			dispatch(createOffer(value, coverImage, eventData?._id, status))
			handleCancel()
			setPublishModal(true)
		} else {
			const coverImage = addCoverImage["0"] ? addCoverImage["0"]["originFileObj"] : null
			dispatch(createOffer(value, coverImage, event_id, status))
			handleCancel()
		}
	}
	const beforeUpload = () => {
		return false
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={() => handleCancel(form.resetFields())}
				title="Create New Offer"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					{/* <div className="event-upload">
						<Upload
							action={process.env.Action}
							listType="picture-card"
							fileList={addCoverImage}
							beforeUpload={beforeUpload}
							onChange={({ fileList: newFileList }) => {
								setAddCoverImage(newFileList)
							}}
						>
							{addCoverImage?.length < 1 && <span className="pls-icon">+</span>}
						</Upload>
					</div> */}

					<Form form={form} onFinish={addOffer}>
						<div className="container createevent add-ticket">
							<div className="addevent">
								<div className="eventNameDiv">
									<FieldName className="fieldName">Event Name</FieldName>
									{ticketCreated ? (
										<Form.Item name="eventName">
											<Input disabled />
										</Form.Item>
									) : (
										<Select
											placeholder="Select an event"
											onChange={(value) => {
												setEventId(value)
											}}
											style={{ width: "100%" }}
										>
											{event.map((item) => (
												<Option value={item._id}>{item.name}</Option>
											))}
										</Select>
									)}
								</div>
								<div>
									<FieldName className="fieldName">Offer Code</FieldName>
									<Form.Item
										name="event_offer_coupon_code"
										rules={[
											{
												required: true,
												message: "Please enter Event Coupon code",
											},
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Quantity</FieldName>
									<Form.Item
										name="event_offer_max_quantity"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">Offer %</FieldName>
									<Form.Item
										name="offer_discount"
										rules={[
											{
												required: true,
												message: "Please enter Offer Discount",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Expiry Date</FieldName>
									<Form.Item
										name="event_offer_validity"
										rules={[
											{
												required: true,
												message: "Please enter Expiry Date",
											},
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Select status</FieldName>
									<Select
										placeholder="Select"
										onChange={(value) => {
											setStatus(value)
										}}
										style={{ width: "100%" }}
									>
										<Option value={true}>Active</Option>
										<Option value={false}>Inactive</Option>
									</Select>
								</div>
								<div></div>
							</div>
						</div>
						<div className="modal-btns">
							<Button
								className="cancel-btn"
								onClick={() => {
									handleCancel(form.resetFields())
									setPublishModal(true)
								}}
							>
								Skip
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Submit
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
			<PublishEvent
				open={publishModal}
				handleCancel={() => setPublishModal(false)}
				eventId={eventId}
			/>
		</>
	)
}

export default AddOffer
