import React, { useState, useEffect } from "react"
import { ContentWrapper } from "./style"
import { Button, Card, Col, Row } from "antd"
import { users } from "redux/app"
import "./styles.css"
import { Form, Select } from "antd"
import { FieldName } from "pages/AddUser/styles"
import { Input, Radio } from "antd"
import { axiosIntercepter } from "redux/axios"
import { notification } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { getAllUsers } from "redux/app/actions/auth"

const { Option } = Select

function onChange(value) {
	console.log(`selected ${value}`)
}

function onBlur() {
	console.log("blur")
}

function onFocus() {
	console.log("focus")
}

function onSearch(val) {
	console.log("search:", val)
}

const SendNotification = (props) => {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const usersList = useSelector(users)

	useEffect(() => {
		dispatch(getAllUsers())
	}, [])

	const [userTypes, setUserTypes] = useState(1)
	const editEvent = async (values) => {
		try {
			axiosIntercepter.post("notification/send", { ...values, userTypes })
			notification["success"]({
				message: "Notification sending in progress",
				duration: 2,
			})
			form.resetFields()
		} catch (e) {
			notification["error"]({
				message: e.message,
				duration: 2,
			})
		}
	}

	const setVal = (e) => {
		setUserTypes(e.target.value)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<p>Send Notifications</p>
					</div>
					<Row gutter={[12, 12]}>
						<Col span={12}>
							<Card>
								<Form onFinish={editEvent} form={form} autoComplete="off">
									<div>
										<div style={{ marginBottom: "20px" }}>
											<Radio.Group
												name="usertypes"
												onChange={setVal}
												defaultValue={1}
											>
												<Radio value={1}>All Users</Radio>
												<Radio value={2}>Select Users</Radio>
											</Radio.Group>
										</div>
										{userTypes === 2 && (
											<>
												<FieldName className="fieldName">Send to</FieldName>
												<Form.Item
													name="send_to"
													rules={[
														{
															required: true,
															message: "Please select users",
														},
													]}
												>
													<Select
														mode="multiple"
														showSearch
														style={{ width: 400 }}
														placeholder="Please select users"
														optionFilterProp="children"
														onChange={onChange}
														onFocus={onFocus}
														onBlur={onBlur}
														onSearch={onSearch}
														filterOption={(input, option) => {
															console.log(
																option.props.label,
																input
															)
															return (
																option.props.label
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															)
														}}
														options={
															usersList?.map((i) => ({
																label: i.name,
																value: i._id,
															})) ?? []
														}
													/>
													{/* <Option value="jack">Jack</Option>
														<Option value="lucy">Lucy</Option>
														<Option value="tom">Tom</Option>
													</Select> */}
													{/* <Select
														mode="multiple"
														placeholder="Please select users"
														style={{
															width: 400,
														}}
														options={
															usersList?.map((i) => ({
																label: i.name,
																value: i._id,
															})) ?? []
														}
													/> */}
												</Form.Item>
											</>
										)}
									</div>
									<div>
										<FieldName className="fieldName">Title</FieldName>
										<Form.Item
											name="title"
											rules={[
												{ required: true, message: "Please enter title" },
												{ min: 3 },
											]}
										>
											<Input placeholder="Please enter title" />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">Message</FieldName>
										<Form.Item
											name="message"
											rules={[
												{ required: true, message: "Please enter message" },
												{ min: 3 },
											]}
										>
											<Input placeholder="Please enter message" />
										</Form.Item>
									</div>
									<div>
										<Button className="action-btn save-btn " htmlType="submit">
											Submit
										</Button>
									</div>
								</Form>
							</Card>
						</Col>
					</Row>
				</div>
			</ContentWrapper>
		</>
	)
}

export default SendNotification
