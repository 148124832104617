import React, { useEffect } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper, FieldName, StatusBtn1, StatusBtn2 } from "./style"
import { Input, Form, Row, Col, Upload, Popconfirm, Card, Table } from "antd"
import { StatusBtn } from "./style"
import Eye from "assets/icons/eye.png"
import { useDispatch, useSelector } from "react-redux"
import Placeholder from "assets/icons/placeholder.png"
import {
	activeUser,
	deleteUserById,
	getAllUsers,
	updateProfileById,
	updateRole,
} from "redux/app/actions/auth"
import { requestCompleted, userProfile, users } from "redux/app"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { Button, Modal } from "antd"
import AddUser from "../AddUser"
import "./style.css"
import Search from "antd/lib/transfer/search"
import EditUser from "pages/EditUser"
import ViewProfile from "./components/ViewProfile"

const UserManagement = () => {
	const userProfileData = useSelector(userProfile)
	const [isViewOpen, setIsViewOpen] = useState(false)
	const [viewBusiness, setViewBusiness] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [imgFile, setImgFile] = useState([])
	const [data, setData] = useState({})
	const dispatch = useDispatch()
	const usersList = useSelector(users)
	const [form] = Form.useForm()
	const [viewArtist, setViewArtist] = useState(false)
	const [viewOrganizer, setViewOrganizer] = useState(false)
	const [id, setId] = useState("")
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const [searchText, setSearchText] = useState("")
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [userId, setUserId] = useState("")
	const [editModal, setEditModal] = useState(false)
	const [viewProfileModal, setViewProfileModal] = useState(false)

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			filteredValue: [searchText],
			onFilter: function (value, record) {
				return (
					String(record.name).toLowerCase().includes(value.toLowerCase()) ||
					String(record.phoneNumber).toLowerCase().includes(value.toLowerCase()) ||
					String(record.email).toLowerCase().includes(value.toLowerCase()) ||
					String(record.currentRole).toLowerCase().includes(value.toLowerCase())
				)
			},
			sorter: (a, b) => {
				return a.name.localeCompare(b.name)
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				return a.email.localeCompare(b.email)
			},
		},
		{
			title: "User Type",
			dataIndex: "currentRole",
			key: "currentRole",
			sorter: (a, b) => {
				return a.currentRole.localeCompare(b.currentRole)
			},
		},
		// {
		// 	title: "Phone Number",
		// 	dataIndex: "phoneNumber",
		// 	key: "phoneNumber",
		// },
		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeUser(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.active === true ? (
							<StatusBtn1 style={{ cursor: "pointer" }}>Active</StatusBtn1>
						) : (
							<StatusBtn2 style={{ cursor: "pointer" }}>Inactive</StatusBtn2>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<i
							onClick={() => {
								setEditModal(true)
								setUserId(row._id)
							}}
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
								cursor: "pointer",
							}}
						></i>

						<Popconfirm
							title="Are you sure , you want to delete the user?"
							onConfirm={() => {
								dispatch(deleteUserById(row._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							{" "}
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "20px",
										marginRight: "10px",
									}}
								></i>
							</span>{" "}
						</Popconfirm>
						<img
							id="EditUserBtn"
							src={Eye}
							alt=""
							style={{ height: "24px", cursor: "pointer" }}
							onClick={() => {
								setViewProfileModal(true)
								setUserId(row._id)
							}}
						/>
					</div>
				</>
			),
		},
	]

	const beforeUpload = () => {
		return false
	}

	useEffect(() => {
		
		(getAllUsers())
	}, [])

	const handleBusinessOk = () => {
		setViewBusiness(false)
	}
	const showArtist = () => {
		setSubmitted(true)
		dispatch(updateRole(userId, "artist"))
		setViewBusiness(false)
		setViewArtist(true)
	}
	const showOrganizer = () => {
		dispatch(updateRole(userId, "organizer"))
		setViewBusiness(false)
		setViewOrganizer(true)
	}
	const handleArtistOk = () => {
		setViewArtist(false)
	}
	const handleOrganizerOk = () => {
		setViewOrganizer(false)
	}
	useEffect(() => {
		setData(userProfileData)
		if (userProfileData?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: userProfileData?.image,
				},
			])
		}
	}, [userProfileData])

	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			phoneNumber: data?.phoneNumber,
			email: data?.email,
			organizerDescription: data?.organizerDescription,
			artistDescription: data?.artistDescription,
			socialLink1: data?.socialLink1,
			socialLink2: data?.socialLink2,
			socialLink3: data?.socialLink3,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])
	const editUser = (values) => {
		const socialLinks = {
			socialLink1: values.socialLink1 || undefined,
			socialLink2: values.socialLink2 || undefined,
			socialLink3: values.socialLink3 || undefined,
		  };
		// Remove undefined values
		const filteredSocialLinks = Object.fromEntries(
			Object.entries(socialLinks).filter(([_, value]) => value !== undefined)
		  );

		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		dispatch(updateProfileById({ ...values, ...filteredSocialLinks }, image, userId));
		if (viewArtist) {
			setViewArtist(false)
		}
		if (viewOrganizer) {
			setViewOrganizer(false)
		}
		dispatch(requestCompleted)
	}
	const objectStyle = {
		color: "black",
		textAlign: "center",
	}
	const objectStyleValid = {
		color: "blue",
		textAlign: "center",
	}
	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">User Management</h2>
							<p> List Of All Users</p>
						</div>
						<div className="right-in">
							{/* <ActionBtn title="Add User" link="/add-user" /> */}
							<Button type="primary" onClick={showModal}>
								Add User
							</Button>
						</div>
					</div>
					<AddUser open={isModalOpen} handleCancel={hideModal} />
					<ViewProfile
						open={viewProfileModal}
						handleCancel={() => setViewProfileModal(false)}
						onOk={() => setViewProfileModal(false)}
						userId={userId}
						showBusiness={() => {
							setViewBusiness(true)
							setViewProfileModal(false)
						}}
					/>
					<Card>
						<div className="card-header user-manage-search">
							<h3>Users List</h3>
							<Input.Search
								onSearch={(value) => {
									setSearchText(value)
								}}
								onChange={(e) => {
									setSearchText(e.target.value)
								}}
								placeholder="Search....."
							/>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={usersList}
						/>
					</Card>

					<Modal
						title="Business Account"
						open={viewBusiness}
						onOk={handleBusinessOk}
						onCancel={handleBusinessOk}
					>
						<div
							style={{
								display: "flex",
								marginLeft: "auto",
								width: "100%",
								margin: "auto",
							}}
						>
							<div
								onClick={showArtist}
								className="avatar-wrapper artist"
								style={{ cursor: "pointer" }}
							>
								<div className="profile-avatar">
									<Upload listType="picture-card" fileList={imgFile} disabled>
										{imgFile?.length < 1 && "No Image"}
									</Upload>
									<h2
										style={
											userProfileData.currentRole === "artist"
												? objectStyleValid
												: objectStyle
										}
									>
										Artist
									</h2>
								</div>
							</div>
							<div
								onClick={showOrganizer}
								className="avatar-wrapper artist"
								style={{ cursor: "pointer" }}
							>
								<div className="profile-avatar">
									<Upload listType="picture-card" fileList={imgFile} disabled>
										{imgFile?.length < 1 && "No Image"}
									</Upload>
									<h2
										style={
											userProfileData.currentRole === "organizer"
												? objectStyleValid
												: objectStyle
										}
									>
										Organizer
									</h2>
								</div>
							</div>
						</div>
					</Modal>

					<Modal
						title="Artist Information"
						width={800}
						open={viewArtist}
						onCancel={handleArtistOk}
						footer={null}
					>
						<ContentWrapper>
							<div className="avatar-wrapper" style={{ justifyContent: "center" }}>
								<Upload
									listType="picture-card"
									action={process.env.Action}
									beforeUpload={beforeUpload}
									fileList={imgFile}
									onChange={({ fileList: newFileList }) => {
										setImgFile(newFileList)
									}}
									onRemove={(value) => {
										setImgFile([])
									}}
								>
									{imgFile?.length < 1 && (
										<img
											style={{ width: "100%" }}
											src={Placeholder}
											alt="placeholder"
										/>
									)}
								</Upload>
							</div>
							<div className="mt-30">
								<Form onFinish={editUser} form={form} autoComplete="off">
									<Row className="profile-form">
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Name
											</FieldName>
											<Form.Item
												name="name"
												rules={[
													{ required: true, message: "Name is required" },
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Email
											</FieldName>
											<Form.Item name="email">
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Phone Number
											</FieldName>
											<Form.Item
												name="phoneNumber"
												rules={[
													{
														required: true,
														message: "Phone number is required",
													},
													{
														pattern: /^[0-9]+$/,
														message: "Incorrect Phone Number",
													},
													{
														min: 5,
														message:
															"Phone Number must be minimum 6 characters.",
													},
													{
														max: 15,
														message:
															"Phone Number must be minimum 15 characters.",
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Short Description
											</FieldName>
											<Form.Item name="artistDescription">
												<Input />
											</Form.Item>
										</Col>
										<Col span={18}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Social Links
											</FieldName>
											<Form.Item name="socialLink1">
												<Input placeholder="Facebook" />
											</Form.Item>
											<Form.Item name="socialLink2">
												<Input placeholder="Instagram" />
											</Form.Item>
											<Form.Item name="socialLink3">
												<Input placeholder="Tiktok" />
											</Form.Item>
										</Col>

										<Col
											span={24}
											style={{
												justifyContent: "flex-end",
												display: "flex",
												margin: "0",
											}}
										>
											<Button
												className="action-btn save-btn "
												htmlType="submit"
												onClick={() => {
													setSubmitted(true)
												}}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Form>
							</div>
						</ContentWrapper>
					</Modal>

					<Modal
						width={800}
						title="Organizer Information"
						footer={null}
						onCancel={handleOrganizerOk}
						open={viewOrganizer}
					>
						<ContentWrapper>
							<div className="avatar-wrapper" style={{ justifyContent: "center" }}>
								<Upload
									listType="picture-card"
									action={process.env.Action}
									beforeUpload={beforeUpload}
									fileList={imgFile}
									onChange={({ fileList: newFileList }) => {
										setImgFile(newFileList)
									}}
									onRemove={(value) => {
										setImgFile([])
									}}
								>
									{imgFile?.length < 1 && (
										<img
											style={{ width: "100%" }}
											src={Placeholder}
											alt="placeholder"
										/>
									)}
								</Upload>
							</div>
							<div className="mt-30">
								<Form onFinish={editUser} form={form} autoComplete="off">
									<Row className="profile-form">
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Name
											</FieldName>
											<Form.Item
												name="name"
												rules={[
													{ required: true, message: "Name is required" },
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Email
											</FieldName>
											<Form.Item name="email">
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Phone Number
											</FieldName>
											<Form.Item
												name="phoneNumber"
												rules={[
													{
														required: true,
														message: "Phone number is required",
													},
													{
														pattern: /^[0-9]+$/,
														message: "Incorrect Phone Number",
													},
													{
														min: 5,
														message:
															"Phone Number must be minimum 6 characters.",
													},
													{
														max: 15,
														message:
															"Phone Number must be minimum 15 characters.",
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Short Description
											</FieldName>
											<Form.Item name="organizerDescription">
												<Input />
											</Form.Item>
										</Col>
										<Col span={18}>
											<FieldName
												style={{
													color: "black",
													fontFamily: "Myriad Pro,sans-serif",
												}}
											>
												Social Links
											</FieldName>
											<Form.Item name="socialLink1">
												<Input placeholder="Facebook" />
											</Form.Item>
											<Form.Item name="socialLink2">
												<Input placeholder="Instagram" />
											</Form.Item>
											<Form.Item name="socialLink3">
												<Input placeholder="Tiktok" />
											</Form.Item>
										</Col>

										<Col
											span={24}
											style={{
												justifyContent: "flex-end",
												display: "flex",
												margin: "0",
											}}
										>
											<Button
												className="action-btn save-btn"
												style={{ margin: "0" }}
												htmlType="submit"
												onClick={() => {
													setSubmitted(true)
												}}
											>
												Save
											</Button>
										</Col>
									</Row>
								</Form>
							</div>
						</ContentWrapper>
					</Modal>
					<EditUser
						open={editModal}
						handleCancel={() => setEditModal(false)}
						userId={userId}
					/>
				</div>
			</ContentWrapper>
		</>
	)
}

export default UserManagement
