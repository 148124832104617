import React, { useEffect, useState } from "react"
import { Button, Input, Form, Upload, Row, Col, Modal } from "antd"
import { loading } from "redux/app"
import { useSelector } from "react-redux"
import {
	ContentWrapper,
	InputWrapper,
	FieldName,
	ActionButtons,
	LoadingSpinner,
	Card,
} from "./styles"
import { Spin, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { registerNewUser } from "redux/app/actions/auth"
import { useDispatch } from "react-redux"
import { getAllUsers } from "redux/app/actions/auth"
import "./style.css"
import moment from "moment"

const AddUser = ({ open, handleCancel }) => {
	const [data, setData] = useState({})
	const [submitted, setSubmitted] = useState(false)
	const [imgFile, setImgFile] = useState([])
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const dispatch = useDispatch()
	const beforeUpload = () => {
		return false
	}
	const validatePassword = (rule, value, callback) => {
		if (value && value !== "Secret") {
			callback("Error!")
		} else {
			callback()
		}
	}

	const addUser = (values) => {
		const choosenDate = moment(values.DOB, "YYYY-MM-DD")
		const todayDate = moment()
		const years = todayDate.diff(choosenDate, "year")
		if (years < 13) {
			form.setFields([
				{
					name: "DOB",
					errors: ["Age should be more than 13"],
				},
			])
			return
		}

		form.setFieldsValue({
			image: data?.image,
		})
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		dispatch(registerNewUser(values, image))
		handleCancel()
	}

	useEffect(() => {
		dispatch(getAllUsers())
	}, [])

	return (
		<>
			<Modal
				open={open}
				onCancel={() => handleCancel(form.resetFields())}
				title="Add New User"
				width={900}
				height={500}
				footer={null}
			>
				<div className="main-modal">
					<Form
						className="adduser-form"
						onFinish={addUser}
						form={form}
						autoComplete="off"
					>
						<InputWrapper>
							<div className="avatar-wrapper">
								<Upload
									action={process.env.Action}
									listType="picture-card"
									fileList={imgFile}
									beforeUpload={beforeUpload}
									onChange={({ fileList: newFileList }) => {
										setImgFile(newFileList)
									}}
								>
									{imgFile?.length < 1 && "+ Upload"}
								</Upload>
							</div>
						</InputWrapper>
						<Row>
							<Col span={12}>
								<FieldName className="fieldName">
									Name <span>*</span>
								</FieldName>
								<Form.Item
									name="name"
									rules={[
										{ required: true, message: "Please enter Name" },
										{ whitespace: true },
										{ min: 3 },
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<FieldName className="fieldName">
									Email <span>*</span>
								</FieldName>
								<Form.Item
									name="email"
									rules={[{ required: true, message: "Please enter Email" }]}
								>
									<Input type="email" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<FieldName className="fieldName">
									Phone Number <span>*</span>
								</FieldName>
								<Form.Item
									name="phoneNumber"
									rules={[
										{
											required: true,
											message: "Phone number is required",
										},
										{
											pattern: /^(\+?[0-9]{1,3})?[0-9]{6,15}$/,
											message: "Incorrect Phone Number",
										},
										{
											min: 5,
											message: "Phone Number must be minimum 6 characters.",
										},
										{
											max: 15,
											message: "Phone Number must be minimum 15 characters.",
										},
									]}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<FieldName className="fieldName">
									DOB <span>*</span>
								</FieldName>
								<Form.Item
									name="DOB"
									rules={[
										{
											required: true,
											message: "Please enter DOB",
											min: "2011-03-20",
										},
									]}
								>
									<Input type="date" />
								</Form.Item>
							</Col>
							<Col span={24}>
								<FieldName className="fieldName">
									Password <span>*</span>
								</FieldName>
								<Form.Item
									className="passwordcol"
									name="password"
									rules={[
										{ required: true, message: "Please Enter  password" },
										{
											min: 8,
											message: "Password must have a minimum length of 8",
										},
										{
											pattern: new RegExp(
												"^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
											),
											message:
												"Password must contain at least one lowercase letter, uppercase letter, number, and special character",
										},
									]}
								>
									<Input.Password className="password" placeholder="Password" />
								</Form.Item>
							</Col>
						</Row>

						<div className="avatar-wrapper"></div>
						<ActionButtons className="footer-btns">
							<Button
								className="action-btn"
								onClick={() => handleCancel(form.resetFields())}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								className="action-btn save-btn "
								style={{ marginLeft: "15px" }}
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Save
							</Button>
							<LoadingSpinner>
								{dataLoading && (
									<Space size="middle">
										<Spin size="large" />
									</Space>
								)}
							</LoadingSpinner>
						</ActionButtons>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default AddUser
