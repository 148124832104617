import styled from "styled-components"

export const MenuWrapper = styled.div`
	.ant-menu-title-content {
		font-size: 14px;
		color: #fff;
		font-weight: 500;
	}
	li.ant-menu-item {
		margin: 0;
		border-radius: 0px !important;
	}
	.ant-menu-item  i {
		margin-right: 14px;
		font-size: 18px;
		height: 20px;
		width: 22px;
	}
	.menu-collpase {
		background: transparent;
		border: none !important;
	}
	.inner-submenu {
		padding-left: 30px;
	}
	.menu-collpase .ant-collapse-expand-icon {
		position: absolute;
		right: 0;
	}
	.inner-submenu li.ant-menu-item.ant-menu-item-active.ant-menu-item-selected span {
		color: #000 !important;
	}
	
	.inner-submenu li.ant-menu-item.ant-menu-item-active.ant-menu-item-selected , .inner-submenu li.ant-menu-item.ant-menu-item-selected:focus  {
		background: #fff !important;
		color: #000 !important;
	}
	.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
		margin-left: 35px;
	}
	.inner-submenu li.ant-menu-item::before {
		content: "";
		background: #000;
		height: 10px;
		width: 10px;
		border-radius: 100%;
		left: 0;
		position: absolute;
	}
	.inner-submenu li.ant-menu-item {
		position: relative;
	}
	.ant-menu-title-content {
		width: max-content;
	}
	.menu-collpase .ant-collapse-extra {
		position: absolute;
		left: 15px;
		font-size: 18px;
		color: #000;
		top: 10px;
	}
	.ant-menu .ant-menu-title-content svg path {
		fill: #000;
	}
	.ant-menu .ant-menu-title-content svg {
		height: 20px;
		margin-right: 15px;
		width: 20px;
		object-fit: contain;
	}
	.menu-collpase .ant-collapse-extra svg {
		height: 20px;
		width: 20px;
		object-fit: contain;
	}
	span.ant-menu-title-content {
		display: flex;
		align-items: center;
	}
	.ant-layout-sider-collapsed .menu-collpase span.ant-collapse-header-text  {
		display: none;
	}
	.menu-collpase span.ant-collapse-header-text {
		font-weight: 500;
		font-size: 14px;
		color: #000;
	}
	.menu-collpase .ant-collapse-item {
		border: none !important;
		background: transparent !important;
	}
	ul.ant-menu {
		background: transparent !important;
	}

	.ant-menu-item-selected {
		background: #235CEA !important;
	}
	.ant-menu-item {
		svg path {
			fill: #bebfc1;
		}

		&.ant-menu-item-selected {
			svg path {
				fill: #ffffff;
			}
		}
	}
`

export const MenuButton = styled.div`
	color: #fff !important;
`

export const ButtonWrapper = styled.div`
	margin-top: 200px;
	width: 200px;
`
