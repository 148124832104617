import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import ActionBtn from "components/utility/TopBarActionBtn"
import { Link } from "react-router-dom"
import { data, events, interests, offers, offer } from "redux/app"
import { Popconfirm, Table, Card, Modal, Upload, Form, Button, Input, Select } from "antd"
import { StatusBtn } from "./styles"
import {
	createOffer,
	deleteOffer,
	getAllOffers,
	getOffer,
	activeStatus,
	updateOffer,
} from "redux/app/actions/offers"
import { FieldName } from "pages/EventManagement/style"
import Placeholder from "assets/icons/placeholder.png"
import { getAllEvents } from "redux/app/actions/event"
import { Option } from "antd/lib/mentions"
import moment from "moment"
import badurl from "assets/icons/badurl.png"
import Eye from "assets/icons/eye.png"
import "./styles.css"
import AddOffer from "./AddOffer"

const OfferManagement = () => {
	const dispatch = useDispatch()
	const offersData = useSelector(offers)
	const singleOffer = useSelector(offer)
	const [page, setPage] = useState(1)
	const [submitted, setSubmitted] = useState(false)
	const [paginationSize, setPaginationSize] = useState(10)
	const [addUserOpen, setAddUserOpen] = useState(false)
	const [cover, setCover] = useState([])
	const [form] = Form.useForm()
	const event = useSelector(events)
	const [event_Id, setEventId] = useState("")
	const [status, setStatus] = useState(false)
	const [data, setData] = useState({})
	const [id, setId] = useState("")
	const [viewOffer, setViewOffer] = useState(false)
	const [editOffer, setEditOffer] = useState(false)
	const [selectValue, setSelectValue] = useState("")
	const [event_name, setEventName] = useState("")
	const [addCoverImage, setAddCoverImage] = useState([])

	useEffect(() => {
		dispatch(getAllEvents())
	}, [])
	useEffect(() => {
		setData(singleOffer)

		if (singleOffer?.image) {
			setCover([
				{
					uid: "1",
					name: "image.png",
					status: "done",
					url: singleOffer?.image,
				},
			])
		}
		if (singleOffer?.status) {
			setStatus(true)
		} else if (singleOffer.status === false) {
			setSelectValue("Inactive")
		}
		if (singleOffer?.eventId) {
			setEventId(singleOffer.eventId._id)
		}
	}, [singleOffer])
	useEffect(() => {
		dispatch(getOffer(id))
	}, [id, offersData])

	useEffect(() => {
		form.setFieldsValue({
			eventName: data?.eventId?.name,
			offer_discount: data?.offerDiscount,
			event_offer_coupon_code: data.event_offer_coupon_code,
			offer_discount: data?.offer_discount,
			event_offer_validity: moment(data?.event_offer_validity).format("YYYY-MM-DD"),
			event_offer_max_quantity: data?.event_offer_max_quantity,
			status: data?.status === true ? "Active" : "Inactive",
		})
	}, [data])

	const onImageError = (e) => {
		e.target.src = badurl
	}

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Event Name",
			dataIndex: "eventId",
			key: "name",
			render: (item) => item?.name,
			sorter: (a, b) => a.eventId?.name.localeCompare(b.eventId?.name),
		},
		{
			title: "Offer %",
			dataIndex: "offer_discount",
			key: "offer_discount",
			sorter: (a, b) => a.offer_discount - b.offer_discount,
		},
		{
			title: "Offer Code",
			dataIndex: "event_offer_coupon_code",
			key: "event_offer_coupon_code",
		},
		{
			title: "Expiry Date",
			dataIndex: "event_offer_validity",
			key: "event_offer_validity",
			sorter: (a, b) => a.event_offer_validity.localeCompare(b.event_offer_validity),
			render: (value, row, index) => {
				return <span>{moment(value).format("DD MMMM, YYYY h:mm A")}</span>
			},
		},
		{
			title: "Quantity",
			dataIndex: "event_offer_max_quantity",
			key: "event_offer_max_quantity",
			sorter: (a, b) => a.event_offer_max_quantity - b.event_offer_max_quantity,
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<img
							src={Eye}
							alt=""
							style={{ height: "24px", marginRight: "10px", cursor: "pointer" }}
							onClick={() => {
								setId(row._id)
								setViewOffer(true)
							}}
						/>
						<Link
							onClick={() => {
								setId(row._id)
								setEditOffer(true)
							}}
						>
							<i
								class="fa-solid fa-pencil"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</Link>
						<Popconfirm
							title="Are you sure , you want to delete the record?"
							onConfirm={() => {
								dispatch(deleteOffer(row?._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "20px",
										marginRight: "10px",
									}}
								></i>
							</span>{" "}
						</Popconfirm>
					</div>
				</>
			),
		},
	]

	useEffect(() => {
		dispatch(getAllOffers())
	}, [])
	const beforeUpload = () => {
		return false
	}

	const addOffer = (value) => {
		const coverImage = addCoverImage["0"] ? addCoverImage["0"]["originFileObj"] : null
		dispatch(createOffer(value, coverImage, event_Id, status))
		setAddUserOpen(false)
	}

	const editAnOffer = (value) => {
		const coverImage = cover["0"] ? cover["0"]["originFileObj"] : null
		dispatch(updateOffer(value, coverImage, id, status))
		setEditOffer(false)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Offers</h2>
							<p> List Of All Offers</p>
						</div>
						<div
							onClick={() => {
								setAddUserOpen(true)
							}}
							className="right-in"
						>
							<ActionBtn title="Add New Offer" />
						</div>
					</div>
					<Card>
						<div className="card-header">
							<h3>Offer Management</h3>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={offersData ? offersData : []}
						/>
					</Card>
				</div>

				<AddOffer
					open={addUserOpen}
					handleCancel={() => {
						setAddUserOpen(false)
					}}
				/>
				<Modal
					onCancel={() => {
						setViewOffer(false)
					}}
					footer={null}
					title="View Offer"
					width={900}
					open={viewOffer}
				>
					{/* <div className="event-upload">
						<img
							className="coverimage1"
							width="100%"
							height={250}
							src={singleOffer.image ? singleOffer.image : badurl}
							alt="cover-imag"
							onError={onImageError}
						/>
					</div> */}
					<Form form={form} autoComplete="off">
						<div className="container createevent add-ticket">
							<div className="addevent">
								<div className="eventNameDiv">
									<FieldName className="fieldName">Event Name</FieldName>
									<Form.Item
										name="eventName"
										rules={[
											{
												required: true,
												message: "Please enter Offer Discount",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Offer Code</FieldName>
									<Form.Item
										name="event_offer_coupon_code"
										rules={[
											{
												required: true,
												message: "Please enter Event Coupon code",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Quantity</FieldName>
									<Form.Item
										name="event_offer_max_quantity"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">Offer %</FieldName>
									<Form.Item
										name="offer_discount"
										rules={[
											{
												required: true,
												message: "Please enter Offer Discount",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
								<div className="expiryDate">
									<FieldName className="fieldName">Expiry Date</FieldName>
									<Form.Item
										name="event_offer_validity"
										rules={[
											{
												required: true,
												message: "Please enter Expiry Date",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Select status</FieldName>
									<Form.Item
										name="status"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
								<div></div>
							</div>
						</div>
					</Form>
				</Modal>
				<Modal
					onCancel={() => {
						setEditOffer(false)
					}}
					title="Edit an Offer"
					footer={null}
					width={900}
					open={editOffer}
				>
					<div>
						<div className="event-upload">
							<Upload
								action={process.env.Action}
								listType="picture-card"
								fileList={cover}
								style={{ height: 100 }}
								beforeUpload={beforeUpload}
								onChange={({ fileList: newFileList }) => {
									setCover(newFileList)
								}}
								onRemove={(value) => {
									setCover([])
								}}
							>
								{cover?.length < 1 && <span className="pls-icon">+</span>}
							</Upload>
						</div>

						<Form onFinish={editAnOffer} form={form}>
							<div className="container createevent add-ticket">
								<div className="addevent">
									<div className="eventNameDiv">
										<FieldName className="fieldName">Event Name</FieldName>
										<Select
											value={event_Id}
											onChange={(value) => {
												setEventId(value)
											}}
											style={{ width: 380 }}
										>
											{event.map((item) => (
												<Option value={item._id}>{item.name}</Option>
											))}
										</Select>
									</div>
									<div>
										<FieldName className="fieldName">Offer Code</FieldName>
										<Form.Item
											name="event_offer_coupon_code"
											rules={[
												{
													required: true,
													message: "Please enter Event Coupon code",
												},
											]}
										>
											<Input type="text" />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">Quantity</FieldName>
										<Form.Item
											name="event_offer_max_quantity"
											rules={[
												{
													required: true,
													message: "Please enter Qunatity",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input type="text" />
										</Form.Item>
									</div>
								</div>
								<div>
									<div>
										<FieldName className="fieldName">Offer %</FieldName>
										<Form.Item
											name="offer_discount"
											rules={[
												{
													required: true,
													message: "Please enter Offer Discount",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">Expiry Date</FieldName>
										<Form.Item
											name="event_offer_validity"
											rules={[
												{
													required: true,
													message: "Please enter Expiry Date",
												},
											]}
										>
											<Input type="date" />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">Select status</FieldName>
										<Select
											value={status}
											onChange={(value) => {
												setStatus(value)
											}}
											style={{ width: 400 }}
										>
											<Option value={true}>Active</Option>
											<Option value={false}>Inactive</Option>
										</Select>
									</div>
									<div></div>
								</div>
							</div>
							<div className="modal-btns">
								<Button
									className="cancel-btn"
									onClick={() => {
										setEditOffer(false)
									}}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									className="ant-btn ant-btn-button ant-btn photos-btn"
									htmlType="submit"
									onClick={() => {
										setSubmitted(true)
									}}
								>
									Edit Offer
								</Button>
							</div>
						</Form>
					</div>
				</Modal>
			</ContentWrapper>
		</>
	)
}

const ContentWrapper = styled.div`
	width: 100%;
	margin-left: auto;
	.product-image {
	}
	.ant-popover-inner .ant-popover-buttons button:nth-child(2) {
		margin-right: 6px;
	}
	.ant-avatar {
		border-radius: 0px !important;
	}
	.ant-avatar > img {
		border-radius: 100% !important;
	}

	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px;
	}
	.ant-table {
		background: #fff !important;
	}
	button.ant-btn.ant-btn-primary {
		background: #235cea !important;
		padding: 10px 30px !important;
		width: max-content !important;
		font-size: 16px;
		height: auto !important;
	}

	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px;
	}
`

export default OfferManagement
