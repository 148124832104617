import { useEffect, useState } from "react"
import { Form, Input, Button, Space, Spin } from "antd"
import { Card, Wrapper, Heading, FormWrapper, FlexCenter } from "./styles"
import { CheckEmail, ForgetPassword } from "redux/app/actions/auth"
import { useSelector, useDispatch } from "react-redux"
import { loading, data, hasError, message } from "redux/app"
import { useNavigate } from "react-router-dom"

const ForgotPassword = () => {
	const [email, setEmail] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const emailMessage = useSelector(message)
	const [form] = Form.useForm()
	const search = window.location.search
	const params = new URLSearchParams(search)
	const payload = {
		user_id: params.get("user_id"),
		code: params.get("code"),
		intent: params.get("intent"),
	}

	const onFinish = (values) => {
		dispatch(ForgetPassword({email}, navigate))
	}

	const checkEmail = (email) => {
		setEmail(email)
		if (
			/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/.test(
				email
			)
		) {
			setTimeout(() => {
				dispatch(CheckEmail({ email }))
			}, 2000)
		}
	}
	useEffect(() => {
		form.setFieldsValue({
			email:""
		})
	}, [form])

	return (
		<>
			<Wrapper>
				<Card>
					<Heading>
						<h2
							className="pass-icon"
							style={{ color: "#fff", fontSize: "30px", textAlign: "center" }}
						>
							Forgot Password <span style={{ color: "red" }}>*</span>
						</h2>
					</Heading>
					<FormWrapper>
						<Form
							form={form}
							layout="vertical"
							className="FormWrapper"
							onFinish={onFinish}
						>
							<Form.Item
								label="Email"
								style={{ color: "#fff" }}
								name="email"
								rules={[
									// {
									// 	required: true,
									// 	message: "Please enter your email.",
									// },
									{
										pattern:
											/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/,
										message: "Invalid email",
									},
								]}
								hasFeedback
								// help={emailMessage}
							>
								<Input
									placeholder="Enter Email"
									// onChange={(e) => checkEmail(e.target.value)}
									// style={{ color: "black" }}
								/>
								<span style={{ color: "red", padding: 5 }}>{emailMessage}</span>
							</Form.Item>

							<Form.Item wrapperCol={{ span: 24 }}>
								<Button type="primary" htmlType="submit">
									Send Email
								</Button>
							</Form.Item>
						</Form>
					</FormWrapper>
					{dataLoading && (
						<FlexCenter>
							<Space size="medium">
								<Spin size="medium" />
							</Space>
						</FlexCenter>
					)}
				</Card>
			</Wrapper>
		</>
	)
}

export default ForgotPassword
