import styled from "styled-components"
import theme from "styles/Theme"

export const ContentWrapper = styled.div`   
	ul.main-menu {
		padding: 10px !important;
	}
	ul.main-menu li {
		background: #002140;
		margin: 2px 0;
		border-radius: 4px;
		color: #fff !important;
	}

	ul.main-menu:hover li {
		background: #101628 !important;
	}
`