import React, { useEffect } from "react"
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom"
import "scss/main.scss"
import { ThemeProvider } from "styled-components"
import Interests from "pages/Interest"
import Dashboard from "pages/Dashboard"
import Theme from "styles/Theme"
import Global from "styles/Global"
import Layout from "components/layout"
import Login from "pages/Login"
import Register from "pages/Register"
import PageNotFound from "components/pages/NotFound/PageNotFound"
import ProductAction from "pages/AddInterest"
import UserAction from "pages/AddUser"
import ResetPassword from "pages/ResetPassword"
import EmailVerification from "pages/EmailVerification"
import Profile from "pages/Profile"
import UserManagement from "pages/UserManagement"
import EditInterest from "pages/EditInterest"
import ForgotPassword from "pages/ForgotPassword"
import { useSelector } from "react-redux"
import { isAuthenticated, userProfile } from "redux/app"
import ContentPage from "pages/contentPage"
import AddContent from "pages/AddContent"
import FAQPage from "pages/faq"
import AddFAQ from "pages/AddFAQ"
import { getUserProfile, LoginUser } from "redux/app/actions/auth"
import { useDispatch } from "react-redux"
import EditUser from "pages/EditUser"
import EventManagement from "pages/EventManagement"
import ViewEvent from "pages/EventManagement/ViewEvent"
import EditEvent from "pages/EventManagement/EditEvent"
import OfferManagement from "pages/Offer Management"
import TicketsPage from "pages/Tickets/index"
import { Notification } from "pages/Notification/index"
import SendNotification from "pages/Notification/SendNotification"
import TicketScannerPage from "pages/TicketScannerUser"
import FeaturedEvents from "pages/FeaturedEvents"
import BookingPage from "pages/Bookings"
import CouponPage from "pages/Coupon"
import Requests from "pages/Requests/Requests"

function App() {
	const dispatch = useDispatch()
	useEffect(() => {	
			dispatch(getUserProfile())
	}, [])

	const layoutWrapper = (component) => (
		<PrivateRoute>
			<Layout>{component}</Layout>
		</PrivateRoute>
	)
	return (
		<ThemeProvider theme={Theme}>
			<Global />
			<BrowserRouter>
				<Routes>
					<Route
						path="/login"
						element={
							<LoginRedirect>
								<Login />
							</LoginRedirect>
						}
					/>
					<Route path="/" element={layoutWrapper(<Dashboard />)}>
						<Route path="active" element={layoutWrapper(<Dashboard />)} />
						<Route path="archived" element={layoutWrapper(<Dashboard />)} />
					</Route>
					<Route path="/reset" element={layoutWrapper(<Register />)}>
						<Route path="active" element={layoutWrapper(<Register />)} />
						<Route path="archived" element={layoutWrapper(<Register />)} />
					</Route>
					<Route path="/dashboard" element={layoutWrapper(<Dashboard />)}>
						<Route path="active" element={layoutWrapper(<Dashboard />)} />
						<Route path="archived" element={layoutWrapper(<Dashboard />)} />
					</Route>
					<Route path="/genres" element={layoutWrapper(<Interests />)}>
						<Route path="active" element={layoutWrapper(<Interests />)} />
						<Route path="archived" element={layoutWrapper(<Interests />)} />
					</Route>
					<Route path="/userManagement" element={layoutWrapper(<UserManagement />)}>
						<Route path="active" element={layoutWrapper(<UserManagement />)} />
						<Route path="archived" element={layoutWrapper(<UserManagement />)} />
					</Route>
					<Route path="/edit/user/:id" element={layoutWrapper(<EditUser />)}>
						<Route path="active" element={layoutWrapper(<EditUser />)} />
						<Route path="archived" element={layoutWrapper(<EditUser />)} />
					</Route>
					<Route path="/content" element={layoutWrapper(<ContentPage />)}>
						<Route path="active" element={layoutWrapper(<ContentPage />)} />
						<Route path="archived" element={layoutWrapper(<ContentPage />)} />
					</Route>
					<Route path="/add-content" element={layoutWrapper(<AddContent />)}>
						<Route path="active" element={layoutWrapper(<AddContent />)} />
						<Route path="archived" element={layoutWrapper(<AddContent />)} />
					</Route>
					<Route path="/faq" element={layoutWrapper(<FAQPage />)}>
						<Route path="active" element={layoutWrapper(<FAQPage />)} />
						<Route path="archived" element={layoutWrapper(<FAQPage />)} />
					</Route>
					<Route path="/add-faq" element={layoutWrapper(<AddFAQ />)}>
						<Route path="active" element={layoutWrapper(<AddFAQ />)} />
						<Route path="archived" element={layoutWrapper(<AddFAQ />)} />
					</Route>
					<Route path="/faq" element={layoutWrapper(<UserManagement />)}>
						<Route path="active" element={layoutWrapper(<UserManagement />)} />
						<Route path="archived" element={layoutWrapper(<UserManagement />)} />
					</Route>

					<Route path="/profile" element={layoutWrapper(<Profile />)}>
						<Route path="active" element={layoutWrapper(<Profile />)} />
						<Route path="archived" element={layoutWrapper(<Profile />)} />
					</Route>
					<Route path="/add-product" element={layoutWrapper(<ProductAction />)}>
						<Route path="active" element={layoutWrapper(<ProductAction />)} />
						<Route path="archived" element={layoutWrapper(<ProductAction />)} />
					</Route>
					<Route path="/add-user" element={layoutWrapper(<UserAction />)}>
						<Route path="active" element={layoutWrapper(<UserAction />)} />
						<Route path="archived" element={layoutWrapper(<UserAction />)} />
					</Route>
					<Route path="/edit-product/:id" element={layoutWrapper(<EditInterest />)}>
						<Route path="active" element={layoutWrapper(<EditInterest />)} />
						<Route path="archived" element={layoutWrapper(<EditInterest />)} />
					</Route>
					<Route path="/events" element={layoutWrapper(<EventManagement />)}>
						<Route path="active" element={layoutWrapper(<EventManagement />)} />
						<Route path="archived" element={layoutWrapper(<EventManagement />)} />
					</Route>
					<Route path="/featured-events" element={layoutWrapper(<FeaturedEvents />)}>
						<Route path="active" element={layoutWrapper(<FeaturedEvents />)} />
						<Route path="archived" element={layoutWrapper(<FeaturedEvents />)} />
					</Route>
					<Route path="/edit/event/:id" element={layoutWrapper(<EditEvent />)}>
						<Route path="active" element={layoutWrapper(<EditEvent />)} />
						<Route path="archived" element={layoutWrapper(<EditEvent />)} />
					</Route>
					<Route path="/event/:id" element={layoutWrapper(<ViewEvent />)}>
						<Route path="active" element={layoutWrapper(<ViewEvent />)} />
						<Route path="archived" element={layoutWrapper(<ViewEvent />)} />
					</Route>
					<Route path="/offers" element={layoutWrapper(<OfferManagement />)}>
						<Route path="active" element={layoutWrapper(<OfferManagement />)} />
						<Route path="archived" element={layoutWrapper(<OfferManagement />)} />
					</Route>
					<Route path="/offers" element={layoutWrapper(<OfferManagement />)}>
						<Route path="active" element={layoutWrapper(<OfferManagement />)} />
						<Route path="archived" element={layoutWrapper(<OfferManagement />)} />
					</Route>
					<Route path="/tickets" element={layoutWrapper(<TicketsPage />)}>
						<Route path="active" element={layoutWrapper(<TicketsPage />)} />
						<Route path="archived" element={layoutWrapper(<TicketsPage />)} />
					</Route>
					<Route path="/ticket-scanner" element={layoutWrapper(<TicketScannerPage />)}>
						<Route path="active" element={layoutWrapper(<TicketScannerPage />)} />
						<Route path="archived" element={layoutWrapper(<TicketScannerPage />)} />
					</Route>
					<Route path="/coupon" element={layoutWrapper(<CouponPage />)}>
						<Route path="active" element={layoutWrapper(<CouponPage />)} />
						<Route path="archived" element={layoutWrapper(<CouponPage />)} />
					</Route>
					<Route path="/booking" element={layoutWrapper(<BookingPage />)}>
						<Route path="active" element={layoutWrapper(<BookingPage />)} />
						<Route path="archived" element={layoutWrapper(<BookingPage />)} />
					</Route>
					<Route path="/notification" element={layoutWrapper(<Notification />)}>
						<Route path="active" element={layoutWrapper(<Notification />)} />
						<Route path="archived" element={layoutWrapper(<Notification />)} />
					</Route>
					<Route path="/requests" element={layoutWrapper(<Requests />)}>
						<Route path="active" element={layoutWrapper(<Requests />)} />
						<Route path="archived" element={layoutWrapper(<Requests />)} />
					</Route>
					<Route path="/send-notification" element={layoutWrapper(<SendNotification />)}>
						<Route path="active" element={layoutWrapper(<SendNotification />)} />
						<Route path="archived" element={layoutWrapper(<SendNotification />)} />
					</Route>

					<Route path="/password" element={<ResetPassword />} />
					<Route path="/forgotpassword" element={<ForgotPassword />} />
					<Route path="/emailverification" element={<EmailVerification />} />
					<Route path="*" element={layoutWrapper(<PageNotFound />)} />
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	)
}
const PrivateRoute = ({ children }) => {
	const isLoggenIn = useSelector(isAuthenticated)
	const isToken = localStorage.getItem("token")
	return isLoggenIn || isToken ? children : <Navigate to="/login" />
}

const LoginRedirect = ({ children }) => {
	const isLoggenIn = useSelector(isAuthenticated)
	const isToken = localStorage.getItem("token")
	return isLoggenIn || isToken ? <Navigate to="/" /> : children
}
export default App
