import React, { useState } from "react"
import { Button, Input, Form, Upload, Row, Col, Modal, Select } from "antd"
import { events, loading, organizer, singleEvent } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getEvent, getOrganizerEvents, getOrganizers } from "redux/app/actions/event"
import { addTicket } from "redux/app/actions/ticket"
import "./style.css"
import { Option } from "antd/lib/mentions"
import AddOffer from "pages/Offer Management/AddOffer"
import moment from "moment"

const AddTicket = ({ open, handleCancel, event_id, eventCreated }) => {
	const organizerData = useSelector(organizer)
	const dispatch = useDispatch()
	const Organizer_Events = useSelector(events)
	const [organizerId, setOrganizerId] = useState("")
	const [form] = Form.useForm()
	const eventData = useSelector(singleEvent)
	const [Event_id, setEventId] = useState("")
	const [ticketCreated, setTicketCreated] = useState(false)
	const [ticketModal, setTicketModal] = useState(false)

	useEffect(() => {
		if (event_id) {
			dispatch(getEvent(event_id))
		}
	}, [event_id])

	useEffect(() => {
		form.setFieldsValue({
			eventName: eventData?.name,
			organizer: eventData?.userId?.name,
		})
	}, [eventData])

	useEffect(() => {
		dispatch(getOrganizers())
	}, [])
	useEffect(() => {
		if (organizerId) {
			dispatch(getOrganizerEvents(organizerId))
		}
	}, [organizerId])

	const addNewTicket = (value) => {
		if (eventCreated) {
			dispatch(addTicket(value, eventData?.userId, eventData?._id))
			if (!value.addMore) {
				setTicketModal(true)
			}
		} else {
			dispatch(addTicket(value, organizerId, Event_id))
		}
		if (value.addMore) {
			form.resetFields([
				"ticket_batch_name",
				"ticket_quantity",
				"ticket_sale_start_date",
				"min_tickets",
				"price",
				"ticket_sale_start_time",
				"max_tickets",
			])
		} else {
			setTicketCreated(true)
			handleCancel()
		}
	}

	const addMore = async () => {
		try {
			await form.validateFields()
			const values = form.getFieldValue()
			values.addMore = true
			addNewTicket(values)
		} catch (e) {
			console.log("Not a valid form")
		}
	}

	const handleChange = (value) => {
		setOrganizerId(value)
	}
	const handleEvent = (value) => {
		setEventId(value)
	}

	return (
		<>
			<div>
				<Modal
					open={open}
					onCancel={handleCancel}
					title="New Ticket Batch - Ticket"
					width={900}
					height={500}
					footer={null}
				>
					<div>
						<Form onFinish={addNewTicket} form={form} autoComplete="off">
							<div className="container createevent new-ticket">
								<div className="addevent">
									<div>
										<FieldName className="fieldName">
											Batch Name <span>*</span>
										</FieldName>
										<Form.Item
											name="ticket_batch_name"
											rules={[
												{
													required: true,
													message: "Please enter Batch Name",
												},
												{ whitespace: true },
												{ min: 3 },
											]}
										>
											<Input />
										</Form.Item>
									</div>
									<div className="organizer-div">
										<FieldName className="fieldName">
											Event Name <span>*</span>
										</FieldName>
										{eventCreated ? (
											<Form.Item name="eventName">
												<Input disabled />
											</Form.Item>
										) : (
											<Select onChange={handleEvent} style={{ width: 380 }}>
												{Organizer_Events?.map((item) => (
													<Option value={item._id}>{item.name}</Option>
												))}
											</Select>
										)}
									</div>
									<div>
										<FieldName className="fieldName">
											Quantity <span>*</span>
										</FieldName>
										<Form.Item
											name="ticket_quantity"
											rules={[
												{
													required: true,
													message: "Please enter Qunatity",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">
											Start Sale Date <span>*</span>
										</FieldName>
										<Form.Item
											name="ticket_sale_start_date"
											rules={[
												{
													required: true,
													message: "Please enter Start Date",
												},
											]}
										>
											<Input type="date" />
										</Form.Item>
									</div>
									{/* <div>
										<FieldName className="fieldName">End Sale Date</FieldName>
										<Form.Item
											name="ticket_sale_end_date"
											rules={[
												{
													required: true,
													message: "Please enter End Date",
												},
											]}
										>
											<Input type="date" />
										</Form.Item>
									</div> */}
									<div>
										<FieldName className="fieldName">
											Minimum Ticket By Order <span>*</span>
										</FieldName>
										<Form.Item
											name="min_tickets"
											rules={[
												{
													required: true,
													message: "Please enter Minimum Tickets",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input />
										</Form.Item>
									</div>
								</div>
								<div>
									<div className="event-div">
										<FieldName className="fieldName">
											Organizer <span>*</span>
										</FieldName>
										{eventCreated ? (
											<Form.Item name="organizer">
												<Input disabled />
											</Form.Item>
										) : (
											<Select onChange={handleChange} style={{ width: 380 }}>
												{organizerData.map((item) => (
													<Option value={item._id}>{item.name}</Option>
												))}
											</Select>
										)}
									</div>
									<div>
										<FieldName className="fieldName">
											Price <span>*</span>
										</FieldName>
										<Form.Item
											name="price"
											rules={[
												{
													required: true,
													message: "Please enter Price",
												},
												{
													pattern: /^\d+(\.\d{1,2})?$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input />
										</Form.Item>
									</div>
									<div>
										<FieldName className="fieldName">
											Start Sale Time <span>*</span>
										</FieldName>
										<Form.Item
											name="ticket_sale_start_time"
											rules={[
												{
													required: true,
													message: "Please enter Start Time",
												},
											]}
										>
											<Input type="time" />
										</Form.Item>
									</div>
									{/* <div>
										<FieldName className="fieldName">End Sale Time</FieldName>
										<Form.Item
											name="ticket_sale_end_time"
											rules={[
												{
													required: true,
													message: "Please enter End Time",
												},
											]}
										>
											<Input type="time" />
										</Form.Item>
									</div> */}
									<div>
										<FieldName className="fieldName">
											Maximum Tickets By Order <span>*</span>
										</FieldName>
										<Form.Item
											name="max_tickets"
											rules={[
												{
													required: true,
													message: "Please enter Maximum Tickets",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Please Enter Number",
												},
											]}
										>
											<Input />
										</Form.Item>
									</div>
								</div>
							</div>
							<div className="modal-btns">
								<Button className="cancel-btn" onClick={handleCancel}>
									Cancel
								</Button>
								{eventCreated && (
									<Button
										onClick={addMore}
										type="primary"
										className="ant-btn ant-btn-button ant-btn photos-btn"
										style={{ marginRight: "15px" }}
										htmlType="button"
									>
										Submit & Add More
									</Button>
								)}
								<Button
									type="primary"
									className="ant-btn ant-btn-button ant-btn photos-btn"
									htmlType="submit"
									onClick={() => {
										setTicketCreated(true)
									}}
								>
									{eventCreated ? "Next" : "Add Ticket"}
								</Button>
							</div>
						</Form>
					</div>
				</Modal>
			</div>
			<AddOffer
				open={ticketModal}
				handleCancel={() => {
					setTicketModal(false)
				}}
				ticketCreated={ticketCreated}
			/>
		</>
	)
}

export default AddTicket
