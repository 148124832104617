import moment from "moment"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import { returningCustomer, revenue } from "redux/app"
import { getReturningCustomers } from "redux/app/actions/booking"

function ApexChart() {
	const dispatch = useDispatch()
	const returningCustomerData = useSelector(returningCustomer)
	const [loading, setLoading] = useState(true)
	const [options, setOptions] = useState({
		series: [
			{
				name: "Revenue",
				data: [
					returningCustomerData?.ticketSold?.oldPercentage,
					returningCustomerData?.ticketSold?.newPercentage,
				],
			},
		],
		options: {
			chart: {
				type: "bar",
				height: 150,
				width: "100%",
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "25%",
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: false,
			},

			stroke: {
				show: true,
				width: 3,
				colors: ["transparent"],
			},
			xaxis: {
				categories: ["Old Users", "New Users "],
			},
			yaxis: {
				title: {
					text: "% (Percentage)",
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "% " + val + " Percentage"
					},
				},
			},
		},
	})

	useEffect(() => {
		if (returningCustomerData) {
			setLoading(true)

			const oldPercentage = returningCustomerData?.ticketSold?.oldPercentage ?? {}
			const newPercentage = returningCustomerData?.ticketSold?.newPercentage ?? {}

			const copyOptions = { ...options }
			options.series[0].data = [oldPercentage,newPercentage]
			setOptions(copyOptions)

			setTimeout(() => {
				setLoading(false)
			}, 500)
		}
	}, [returningCustomerData])

	useEffect(() => {
		dispatch(getReturningCustomers())
	}, [])
	return (
		<div id="chart">
			{!loading && (
				<ReactApexChart
					options={options.options}
					series={options.series}
					type="bar"
					width="470"
					height={260}
				/>
			)}
		</div>
	)
}
export default ApexChart
