import { Col, Layout, Row, Image, Button, Input, Card, Checkbox } from "antd"
import { AudioOutlined } from "@ant-design/icons"
import { useState } from "react"
import { LayoutWrapper } from "./styles"
import { useEffect } from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { createFeaturedEvents, featuredEvents, getAllEvents } from "redux/app/actions/event"
import { events, featured_events, tickets, userProfile } from "redux/app"
import Search from "antd/lib/transfer/search"

const { Content } = Layout

const FeaturedEvents = ({ children }) => {
	const [query, setQuery] = useState("")
	const dispatch = useDispatch()
	const [data, setData] = useState([])
	const [filtererdData, setFiltererdData] = useState([])
	const event = useSelector(events)
	const featured_event = useSelector(featured_events)
	const onChange = (e) => {
		if (e.target.checked) {
			setData([...data, e.target.value])
		}
		if (!e.target.checked) {
			let filteredArray = data.filter((item) => item !== e.target.value)
			setData(filteredArray)
		}
	}
	useEffect(() => {
		const array = []
		featured_event.map((item) => {
			array.push(item._id.toString())
		})
		setData(array)
	}, [featured_event])
	useEffect(() => {
		dispatch(featuredEvents())
	}, [])
	useEffect(() => {
		setFiltererdData(event)
	}, [event])

	const featuredData = () => {
		console.log(data)
		dispatch(createFeaturedEvents(data))
	}

	useEffect(() => {
		if (query === "") {
			setFiltererdData(event)
		} else {
			const tempData = event?.filter((item) => {
				return item.name.toLowerCase().includes(query)
			})
			setFiltererdData(tempData)
		}
	}, [query])
	const suffix = (
		<AudioOutlined
			style={{
				fontSize: 16,
				color: "#1890ff",
			}}
		/>
	)

	useEffect(() => {
		dispatch(getAllEvents())
	}, [])

	return (
		<LayoutWrapper>
			<Layout className="layout-container">
				<Layout
					className="site-layout"
					style={{
						transition: "0.15s ease all",
						minHeight: "100vh",
						background: "#fafafa",
						padding: 0,
					}}
				>
					<Content>
						{children}
						<div className="main-contnt">
							<div className="top-header">
								<div className="left-in">
									<h2 className="mb-0">Featured Events</h2>
								</div>
							</div>
							<section className="add-featured pt-5 pb-5">
								<Row>
									<Col span={18}>
										<div className="search featured relative">
											<Search
												placeholder="Search Events"
												value={query}
												onChange={(e) => setQuery(e.target.value)}
												style={{ width: "100%" }}
											/>
										</div>
									</Col>
									<Col span={6} style={{ textAlign: "right" }}>
										<Button type="primary" onClick={featuredData}>
											Add Featured Event
										</Button>
									</Col>
								</Row>
								<Row className="add-event-rw">
									{filtererdData.map((value) => (
										<Col span={6}>
											<div role="button" className="actives" tabIndex={-1}>
												<Card
													variant="secondary"
													style={{ marginBottom: "10px" }}
												>
													<div className="add-event-col text-center relative">
														<img
															className="featured-img"
															src={value.image[0]}
															alt=""
														/>
														<p
															className="f-18 bold"
															style={{ marginBottom: "0" }}
														>
															{value?.name}
														</p>
														<p
															className="mb-0 f-16 bold"
															style={{ marginBottom: "0" }}
														>
															{value?.userId?.name}
														</p>
														<div className="event-check">
															<Checkbox
																checked={data.includes(value._id)}
																onChange={onChange}
																value={value._id}
																// onClick={createFeaturedEvents(value._id)}
															></Checkbox>
														</div>
													</div>
												</Card>
											</div>
										</Col>
									))}
								</Row>
							</section>
						</div>
					</Content>
				</Layout>
			</Layout>
		</LayoutWrapper>
	)
}

export default FeaturedEvents
