import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper } from "./style"
import { Card, Popconfirm, Table, Button } from "antd"
import { StatusBtn } from "./style"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { faqs } from "redux/app"
import { deleteFAQ, getAllFAQs, updateFAQ } from "redux/app/actions/faq"
import { Link } from "react-router-dom"
import EditFAQ from "pages/AddFAQ/EditFaq"
import AddFAQ from "pages/AddFAQ"
import ViewFaq from "pages/AddFAQ/ViewFaq"
import '../EventManagement/styles.css'

const FAQPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const dispatch = useDispatch()
	const faqsList = useSelector(faqs)
	const [viewEditModal, setEditModal] = useState(false)
	const [addModal, setAddModal] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [viewModal, setViewModal] = useState(false)
	const [faqId, setFaqId] = useState("")
	useEffect(() => {
		dispatch(getAllFAQs())
	}, [])
	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Question",
			dataIndex: "question",
			key: "question",
		},
		{
			title: "Answer",
			dataIndex: "answer",
			key: "answer",
		},
		{
			title: "Status",
			dataIndex: "status",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(updateFAQ(row?._id, !row?.status))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.status ? (
							<StatusBtn style={{ cursor: "pointer",backgroundColor:'green' }}>Active</StatusBtn>
						) : (
							<StatusBtn style={{ cursor: "pointer" }}>Inactive</StatusBtn>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					{/* <img
						src={Eye}
						alt=""
						className="eye"
						onClick={() => {
							setFaqId(row._id)
							setViewModal(true)
						}}
					/> */}
					<Link
						onClick={() => {
							setFaqId(row._id)
							setEditModal(true)
						}}
					>
						<i
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
							}}
						></i>
					</Link>

					<Popconfirm
						title="Are you sure , you want to delete the record?"
						onConfirm={() => {
							dispatch(deleteFAQ(row?._id))
						}}
						onCancel={() => {}}
						okText="Yes"
						cancelText="No"
					>
						{" "}
						<span style={{ cursor: "pointer" }}>
							<i
								class="fa-regular fa-trash-can"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</span>{" "}
					</Popconfirm>
				</>
			),
		},
	]
	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">FAQs</h2>
							<p>List of All FAQ</p>
						</div>
						<div className="right-in">
							{/* <ActionBtn title="Add FAQ" link="/add-faq" /> */}
							<Button type="primary" onClick={showModal}>
								Add FAQ
							</Button>
						</div>
					</div>
					<Card>
						<div className="card-header">
							<h3>FAQ Management</h3>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={faqsList}
						/>
					</Card>
				</div>
				<AddFAQ open={isModalOpen} handleCancel={hideModal} />
				<EditFAQ
					open={viewEditModal}
					onCancel={() => {
						setEditModal(false)
					}}
					faqId={faqId}
				/>
				<ViewFaq
					open={viewModal}
					onCancle={() => {
						setViewModal(false)
					}}
				/>
			</ContentWrapper>
		</>
	)
}

export default FAQPage
