import React, { useState } from "react"
import { Input, Form, Modal, Select, Button, Upload } from "antd"
import { coupon, events, organizer, ticket } from "redux/app"
import { useSelector } from "react-redux"
import { ContentWrapper, FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { editTicket, singleTicket } from "redux/app/actions/ticket"
import moment from "moment"
import { getOrganizerEvents, getOrganizers } from "redux/app/actions/event"
import "./style.css"
import { Option } from "antd/lib/mentions"
import { editCoupon, singleCoupon } from "redux/app/actions/coupon"
import Placeholder from "assets/icons/placeholder.png"

const EditCoupon = ({ open, handleOk, ticketId }) => {
	const organizerData = useSelector(organizer)
	const Organizer_Events = useSelector(events)
	const [data, setData] = useState({})
	const dispatch = useDispatch()
	const [organizerId, setOrganizerId] = useState("")
	const [submitted, setSubmitted] = useState(false)
	const [Event_id, setEventId] = useState("")
	const [form] = Form.useForm()
	const couponData = useSelector(coupon)
	const [imgFile, setImgFile] = useState([])
	const [status, setStatus] = useState(false)
	const [selectValue, setSelectValue] = useState("")

	useEffect(() => {
		dispatch(singleCoupon(ticketId))
	}, [ticketId])

	useEffect(() => {
		setData(couponData)

		setImgFile([
			{
				uid: "-1",
				name: "image.png",
				status: "done",
				url: couponData?.image,
			},
		])
		if (couponData?.status) {
			setStatus(true)
		} else if (couponData.status === false) {
			setSelectValue("Inactive")
		}
	}, [couponData])

	const beforeUpload = () => {
		return false
	}
	
	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			description: data?.description,
			coupon_code: data?.coupon_code,
			coupon_discount: couponData?.coupon_discount,
			expiry_date: moment(data?.expiry_date).format("MM/DD/YYYY"),
			max_users: data?.max_users,
			status: data?.status === true ? "Active" : "Inactive",
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])

	const editACoupon = (value) => {
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"]: null
		console.log(imgFile["0"]["originFileObj"],"image")
		dispatch(editCoupon(value, image, ticketId, status))
		handleOk()
	}
	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="Edit Coupon"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					
					<Form onFinish={editACoupon} form={form} autoComplete="off">
					<div className="event-upload">
						<Upload
							beforeUpload={beforeUpload}
							action={process.env.Action}
							listType="picture-card"
							fileList={imgFile}
							onChange={({ fileList: newFileList }) => {
								setImgFile(newFileList)
							}}
							onRemove={() => {
								setImgFile([])
							}}
						>
							{imgFile?.length < 1 && <span className="pls-icon">+</span>}
						</Upload>
					</div>
						<div className="container createevent new-ticket">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">Title</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</div>

								<div>
									<FieldName className="fieldName">Coupon code</FieldName>
									<Form.Item
										name="coupon_code"
										rules={[
											{ required: true, message: "Please enter coupon code" },
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Max Users</FieldName>
									<Form.Item
										name="max_users"
										rules={[
											{
												required: true,
												message: "Please enter max_users",
											},
											{
												pattern: /^[0-9]+$/,
												message: "Please Enter Number",
											},
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Coupon Discount</FieldName>
									<Form.Item
										name="coupon_discount"
										rules={[
											{
												required: true,
												message: "Please enter coupon_discount",
											},
										]}
									>
										<Input type="text" />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">Description</FieldName>
									<Form.Item
										name="description"
										rules={[
											{
												required: true,
												message: "Please enter Description",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Expiry Date</FieldName>
									<Form.Item
										name="expiry_date"
										rules={[
											{
												required: true,
												message: "Please enter expiry date",
											},
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Select status</FieldName>
									<Select
										value={status}
										onChange={(value) => {
											setStatus(value)
										}}
										style={{ width: 400 }}
									>
										<Option value={true}>Active</Option>
										<Option value={false}>Inactive</Option>
									</Select>
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button className="cancel-btn" onClick={handleOk}>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn save-btn"
								htmlType="submit"
								onClick={() => {
									setSubmitted(true)
								}}
							>
								Edit Coupon
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default EditCoupon
