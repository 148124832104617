import { notification } from "antd"
import {
	requestCompleted,
	requestStart,
	setEvents,
	setSingleEvent,
	updateActiveEvent,
	setOrganizers,
	removeEvent,
	setFeaturedEvents,
	setUpcomingEvents,
} from "redux/app"
import { axios } from "lib/axios"
import { axiosIntercepter } from "redux/axios"

export function getAllEvents() {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/event/`)
		return dispatch(setEvents(response.data.events))
	}
}
export function getEvent(id) {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/event/${id}`)
		return dispatch(setSingleEvent(response.data))
	}
}
export function createFeaturedEvents(payload) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			events: payload,
		})
		var config = {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/event/featured-events",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllEvents())
					notification["success"]({
						message: "Featured Events added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Featured Events not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function createEvent(payload, coverImage, image_1, image_2, image_3, genre, userId) {
	return async (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		data.append("image", coverImage)
		data.append("image", image_1)
		data.append("image", image_2)
		data.append("image", image_3)
		data.append("name", payload.name)
		data.append("description", payload.description)
		data.append("start_date", payload.start_date)
		data.append("end_date", payload.end_date)
		data.append("userId", userId)
		data.append("interest", genre)
		data.append("link", payload.link ? payload.link : "")
		data.append("ticket_platform", payload.ticket_platform)
		data.append("start_time", payload.start_time)
		data.append("end_time", payload.end_time)
		data.append("location", payload.location)
		data.append("latitude", payload.latitude)
		data.append("longitude", payload.longitude)
		data.append("status", "published")
		data.append("publish_date", Date.now())
		data.append("publish_time", Date.now())

		var config = {
			method: "post",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/event/admin",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setSingleEvent(response.data.data))
					dispatch(requestCompleted())
					dispatch(getAllEvents())
					notification["success"]({
						message: "Event added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Event not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function activeEvent(id) {
	return async (dispatch) => {
		await axiosIntercepter.patch(`event/activeEvent/${id}`)
		return dispatch(updateActiveEvent({ id }))
	}
}
export function getOrganizers() {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/user/organizers`)
		return dispatch(setOrganizers(response.data))
	}
}

export function updateEvent(payload, id) {
	return async (dispatch) => {
		var config = {
			method: "put",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/event/update/${id}`,
			data: payload,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllEvents())
					notification["success"]({
						message: "Event Updated successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Event not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function uploadImage(image) {
	var formData = require("form-data")
	var data = new formData()
	if (image) {
		data.append("image", image)
	}

	var config = {
		method: "post",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("token")}`,
		},
		url: `/utils/upload`,
		data: data,
	}
	return axios(config)
}

export function updateCoverImage(coverImage, id) {
	return async (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (coverImage) {
			data.append("image", coverImage)
		}

		var config = {
			method: "post",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/event/coverImage/${id}`,
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Event not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function deleteEvent(id) {
	return async (dispatch) => {
		await axiosIntercepter.delete(`/event/delete/${id}`)
		dispatch(removeEvent(id))
		return dispatch(getAllEvents())
	}
}
export function getUpcomingEvents() {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/event/upcomingEvents?page=1&limit=20`)
		return dispatch(setUpcomingEvents(response))
	}
}
export function getOrganizerEvents(id) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/event/organizer/${id}`)
		return dispatch(setEvents(response.data))
	}
}
export function getEventsByDate(startDate, endDate) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(
			`/event/date?startDate=${startDate}&endDate=${endDate}`
		)
		return dispatch(setEvents(response.data))
	}
}

export function featuredEvents() {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/event/featured/`)
		return dispatch(setFeaturedEvents(response.data))
	}
}
