import { notification } from "antd"
import { generateId } from "services/CommonMethods"
import {
	requestStart,
	requestSuccess,
	requestFailure,
	requestCompleted,
	setInterstes,
	changeAInterstes,
	removeInterest,
	setSingleInterest,
	updateInterest,
} from "../../"
import { axios } from "lib/axios"
import { axiosIntercepter } from "redux/axios"

// Get Ideas, Criteria and Category Groups
export function GetProjectMeta(id) {
	return (dispatch) => {
		let projects = localStorage.getItem("projects")
		if (projects) {
			projects = JSON.parse(projects)
		} else {
			localStorage.setItem("users", JSON.stringify([]))
			projects = []
		}
		const project = projects.find((i) => i.id === id)

		if (!project) {
			dispatch(requestFailure())
		} else {
			dispatch(requestSuccess(project))
			dispatch(requestCompleted())
		}
	}
}

//Get all projects
export function GetAllInterest() {
	return (dispatch) => {
		dispatch(requestStart())
		console.log("starte")
		var config = {
			method: "get",
			url: "interest",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				dispatch(setInterstes(response.data.data))
			})
			.catch(function (error) {
				console.log("error", error)
			})
		dispatch(requestCompleted())
	}
}

export function EditAProject(data, id, screen) {
	return (dispatch, state) => {
		dispatch(requestStart())
		let projects = localStorage.getItem("projects")
		if (projects) {
			projects = JSON.parse(projects)
		} else {
			localStorage.setItem("users", JSON.stringify([]))
			projects = []
		}
		const project = projects.find((i) => i.id === id)

		if (!project) {
			notification["error"]({
				message: "Project Not Found",
				duration: 2,
			})
			dispatch(requestFailure())
		} else {
			const index = projects.findIndex((i) => i.id === id)
			projects[index] = { ...project, ...data }
			localStorage.setItem("projects", JSON.stringify(projects))

			const { app } = state()
			const projectName = app?.data[0]?.project?.name

			if (projectName !== data?.name) {
				notification["success"]({
					message: "Project edited successfully",
					duration: 2,
				})
			}
			dispatch(requestCompleted())
			if (screen === "Edit") {
				dispatch(GetProjectMeta(id, "projectDetails"))
			}
		}
		dispatch(requestCompleted())
	}
}

//Search projects
export function SearchProject(searchQuery) {
	return (dispatch) => {
		dispatch(requestStart())
		let projects = localStorage.getItem("projects")
		if (projects) {
			projects = JSON.parse(projects)
		} else {
			localStorage.setItem("users", JSON.stringify([]))
			projects = []
		}
		const filteredProject = projects.filter(
			(i) =>
				i.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				i.description.toLowerCase().includes(searchQuery.toLowerCase())
		)
		dispatch(requestSuccess(filteredProject))
	}
}

// Delete a project
export function DeleteAInterest(id) {
	return (dispatch) => {
		var config = {
			method: "delete",
			url: `interest/${id}`,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				dispatch(removeInterest({ id }))
				console.log(response.data)
				notification["success"]({
					message: "Record Deleted Successfully.",
					duration: 2,
				})
			})
			.catch(function (error) {
				console.log(error)
			})
	}
}

export function UpdateAInterest(payload, image, id) {
	return (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (image) {
			data.append("image", image)
		}
		data.append("name", payload.name)
		var config = {
			method: "patch",
			url: `/interest/${id}`,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(GetAllInterest())
					notification["success"]({
						message: "Genre Updated successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Genre not updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

// Create Project
export function CreateInterest(payload, image) {
	return (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (image) {
			data.append("image", image)
		}
		data.append("name", payload.name)
		var config = {
			method: "post",
			url: "/interest",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					notification["success"]({
						message: "Genre added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Genre not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

// Get single project details
export function GetProjectDetails(id) {
	return (dispatch) => {
		dispatch(requestStart())
		let projects = localStorage.getItem("projects")
		if (projects) {
			projects = JSON.parse(projects)
		} else {
			localStorage.setItem("users", JSON.stringify([]))
			projects = []
		}
		const project = projects.find((i) => i.id === id)

		if (!project) {
			dispatch(requestFailure())
		} else {
			dispatch(requestSuccess(project))
			dispatch(requestCompleted())
		}
		dispatch(requestCompleted())
	}
}

// Copy a project
export function CopyProject(projectID, setPagination, setIsCopiedSuccess) {
	return (dispatch) => {
		dispatch(requestStart())
		let projects = localStorage.getItem("projects")
		if (projects) {
			projects = JSON.parse(projects)
		} else {
			localStorage.setItem("users", JSON.stringify([]))
			projects = []
		}
		const project = projects.find((i) => i.id === projectID)
		projects.push({ ...project, id: generateId(projects), name: `${project.name} Copy` })
		localStorage.setItem("projects", JSON.stringify(projects))
		dispatch(requestCompleted())
		// dispatch(GetAllProjects())
		setPagination({ current: 1 })
		setIsCopiedSuccess(true)
		notification["success"]({
			message: "Project copied successfully",
			duration: 2,
		})
		dispatch(requestCompleted())
	}
}
export function getInterestById(id) {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: `interest/${id}`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					console.log("res", response.data.data)
				}
				dispatch(setSingleInterest(response.data.data))
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function activeInterest(id) {
	return async (dispatch) => {
		await axiosIntercepter.patch(`interest/active/${id}`)
		return dispatch(changeAInterstes({ id }))
	}
}
