import styled from "styled-components"

export const LogoWrapper = styled.div`
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	&:hover {
		cursor: pointer;
	}
	
	svg {
		display: block;
		max-width: 100%;
	}
`
export const Profile =
	styled.div
	`
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;

	justify-content: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
	transition: all 0.15s all;
	padding: ${({ collapsed }) => (collapsed ? "30px 0px" : "30px 5px 0px 24px")};

	position: relative;
	margin-bottom: ${({ collapsed }) => (collapsed ? "50px" : "95px")};
`

export const Info = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	display: ${({ collapsed }) => (collapsed ? "none" : "block")};
	h2,
	p {
		margin: 0;
		padding: 0;
	}
	h2 {
		text-transform: capitalize;
		font-size: 15px;
		font-weight: ${(p) => p.theme.font.weight.semiBold};
		color: ${(p) => p.theme.colors.black};
	}
	.ant-layout-sider {
		background: "#fff !important";
	}
	.ant-layout-sider-children {
		background: #fff;
	}
	p {
		color: ${(p) => p.theme.colors.gray8};
		font-size: 13px;
		margin-top: -2px;
	}
	.ant-layout-sider-collapsed .custom-logo img {
		height: 15px;
	}
	.ant-collapse > .ant-collapse-item > .ant-collapse-header {
		padding: 12px 16px 20px 16px !important;
	  }
	.ant-layout-sider-collapsed span.ant-collapse-header-text {
		display: none;
	}
`
