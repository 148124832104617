import styled from "styled-components"
import theme from "styles/Theme"

export const LayoutWrapper = styled.div`
	height: 100%;
	background: #ffffff;

	.ant-menu-inline {
		border-right: none;
	}
	.main-contnt {
		padding: 50px 40px 40px 40px;
	}
	.card-dashboard p {
		font-size: 16px;
		margin: 0;
	}
    .search.featured button.ant-input-search-button {
        padding: 13px !important;
        border: 1px solid #ddd !important;
    }
    .add-event-rw {
        margin-top: 20px;
    }
	.ant-menu-title-content {
		color: #fff;
	}
    .event-check {
        position: absolute;
        top: 13px;
        left: 18px;
    }
    .event-check span.ant-checkbox-inner {
        border: 1px solid #a7a5a5 !important;
    }
    img.featured-img {
        height: 90px;
        width: 90px;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 10px;
        border: 3px solid #235cea42;
    }
	.ant-menu-item > .anticon {
		font-size: 1.25rem !important;
		margin-right: 0.625rem;
	}

	.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
		margin: 0;
		padding: 0.55rem 0 0 1.25rem !important;
		border-radius: 0 !important;
		& > .anticon {
			transform: translateY(0.375rem) translateX(-0.125rem);
		}
	}

	.ant-menu-item {
		width: auto !important;
		height: 2.5rem !important;
		border-radius: 0.25rem !important;
		padding-left: 0.875rem !important;
	}
	

	.ant-menu-item-selected .ant-menu-title-content {
		font-size: 14px;
		color: #ffffff;
		.ant-btn {
			background-color: #235cea !important;
			border-color: #235cea !important;
		}
	}

	.ant-menu-item-selected {
		background: #235cea !important;
		width: auto !important;
		color: white !important;
		height: 3rem !important;
		border-radius: 0.25rem !important;
		transition: none;
		svg {
			path {
				fill: white;
			}
		}
	}

	.ant-menu-item::after {
		border-right: none !important;
	}

	.ant-menu-vertical {
		border-right: none;
	}

	.ant-layout-sider-light .ant-layout-sider-trigger {
		left: 0;
	}
	.ant-layout-sider-trigger {
		width: 219px;
		left: 0;
	}
	
	.ant-layout-sider {
		background: "#fff !important";
	}
	.ant-layout-sider-children {
		background: #fff;
	}
`

export const TopHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 98%;
`

export const BodyWrapper = styled.div`
	align-items: center;
	justify-content: center;
	min-height: 80vh;
	display: flex;

`
