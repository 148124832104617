import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper, ButtonStyle } from "./style"
import { Button, Card, Popconfirm, Table } from "antd"
import { useSelector, useDispatch } from "react-redux"
import Eye from "assets/icons/eye.png"
import { faqs, loading, tickets } from "redux/app"
import moment from "moment"
import { deleteTicket, getAllTickets } from "redux/app/actions/ticket"
import { Link } from "react-router-dom"
import AddTicket from "./AddTicket"
import ViewTicket from "./ViewTicket"
import EditTicket from "./EditTicket"
import "./style.css"

const TicketsPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const ticketData = useSelector(tickets)
	const dispatch = useDispatch()
	const [viewTicket, setViewTicket] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [ticketId, setTicketId] = useState("")
	const [viewEditTicket, setViewEditTicket] = useState(false)

	useEffect(() => {
		dispatch(getAllTickets())
	}, [])

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Ticket Batch",
			dataIndex: "ticket_batch_name",
			key: "ticket_batch_name",
		},
		{
			title: "Event Name",
			dataIndex: "eventId",
			key: "name",
			render: (item) => item?.name,
			sorter: (a, b) => a.eventId?.name.localeCompare(b.eventId?.name)
		},
		{
			title: "Quantity",
			dataIndex: "ticket_quantity",
			key: "ticket_quantity",
			sorter: (a, b) => a.ticket_quantity - b.ticket_quantity
		},
		{
			title: "Price ($)",
			dataIndex: "price",
			key: "price",
			sorter: (a, b) => a.price - b.price
		},
		{
			title: "Start Sale Date",
			dataIndex: "ticket_sale_start_date",
			key: "ticket_sale_start_date",
			sorter: (a, b) => {
				return a.ticket_sale_start_date.localeCompare(b.ticket_sale_start_date)
			},
			render: (value, row, index) => {
				return <span>{moment(value).format("DD MMMM, YYYY h:mm A")}</span>
			},
			
		},
		// {
		// 	title: "End Sale Date",
		// 	dataIndex: "ticket_sale_end_date",
		// 	key: "ticket_sale_end_date",
		// 	sorter: (a, b) => {
		// 		return a.ticket_sale_start_date.localeCompare(b.ticket_sale_end_date)
		// 	},
		// 	render: (value, row, index) => {
		// 		if(value){
		// 			return <span>{moment(value).format("DD MMMM, YYYY h:mm A")}</span>
		// 		}else{
		// 			return <span>No End Date</span>
		// 		}
				
		// 	},
			
		// },

		{
			title: "Start Sale Time",
			dataIndex: "ticket_sale_start_time",
			key: "ticket_sale_start_time",
			render: (value, row, index) => {
				return <span>{moment(value, ["HH:mm"]).format("hh:mm A")}</span>
			},
		},
		// {
		// 	title: "End Sale Time",
		// 	dataIndex: "ticket_sale_end_time",
		// 	key: "ticket_sale_end_time",
		// 	render: (value, row, index) => {
		// 		if(value){
		// 			return <span>{moment(value, ["HH:mm"]).format("hh:mm A")}</span>
		// 		}else{
		// 			return <span>No End Time</span>
		// 		}
				
		// 	},
		// },
		{
			title: "Actions",
			render: (row) => (
				<>
				<div className="table-action">
					<img style={{marginRight: "10px"}}
						src={Eye}
						alt=""
						className="eye"
						onClick={() => {
							setTicketId(row._id)
							setViewTicket(true)
						}}
					/>
					<Link
						onClick={() => {
							setTicketId(row._id)
							setViewEditTicket(true)
						}}
					>
						<i
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
							}}
						></i>
					</Link>
					<Popconfirm
						title="Are you sure , you want to delete the record?"
						onConfirm={() => {
							dispatch(deleteTicket(row._id))
						}}
						onCancel={() => {}}
						okText="Yes"
						cancelText="No"
					>
						{" "}
						<span style={{ cursor: "pointer" }}>
							<i
								class="fa-regular fa-trash-can"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</span>{" "}
					</Popconfirm>
					</div>
				</>
			),
		},
	]

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<p>
							Tickets <span>Welcome to Event</span>
						</p>
						<ButtonStyle>
							<Button type="primary" onClick={showModal}>
								Add New Ticket
							</Button>
						</ButtonStyle>
					</div>
					<Card>
					<div className="card-header">
						<h3>Tickets</h3>
					</div>
					<Table
						pagination={{
							onChange(current, pageSize) {
								setPage(current)
								setPaginationSize(pageSize)
							},
							defaultPageSize: 10,
							hideOnSinglePage: false,
							showSizeChanger: true,
						}}
						columns={columns}
						dataSource={ticketData}
					/>
					</Card>
				</div>
				<AddTicket open={isModalOpen} handleCancel={hideModal} />
				<ViewTicket
					open={viewTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewTicket(false)
					}}
				/>
				<EditTicket
					open={viewEditTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewEditTicket(false)
					}}
				/>
			</ContentWrapper>
		</>
	)
}

export default TicketsPage
