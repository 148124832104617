import React from "react"
import { Input, Form, Modal } from "antd"
import {  ticketScanner } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import "./style.css"
import { singleTicketScanner } from "redux/app/actions/ticketScanner"

const ViewTicketScanner = ({ open, handleOk, ticketId }) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const scannerTicketData = useSelector(ticketScanner)

	useEffect(() => {
		dispatch(singleTicketScanner(ticketId))
	}, [dispatch, ticketId])

	useEffect(() => {
		form.setFieldsValue({
			name: scannerTicketData?.name,
			status: scannerTicketData?.status,
			userId: scannerTicketData?.userId?.name,
			events: scannerTicketData?.events,
			email: scannerTicketData?.email,
			access_code: scannerTicketData?.access_code,
		})
	}, [form, scannerTicketData])

	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="View Ticket Scanner"
				width={900}
				height={500}
				footer={null}
			>
				<div>
					<Form form={form} autoComplete="off">
						<div className="container createevent">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">Name</FieldName>
									<Form.Item
										name="name"
										rules={[{ required: true, message: "Please enter Name" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Status</FieldName>
									<Form.Item
										name="status"
										rules={[{ required: true, message: "Please enter Status" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>

								<div>
									<FieldName className="fieldName">Email</FieldName>
									<Form.Item
										name="email"
										rules={[{ required: true, message: "Please enter Email" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>
							</div>
							<div>
								<div>
									<FieldName className="fieldName">User Id</FieldName>
									<Form.Item
										name="userId"
										rules={[{ required: true, message: "Please enter userId" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Access Code</FieldName>
									<Form.Item
										name="access_code"
										rules={[
											{ required: true, message: "Please enter access_code" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Event</FieldName>
									{scannerTicketData?.events?.length > 0 &&
										scannerTicketData?.events.map((value) => (
											<div>
												<br></br>
												<input
													className="input_field"
													name="events"
													value={value.name}
													disabled={true}
													rules={[
														{
															required: true,
															message: "Please enter Batch Name",
														},
													]}
												/>
											</div>
										))}
								</div>
							</div>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default ViewTicketScanner
