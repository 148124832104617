import { notification } from "antd"
import moment from "moment"
import {
	removeTicket,
	removeTicketScanner,
	requestCompleted,
	requestStart,
	setActiveTicketScanner,
	setCoupons,
	setSingleCoupon,
	setSingleTicketScanner,
	setTicket,
	setTickets,
	setTicketScanner,
	updateActiveCoupon,
	updateActiveEvent,
} from "redux/app"
import { axiosIntercepter } from "redux/axios"
import { axios } from "lib/axios"
import { getAllTickets } from "../ticket"

export function getAllCoupon() {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/coupon`)
		return dispatch(setCoupons(response.data))
	}
}
export function singleCoupon(id) {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/coupon/${id}`)
		return dispatch(setSingleCoupon(response.data))
	}
}
export function addCoupon(payload, coverImage,status) {
	return async (dispatch) => {
		dispatch(requestStart())
        var formData = require("form-data")
        var data = new formData()
        data.append("image", coverImage)
        data.append("status",status)
		data.append("name", payload?.name)
		data.append("coupon_code", payload.coupon_code)
		data.append("max_users", payload.max_users)
		data.append("expiry_date", moment(payload.expiry_date).format("MM/DD/YYYY"))
		data.append("coupon_discount", payload.coupon_discount)
		data.append("description", payload.description)

		var config = {
			method: "post",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/coupon",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					notification["success"]({
						message: "Coupon added successfully",
						duration: 2,
					})
					dispatch(getAllCoupon())
				} else {
					notification["error"]({
						message: "Coupon not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
				dispatch(getAllCoupon)
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function editCoupon(payload, coverImage, id,status) {
	return async (dispatch) => {
		dispatch(requestStart())
		var formData = require("form-data")
		var data = new formData()
		if (coverImage) {
			data.append("image", coverImage)
		}
		data.append("status",status)
		data.append("name", payload?.name)
		data.append("coupon_code", payload.coupon_code)
		data.append("coupon_discount", payload.coupon_discount)
		data.append("max_users", payload.max_users)
		data.append("expiry_date", moment(payload.expiry_date).format("MM/DD/YYYY"))
		data.append("description", payload.description)

		var config = {
			method: "put",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/coupon/${id}`,
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					// dispatch(getAllCoupon())
					notification["success"]({
						message: "Coupon Updated successfully",
						duration: 2,
					})
					dispatch(getAllCoupon())
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Coupon not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function activeCoupon(id) {
	return async (dispatch) => {
		await axiosIntercepter.patch(`coupon/active/${id}`)
		return dispatch(updateActiveCoupon({ id }))
	}
}
export function deleteTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/delete/${id}`)
		return dispatch(removeTicketScanner({ id }))
	}
}
export function activeTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/active/${id}`)
		return dispatch(setActiveTicketScanner({ id }))
	}
}
export function getTickets(id) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/ticket/event/${id}`)
		return dispatch(setTickets(response.data))
	}
}
