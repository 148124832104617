import React, { useEffect } from "react"
import { ContentWrapper } from "../UserManagement/style"
import { Card, Collapse, Form, Popconfirm, Spin, Table } from "antd"
import Eye from "assets/icons/eye.png"
import { StatusBtn, StatusBtn1, StatusBtn2 } from "./style"
import { useDispatch, useSelector } from "react-redux"
import { events, tickets, userProfile } from "redux/app"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import { AudioOutlined } from "@ant-design/icons"
import { Input } from "antd"
import { activeEvent, deleteEvent, getAllEvents, getEventsByDate } from "redux/app/actions/event"
import Plus from "assets/icons/plus.png"
import moment from "moment"
import { getTickets } from "redux/app/actions/ticket"
import { DatePicker, Space } from "antd"
const { RangePicker } = DatePicker
const EventManagement = (props) => {
	const userProfileData = useSelector(userProfile)
	const [data, setData] = useState({})
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const event = useSelector(events)
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const ticketData = useSelector(tickets)
	const { Panel } = Collapse
	const [searchText, setSearchText] = useState("")
	const [date, setDate] = useState([])
	useEffect(() => {
		if (date && date?.length === 0) {
			dispatch(getAllEvents())
		} else {
			if(date?.length > 0){
			const startDate = moment(date[0]?._d).format("DD/MM/YYYY")
			const endDate = moment(date[1]?._d).format("DD/MM/YYYY")
			dispatch(getEventsByDate(startDate, endDate))
			}
		}
	}, [date])

	const [paginationSize, setPaginationSize] = useState(10)
	const column = [
		{
			title: "Batch Name",
			dataIndex: "ticket_batch_name",
			key: "ticket_batch_name",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			render: (text) => "€" + text,
		},
		{
			title: "Quantity",
			dataIndex: "ticket_quantity",
			key: "ticket_quantity",
		},
		
		{
			title: "Sold Tickets",
			dataIndex: ["ticket_quantity", "remaning_quantity"],
			key: "remaining_quantity",
			render: (text, obj) => obj.ticket_quantity - obj.remaining_quantity,
		},
	]
	const columns = [
		{
			dataIndex: "+",
			key: "number",
			render: (_, row) => (
				<Collapse
					style={{ background: "transparent", border: "none" }}
					onChange={() => {
						dispatch(getTickets(row._id))
					}}
				>
					<Panel header="+" key="1">
						<h4>Tickets</h4>
						<Table columns={column} dataSource={ticketData} pagination={false} />
					</Panel>
				</Collapse>
			),
		},
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Event Name",
			dataIndex: "name",
			key: "name",
			filteredValue: [searchText],
			onFilter: function (value, record) {
				return (
					String(record.name).toLowerCase().includes(value.toLowerCase()) ||
					String(record.userId.name).toLowerCase().includes(value.toLowerCase())
				)
			},
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Publish Date",
			dataIndex: "publish_date",
			key: "publish_date",
			render: (value, row, index) => {
				if (value) {
					return <span>{moment(value).format("MMMM D, YYYY h:mm A")}</span>
				} else {
					return <span>Not published yet</span>
				}
			},
			sorter: (a, b) => {
				return a?.publish_date?.localeCompare(b?.publish_date)
			},
		},
		{
			title: "Publish By",
			dataIndex: "userId",
			key: "userId",
			render: (item) => item.name,
			sorter: (a, b) => {
				return a.userId.name.localeCompare(b.userId.name)
			},
		},
		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeEvent(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.active === true ? (
							<StatusBtn1 style={{ cursor: "pointer", backgroundColor: "green" }}>
								Active
							</StatusBtn1>
						) : (
							<StatusBtn2 style={{ cursor: "pointer" }}>Inactive</StatusBtn2>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<Link to={`/edit/event/${row._id}`}>
							<i
								class="fa-solid fa-pencil"
								style={{
									color: "#000",
									fontSize: "15px",
									marginRight: "6px",
								}}
							></i>
						</Link>
						<Popconfirm
							title="Are you sure , you want to delete the user?"
							onConfirm={() => {
								dispatch(deleteEvent(row._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							{" "}
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "15px",
										marginRight: "6px",
									}}
								></i>
							</span>{" "}
						</Popconfirm>
						<img
							src={Eye}
							alt=""
							style={{ height: "20px", cursor: "pointer" }}
							onClick={() => {
								navigate(`/event/${row._id}`)
							}}
						/>
					</div>
				</>
			),
		},
	]

	useEffect(() => {
		setData(userProfileData)
	}, [userProfileData])

	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			phoneNumber: data?.phoneNumber,
			email: data?.email,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Events</h2>
							<p>Welcome to Event</p>
						</div>
					</div>
					<Card className="event-table-manage">
						<div className="card-header user-manage-search event-header-top">
							<h3>Events</h3>
							<Space className="pick-date-bx">
								<RangePicker
									className="pick-any-date"
									onChange={(value) => {
										setDate(value)
									}}
								/>
							</Space>
							<Input.Search
								onSearch={(value) => {
									setSearchText(value)
								}}
								onChange={(e) => {
									setSearchText(e.target.value)
								}}
								placeholder="Search....."
							/>
						</div>
						<Table
							className="event-table-manage"
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={event}
							loading={{
								indicator: (
									<div>
										<Spin size="large" />
									</div>
								),
								spinning: !event.length,
							}}
						/>
					</Card>
				</div>
			</ContentWrapper>
		</>
	)
}

export default EventManagement
