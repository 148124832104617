import styled from "styled-components"

export const Wrapper = styled.div`
	height: 100vh;
	background: #101628;
	display: flex;
	justify-content: center;
	align-items: center;
	.ant-spin-dot-item {
		background-color: #101628 !important;
	}

`
export const Card = styled.div`
	flex-basis: 700px;
	background: #101628;
	border-radius: 8px;
	box-shadow: 0 0 3px 4px #002140;
`

export const Heading = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 36px;
	margin-bottom: 30px;

`
export const FormWrapper = styled.div`
	display: flex;
	justify-content: center;
	.ant-form {
		width: 65%;
	}
	.ant-form-item-feedback-icon-success {
		display: none;
	}
	.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
		background: #002140;
	}
	.ant-input {
		/* Neutral/Astellas Gray */

		border: 0px solid #939597;
		box-sizing: border-box;
		border-radius: 8px;
		height: auto;
		font-size: 16px;
		padding: 10px;
		background: #002140;
	}
	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
		display: inline-block;
		margin-left: 4px;
		color: #ff4d4f;
		font-size: 16px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: "*";
	}
	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
	.ant-form-item-feedback-icon-error {
		display: none;
	}
	.ant-form-item-label > label {
		color: #fff;
		font-size: 16px !important;
	}
	.ant-btn {
		/* Primary/Astellas Red */

		background: #235cea;
		border-color: #235cea;
		padding: 12px 40px;
		font-size: 16px;
		border-radius: 8px;
		margin-top: 15px;
		margin-bottom: 15px;
		width: 100%;
		height: auto;
	}
	 .ant-form-item-control-input .ant-input-password-icon.anticon {
		color: #000 !important; 
	}
	.ant-input-affix-wrapper > input.ant-input {
		height: 33px;
	}
	.ant-input-suffix svg {
		color: #7b7e88;
	}
	.ant-input-affix-wrapper {
		height: 44px;
		border-radius: 4px;
		background: #002140;
		border: none;
	}
`
export const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
`

export const ForgotPassword = styled.div`
	margin-top: 12px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 112.5%;
	/* identical to box height, or 15px */
	/* Gray 4 */
	color: #bdbdbd;
`

export const ActionLinks = styled.div`
	display: flex;
	flex-basis: 438px;
	justify-content: start;
`