import styled from "styled-components"
export const ContentWrapper = styled.div`
	width: 100%;
	padding-top: 0px;
	margin: auto;
	.save-btn {
		background: #235cea !important;
		color: #ffff !important;
	}
	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px;
	}
	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px;
	}
	.action-btn {
		filter: drop-shadow(-15px 42px 30px rgba(0, 0, 0, 0.05));
		width: 145px;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		padding: 16px 40px;
		border: 1px solid #188bce;
		background: #235cea !important;
		padding: 10px 30px !important;
		border-radius: 10px;
	}
	.ant-input {
		max-width: 420px !important;
		height: 56px;

		border: 0.5px solid #d2d2d2 !important;
		box-sizing: border-box !important;
		border-radius: 10px !important;
	}
	.event-upload .ant-upload.ant-upload-select {
		width: 100% !important;
		border-color: #c1c1c1;
		border-radius: 0;
		height: 140px;
	}
	.fileUploadInput {
		display: grid;

		position: relative;
		z-index: 1;
		max-width: 420px;
	}

	.fileUploadInput input {
		position: relative;
		z-index: 1;
		padding: 0 gap(m);
		width: 100%;
		height: 56px;
		padding-right: 125px;
		border: 0.5px solid #d2d2d2;
		box-sizing: border-box;
		border-radius: 10px;
		font-family: arial, sans-serif;
		font-size: 1rem;
		user-select: none;
		cursor: pointer;
		font-weight: regular;
	}
	.fileUploadInput input[type="file"] {
		padding: 0 gap(m);
	}
	.fileUploadInput input[type="file"]::-webkit-file-upload-button {
		visibility: hidden;
		margin-left: 10px;
		padding: 0;
		height: 50px;
		width: 0;
	}

	.fileUploadInput button {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100px;
		border: none;
		height: 56px;
		line-height: 0;
		user-select: none;
		color: white;

		background: rgba(0, 126, 197, 0.3);
		border-radius: 10px;

		font-family: arial, sans-serif;
		font-size: 1rem;
		font-weight: 800;
	}
	.fileUploadInput button svg {
		width: auto;
		height: 50%;
	}
`
export const Card = styled.div`
	flex-basis: 700px;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 3px 4px #eee;
`
export const ButtonStyle = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 42px;
	.ant-btn ant-btn-primary {
		float: right !important;
	}
	.ant-btn > span {
		padding-left: 0px !important;
	}
	.ant-btn {
		display: flex !important;
		flex-direction: row !important;
		justify-content: center !important;
		align-items: center !important;
		background: #235cea !important;
		color: #fff;
		text-align: center;
		border-radius: 10px !important;
		padding: 12px 30px !important;
		width: max-content !important;
		height: auto !important;
	}
	.ant-input-affix-wrapper {
		height: 56px !important;

		border: 0.5px solid #d2d2d2 !important;
		box-sizing: border-box !important;
		border-radius: 10px !important;
	}
`

export const InputWrapper = styled.div`
	padding-bottom: 24px;
`

export const FieldName = styled.p`
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 100%;
	/* identical to box height, or 16px */
	color: #5e5e5e;
`

export const ActionButtons = styled.div`
	display: flex;
	padding-top: 35px;
	
`
export const LoadingSpinner = styled.div`
	max-width: 305px;
	display: flex;
	justify-content: center;
`
export const StatusBtn = styled.button`
	border: none;
	background: black;
	color: white;
	border-radius: 4px;
	width: 70px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
`