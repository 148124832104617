import { notification } from "antd"
import moment from "moment"
import {
	removeTicket,
	removeTicketScanner,
	requestCompleted,
	requestStart,
	setActiveTicketScanner,
	setDeleteNotifications,
	setNotifications,
	setSingleNotification,
	setSingleTicketScanner,
	setTicket,
	setTickets,
	setTicketScanner,
} from "redux/app"
import { axiosIntercepter } from "redux/axios"
import { axios } from "lib/axios"
import { getAllTickets } from "../ticket"
import { getAllTicketScanner } from "../ticketScanner"

export function getAllNotification() {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/notification/all`)
		return dispatch(setNotifications(response.data))
	}
}
export function deleteNotification(id) {
	return async (dispatch) => {
		var data = JSON.stringify({
			ids: id,
		})
		var config = {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/notification",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllNotification())
					notification["success"]({
						message: "Notification deleted successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Please select notification",
						duration: 2,
					})
					dispatch(requestCompleted())
				}
			})

			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function addTicketScanner(payload, organizerId, eventId) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			name: payload.name,
			ticket_quantity: payload.ticket_quantity,
			price: payload.price,
			ticket_sale_start_date: moment(payload.ticket_sale_start_date).format("DD/MM/YYYY"),
			ticket_sale_end_date: moment(payload.ticket_sale_end_date).format("DD/MM/YYYY"),
			ticket_sale_start_time: payload.ticket_sale_start_time,
			ticket_sale_end_time: payload.ticket_sale_end_time,
			min_ticket: payload.min_tickets,
			max_ticket: payload.max_tickets,
			eventId: eventId,
			userId: organizerId,
		})

		var config = {
			method: "post",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/ticket/admin",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTickets())
					notification["success"]({
						message: "Ticket added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Ticket not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function editTicketScanner(payload, userId, eventId, id) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			name: payload.name,
			access_code: payload?.access_code,
			email: payload?.email,
			status: payload?.status,
			eventId: eventId,
			userId: userId,
		})

		var config = {
			method: "put",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/ticket-scanner/${id}`,
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTicketScanner())
					notification["success"]({
						message: "Ticket Scanner Updated successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Ticket Scanner not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/delete/${id}`)
		return dispatch(removeTicketScanner({ id }))
	}
}
export function activeTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/active/${id}`)
		return dispatch(setActiveTicketScanner({ id }))
	}
}
export function getTickets(id) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/ticket/event/${id}`)
		return dispatch(setTickets(response.data))
	}
}
export function getSingleNotification(id) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/notification/${id}`)
		return dispatch(setSingleNotification(response.data))
	}
}
