import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper, ButtonStyle } from "./style"
import { Button, Card, Popconfirm, Spin, Table } from "antd"
import { useSelector, useDispatch } from "react-redux"
import Eye from "assets/icons/eye.png"
import { coupons, faqs, loading, tickets } from "redux/app"
import moment from "moment"
import { deleteTicket, getAllTickets } from "redux/app/actions/ticket"
import { Link } from "react-router-dom"
import AddTicket from "./AddCoupon"
import ViewTicket from "./ViewCoupon"
import EditTicket from "./EditCoupon"
import "./style.css"
import { activeCoupon, getAllCoupon } from "redux/app/actions/coupon"
import ViewCoupon from "./ViewCoupon"
import EditCoupon from "./EditCoupon"
import AddCoupon from "./AddCoupon"
import { StatusBtn1, StatusBtn2 } from "pages/Bookings/style"
import { activeEvent } from "redux/app/actions/event"

const CouponPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const couponData = useSelector(coupons)
	const dispatch = useDispatch()
	const [viewTicket, setViewTicket] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [ticketId, setTicketId] = useState("")
	const [viewEditTicket, setViewEditTicket] = useState(false)

	useEffect(() => {
		dispatch(getAllCoupon())
	}, [])

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Title",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
		},
		{
			title: "Max Users",
			dataIndex: "max_users",
			key: "max_users",
		},
		{
			title: "Coupon Code",
			dataIndex: "coupon_code",
			key: "coupon_code",
		},
		{
			title: "Expiry Date",
			dataIndex: "expiry_date",
			key: "expiry_date",
			sorter: (a, b) => {
				return a.expiry_date.localeCompare(b.expiry_date)
			},
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY")}</span>
			},
			
		},
		{
			title: "Date",
			dataIndex: "createdAt",
			key: "createdAt",
			sorter: (a, b) => {
				return a.createdAt.localeCompare(b.createdAt)
			},
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY h:mm A")}</span>
			},
			
		},
		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeCoupon(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.status === true ? (
							<StatusBtn1 style={{ cursor: "pointer", backgroundColor: "green" }}>
								Active
							</StatusBtn1>
						) : (
							<StatusBtn2 style={{ cursor: "pointer" }}>Inactive</StatusBtn2>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},

		{
			title: "Actions",
			render: (row) => (
				<>
				<div className="table-action">
					<img style={{marginRight: "10px"}}
						src={Eye}
						alt=""
						className="eye"
						onClick={() => {
							setTicketId(row._id)
							setViewTicket(true)
						}}
					/>
					<Link
						onClick={() => {
							setTicketId(row._id)
							setViewEditTicket(true)
						}}
					>
						<i
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
							}}
						></i>
					</Link>
					{/* <Popconfirm
						title="Are you sure , you want to delete the record?"
						onConfirm={() => {
							dispatch(deleteTicket(row._id))
						}}
						onCancel={() => {}}
						okText="Yes"
						cancelText="No"
					>
						{" "}
						<span style={{ cursor: "pointer" }}>
							<i
								class="fa-regular fa-trash-can"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</span>{" "}
					</Popconfirm> */}
					</div>
				</>
			),
		},
	]

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<p>
						Coupons <span>All Coupons</span>
						</p>
						<ButtonStyle>
							<Button type="primary" onClick={showModal}>
								Add Coupon
							</Button>
						</ButtonStyle>
					</div>
					<Card>
					<div className="card-header">
						<h3>Coupons</h3>
					</div>
					<Table
						pagination={{
							onChange(current, pageSize) {
								setPage(current)
								setPaginationSize(pageSize)
							},
							defaultPageSize: 10,
							hideOnSinglePage: false,
							showSizeChanger: true,
						}}
						columns={columns}
						dataSource={couponData}
						// loading={{
						// 	indicator: (
						// 		<div>
						// 			<Spin size="large" />
						// 		</div>
						// 	),
						// 	spinning: !couponData?.length
						// }}
					/>
					</Card>
				</div>
				<AddCoupon open={isModalOpen} handleCancel={hideModal} />
				<ViewCoupon
					open={viewTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewTicket(false)
					}}
				/>
				<EditCoupon
					open={viewEditTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewEditTicket(false)
					}}
				/>
			</ContentWrapper>
		</>
	)
}

export default CouponPage
