import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import ActionBtn from "components/utility/TopBarActionBtn"
import { Link } from "react-router-dom"
import { data, interests } from "redux/app"
import { Avatar, Popconfirm, Table, Button, Spin } from "antd"
import { GetAllInterest, DeleteAInterest, activeInterest } from "redux/app/actions/interests"
import { StatusBtn, Card } from "./styles"
import AddInterest from "../AddInterest"
import "../EventManagement/styles.css"
import EditInterest from "pages/EditInterest"

const Interests = () => {
	const dispatch = useDispatch()
	const tableData = useSelector(data)
	const tempInterests = useSelector(interests)
	const [query, setQuery] = useState("")
	const [filteredProducts, setFilteredProducts] = useState([])
	const [tableInterest, setTableInterest] = useState([])
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [setEditInterest, setViewEditInterest] = useState(false)
	const [interestId, setInterestId] = useState("")
	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Image",
			render: (row) => <Avatar size={55} src={row.image} />,
			key: "image",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Status",
			dataIndex: "isActive",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeInterest(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.isActive ? (
							<StatusBtn style={{ cursor: "pointer", backgroundColor: "green" }}>
								Active
							</StatusBtn>
						) : (
							<StatusBtn style={{ cursor: "pointer" }}>Inactive</StatusBtn>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<Link
						onClick={() => {
							setViewEditInterest(true)
							setInterestId(row._id)
						}}
					>
						<i
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
							}}
						></i>
					</Link>

					<Popconfirm
						title="Are you sure , you want to delete the record?"
						onConfirm={() => {
							dispatch(DeleteAInterest(row?._id))
						}}
						onCancel={() => {}}
						okText="Yes"
						cancelText="No"
					>
						{" "}
						<span style={{ cursor: "pointer" }}>
							<i
								class="fa-regular fa-trash-can"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</span>{" "}
					</Popconfirm>
				</>
			),
		},
	]

	useEffect(() => {
		dispatch(GetAllInterest())
	}, [])

	useEffect(() => {
		setTableInterest(tempInterests)
	}, [tempInterests])

	useEffect(() => {
		if (tableData.length > 0) {
			if (query) {
				let result = tableData.filter((cam) =>
					cam?.name?.toUpperCase().startsWith(query.toUpperCase())
				)
				setFilteredProducts(result)
			}
		}
	}, [query, tableData])

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Genres</h2>
							<p>List of all Genre</p>
						</div>
						<div className="right-in">
							{/* <ActionBtn title="Add New Genre" link="/add-product" /> */}
							<Button type="primary" onClick={showModal}>
								Add New Genre
							</Button>
						</div>
					</div>
					<Card>
						<div className="card-header">
							<h3>Genre Management</h3>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							loading={{
								indicator: (
									<div>
										<Spin size="large" />
									</div>
								),
								spinning: !interests.length,
							}}
							columns={columns}
							dataSource={
								tableInterest.length > 0 && query !== ""
									? filteredProducts
									: tableInterest
							}
						/>
					</Card>
				</div>
				<AddInterest open={isModalOpen} handleCancel={hideModal} />
				<EditInterest
					open={setEditInterest}
					handleCancel={() => setViewEditInterest(false)}
					interestId={interestId}
				/>
			</ContentWrapper>
		</>
	)
}

const ContentWrapper = styled.div`
	width: 100%;
	margin-left: auto;
	.product-image {
	}
	.ant-popover-inner .ant-popover-buttons button:nth-child(2) {
		margin-right: 6px;
	}
	.ant-avatar {
		border-radius: 0px !important;
	}
	.ant-avatar > img {
		border-radius: 100% !important;
	}
	.main-contnt {
		padding: 50px 40px 40px 40px;
	}
	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px;
	}
	.ant-table {
		background: #fff !important;
	}
	button.ant-btn.ant-btn-primary {
		background: #235cea !important;
		padding: 10px 30px !important;
		width: max-content !important;
		font-size: 16px;
		height: auto !important;
	}

	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 0px !important;
		border-bottom-right-radius: 0px;
	}
`

export default Interests
