import React from "react"
import { Button, Input } from "antd"
import { Link } from "react-router-dom"
import { ButtonStyle, SearchContainer } from "./style"

const index= ({ title, link, showSearch, setQuery }) => {
	return (
		<ButtonStyle>
			<SearchContainer>
				{showSearch && (
					<Input
						type="text"
						onChange={e => setQuery(e.target.value)}
						placeholder="Search"
						style={{ fontFamily: "Arial, FontAwesome" }}
					/>
				)}
			</SearchContainer>
			<Link to={link}>
				<Button type="primary" size="small">
					{title}
				</Button>
			</Link>
		</ButtonStyle>
	)
}

export default index
