import { Button, Col, Form, Input, Modal, Row, Upload } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userProfile } from "redux/app"
import { getUserProfileById } from "redux/app/actions/auth"
import { ContentWrapper, FieldName } from "../style"

const ViewProfile = ({ open, onOk, handleCancel, showBusiness, userId }) => {
	const dispatch = useDispatch()
	const userProfileData = useSelector(userProfile)
	const [imgFile, setImgFile] = useState([])
	const [data, setData] = useState({})
	const [form] = Form.useForm()
	const beforeUpload = () => {
		return false
	}
	useEffect(() => {
		if (userId.length > 0) {
			dispatch(getUserProfileById(userId))
		}
	}, [userId])
	useEffect(() => {
		setData(userProfileData)
		if (userProfileData?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: userProfileData?.image,
				},
			])
		}
		form.setFieldsValue({
			name: data?.name,
			phoneNumber: data?.phoneNumber,
			email: data?.email,
		})
	}, [userProfileData])
	return (
		<Modal
			title="Basic Information"
			open={open}
			onOk={onOk}
			onCancel={handleCancel}
			footer={[
				<Button
					className="ant-btn ant-btn-button ant-btn photos-btn"
					onClick={showBusiness}
					key="submit"
					type="primary"
				>
					Account Type
				</Button>,
				<Button className="cancel-btn" onClick={onOk}>
					Save
				</Button>,
			]}
		>
			<ContentWrapper>
				<div className="avatar-wrapper" style={{ justifyContent: "center" }}>
					<Upload
						beforeUpload={beforeUpload}
						action={process.env.Action}
						style={{ height: "300px", borderRadius: "50%" }}
						listType="picture-card"
						fileList={imgFile}
					>
						{imgFile < 1 && "No Image"}
					</Upload>
				</div>
				<div className="mt-5">
					<Form onFinish={""} form={form} autoComplete="off">
						<Row className="profile-form">
							<Col span={12}>
								<FieldName>Name</FieldName>
								<Form.Item
									name="name"
									rules={[{ required: true, message: "Name is required" }]}
								>
									<Input disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<FieldName>Email</FieldName>
								<Form.Item name="email">
									<Input disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<FieldName>Phone Number</FieldName>
								<Form.Item name="phoneNumber">
									<Input disabled />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</ContentWrapper>
		</Modal>
	)
}

export default ViewProfile
