import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"

export const Location = ({ address, setAddress, setCoordinates }) => {
	const handleSelect = async (value) => {
		const details = await geocodeByAddress(value)
		const li = await getLatLng(details[0])
		setAddress(value)
		setCoordinates(li)
	}
	return (
		<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<div className="location-mapp">
					<label className="gray f-16">Location</label>
					<input
						style={{
							border: "1px solid  #d2d2d2",
							height: "43px",
							width: "100%",
							borderRadius: "8px",
							marginTop: "7px",
							marginBottom: "20px",
							paddingLeft: "10px",
							paddingRight: "10px",
						}}
						{...getInputProps({
							placeholder: "location....",
							className: 'className="col-12 col-md-6 col-lg-6 mb-3 relative',
						})}
					/>
					{suggestions.length > 0 && (
						<div className="location-cards">
							<div className="autocomplete-dropdown-container">
								{loading && <div>Loading...</div>}
								{suggestions.map((suggestion) => {
									const className = suggestion.active
										? "suggestion-item--active"
										: "suggestion-item"
									// inline style for demonstration purpose
									const style = suggestion.active
										? {
												backgroundColor: "#fafafa",
												cursor: "pointer",
												borderBottom: "1px solid #eee",
												paddingBottom: "5px",
												paddingTop: "5px",
												// marginTop: '2%',
										  }
										: {
												backgroundColor: "#ffffff",
												cursor: "pointer",
												borderBottom: "1px solid #eee",
												// marginTop: '2%',
												paddingBottom: "5px",
												paddingTop: "5px",
										  }
									return (
										// eslint-disable-next-line react/jsx-key
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
										>
											<span>{suggestion.description}</span>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</div>
			)}
		</PlacesAutocomplete>
	)
}
