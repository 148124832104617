import React, { useEffect, useState } from "react"
import ActionBtn from "components/utility/TopBarActionBtn"
import { ContentWrapper, ButtonStyle, StatusBtn1, StatusBtn2 } from "./style"
import { Button, Card, Popconfirm, Table } from "antd"
import { useSelector, useDispatch } from "react-redux"
import Eye from "assets/icons/eye.png"
import { faqs, loading, scannerTickets, tickets } from "redux/app"
import moment from "moment"
import { deleteTicket, getAllTickets } from "redux/app/actions/ticket"
import { Link } from "react-router-dom"
import AddTicketScanner from "./AddTicketScanner"
// import ViewTicket from "./ViewTicketScanner"
import "./style.css"
import {
	activeTicketScanner,
	deleteTicketScanner,
	getAllTicketScanner,
} from "redux/app/actions/ticketScanner"
import { activeEvent } from "redux/app/actions/event"
import ViewTicketScanner from "./ViewTicketScanner"
import EditTicketScanner from "./EditTicketScanner"
// import EditTicket from "./EditTicketScanner"


const TicketScannerPage = (props) => {
	const [page, setPage] = useState(1)
	const [paginationSize, setPaginationSize] = useState(10)
	const ticketScannerData = useSelector(scannerTickets)
	const dispatch = useDispatch()
	const [viewTicket, setViewTicket] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [ticketId, setTicketId] = useState("")
	const [viewEditTicket, setViewEditTicket] = useState(false)

	useEffect(() => {
		dispatch(getAllTicketScanner())
	}, [])

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Ticket Scanner User",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Assigned By",
			dataIndex: "userId",
			key: "name",
			render: (item) => item?.name,
			sorter: (a, b) => a.userId?.name.localeCompare(b.userId?.name),
		},
		{
			title: "Assigned Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY")}</span>
			},
		},
		{
			title: "Assigned Event",
			dataIndex: "events",
			key: "name",
			render: (item) => item[0]?.name,
			sorter: (a, b) => a.events[0]?.name.localeCompare(b.events[0]?.name),
		},
		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeTicketScanner(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row?.status === true ? (
							<StatusBtn1 style={{ cursor: "pointer" }}>Active</StatusBtn1>
						) : (
							<StatusBtn2 style={{ cursor: "pointer" }}>Inactive</StatusBtn2>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<img
							style={{ marginRight: "10px" }}
							src={Eye}
							alt=""
							className="eye"
							onClick={() => {
								setTicketId(row._id)
								setViewTicket(true)
							}}
						/>
						<Link
							onClick={() => {
								setTicketId(row._id)
								setViewEditTicket(true)
							}}
						>
							<i
								class="fa-solid fa-pencil"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</Link>
						{/* <Popconfirm
							title="Are you sure , you want to delete the record?"
							onConfirm={() => {
								dispatch(deleteTicketScanner(row._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							{" "}
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "20px",
										marginRight: "10px",
									}}
								></i>
							</span>{" "}
						</Popconfirm> */}
					</div>
				</>
			),
		},
	]

	const showModal = () => {
		setIsModalOpen(true)
	}

	const hideModal = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="genre-box">
						<div className="col-md-6">
							<p>
								Ticket Scanner User<span>Welcome to Ticket Scanner User</span>
							</p>
						</div>
						<div className="col-md-6">
							<Button type="primary" onClick={showModal}>
								Add Ticket Scanner
							</Button>
						</div>
					</div>
					<Card>
						<div className="card-header">
							<h3>Ticket Scanner User</h3>
						</div>
						<Table
							pagination={{
								onChange(current, pageSize) {
									setPage(current)
									setPaginationSize(pageSize)
								},
								defaultPageSize: 10,
								hideOnSinglePage: false,
								showSizeChanger: true,
							}}
							columns={columns}
							dataSource={ticketScannerData}
						/>
					</Card>
				</div>
				<AddTicketScanner open={isModalOpen} handleOk={hideModal} ticketId={ticketId} />
				<ViewTicketScanner
					open={viewTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewTicket(false)
					}}
				/>
				<EditTicketScanner
					open={viewEditTicket}
					ticketId={ticketId}
					handleOk={() => {
						setViewEditTicket(false)
					}}
				/>
			</ContentWrapper>
		</>
	)
}

export default TicketScannerPage
