import React, { useState, useEffect } from "react"
import { Button, Input, Form, Upload, Row, Col, Modal } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { UpdateAInterest, getInterestById } from "redux/app/actions/interests"
import { ContentWrapper, FieldName, Card } from "./style"
import { useNavigate, useParams } from "react-router-dom"
import Placeholder from "assets/icons/placeholder.png"
import { singleInterest } from "redux/app"

const EditInterest = ({ open, handleCancel, interestId }) => {
	const [data, setData] = useState({})
	const [submitted, setSubmitted] = useState(false)
	const [imgFile, setImgFile] = useState([])
	const [genreEditModal, setGenreEditModal] = useState(false)
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const interest = useSelector(singleInterest)

	const beforeUpload = () => {
		return false
	}

	useEffect(() => {
		dispatch(getInterestById(interestId))
	}, [interestId])
	
	useEffect(() => {
		setData(interest)
		if (interest?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: interest?.image,
				},
			])
		}
	}, [interest])

	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])

	// useEffect(() => {
	// 	form.setFieldsValue({
	// 		name: data?.name,
	// 	})
	// 	if (data?.image !== "") {
	// 		form.setFieldsValue({
	// 			image: data?.image,
	// 		})
	// 	}
	// }, [data])

	const updateInterest = (values) => {
		const image = imgFile["0"] ? imgFile["0"]["originFileObj"] : null
		dispatch(UpdateAInterest(values, image, interestId))
		handleCancel()
	}
	return (
		<>
			<Modal
				open={open}
				onCancel={handleCancel}
				title="Add New Genre"
				width={900}
				height={500}
				footer={null}
			>
				<div className="main-modal">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0 gener-title">Edit Genre</h2>
						</div>
					</div>
					<div className="avatar-wrapper" style={{ justifyContent: "center" }}>
						<Upload
							beforeUpload={beforeUpload}
							action={process.env.Action}
							listType="picture-card"
							fileList={imgFile}
							onChange={({ fileList: newFileList }) => {
								setImgFile(newFileList)
							}}
							onRemove={() => {
								setImgFile([])
							}}
						>
							{imgFile?.length < 1 && (
								<img
									style={{ width: "100%" }}
									src={Placeholder}
									alt="placeholder"
								/>
							)}
						</Upload>
					</div>
					<div className="mt-30">
						<Form onFinish={updateInterest} form={form} autoComplete="off">
							<Row className="profile-form">
								<Col span={24}>
									<FieldName className="fieldName"
										style={{
											color: "#000",
											fontWeight: "600",
										}}
									>
										Name <span>*</span>
									</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Genre Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<div
								className="footer-btns"
								span={24}
								style={{ display: "flex" , justifyContent: "flex-end" }}
							>
								<Button
									type="primary"
									className="action-btn save-btn"
									style={{marginLeft: "auto"}}
									htmlType="submit"
									onClick={() => {
										setSubmitted(true)
									}}
								>
									Update Genre
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default EditInterest
