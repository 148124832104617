import { notification } from "antd"
import {
	requestStart,
	requestSuccess,
	requestFailure,
} from "../../"
import { axios } from "lib/axios"

// Get single project details
export function VerifyCode(payload) {
	return async (dispatch) => {
		const data = JSON.stringify(payload)

		const config = {
			method: "post",
			url: "/code/verify",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}
		dispatch(requestStart())

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestSuccess(response.data))

				} else {
					notification["error"]({
						message: "Password Does Not Reset Successfully",
						duration: 2,
					})
					dispatch(requestFailure())
				}
			})
			.catch(function (error) {
				notification["error"]({
					message: "Sorry, the link for password reset is expireed, please generate another.",
					duration: 2,
				})
				dispatch(requestFailure())

			})
	}
}
