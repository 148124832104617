import { Button, Image, Modal, Popconfirm, Table } from "antd"
import React, { useState } from "react"
import { ContentWrapper } from "./style"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { deleteEvent, getEvent } from "redux/app/actions/event"
import { useSelector } from "react-redux"
import { offers, singleEvent, tickets } from "redux/app"
import calender from "assets/icons/calender.svg"
import Location from "assets/icons/location.svg"
import grid from "assets/icons/grid.svg"
import videoicon from "assets/icons/videoicon.svg"
import "./styles.css"
import "moment-timezone"
import moment from "moment/moment"
import badurl from "assets/icons/badurl.png"
import { getTickets } from "redux/app/actions/ticket"
import { getOfferByEventId } from "redux/app/actions/offers"
import { saveAs } from "file-saver"

const ViewEvent = () => {
	const [showDownload, setShowDownload] = useState(false)
	const [image, setImage] = useState("")
	const dispatch = useDispatch()
	const event = useSelector(singleEvent)
	const params = useParams()
	const navigate = useNavigate()
	const ticketData = useSelector(tickets)
	const offersData = useSelector(offers)

	useEffect(() => {
		if (params.id) {
			dispatch(getEvent(params.id))
		}
	}, [params.id])
	useEffect(() => {
		dispatch(getTickets(params.id))
	}, [])
	useEffect(() => {
		dispatch(getOfferByEventId(params.id))
	}, [])

	const column = [
		{
			title: "Batch Name",
			dataIndex: "ticket_batch_name",
			key: "ticket_batch_name",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
			render: (text) => "€" + text,
		},
		{
			title: "Quantity",
			dataIndex: "ticket_quantity",
			key: "ticket_quantity",
		},
	]
	const columns = [
		{
			title: "Event Name",
			dataIndex: "eventId",
			key: "name",
			render: (item) => item.name,
		},
		{
			title: "Offer",
			dataIndex: "offer_discount",
			key: "offer_discount",
		},
		{
			title: "Discount Code",
			dataIndex: "event_offer_coupon_code",
			key: "event_offer_coupon_code",
		},
		{
			title: "Expiry Date",
			dataIndex: "event_offer_validity",
			key: "event_offer_validity",
			render: (value, row, index) => {
				return <span>{moment(value).format("MMMM D, YYYY ")}</span>
			},
		},
	]
	const onImageError = (e) => {
		e.target.src = badurl
	}
	const handleDownloadModal = (url) => {
		setImage(url)
		setShowDownload(true)
	}
	const handleDownload = () => {
		saveAs(image, "image.jpg")
	}
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Events</h2>
							<p>Welcome to Event</p>
						</div>
						<div className="right-in">
							<div className="event-left-btn">
								<button
									type="button"
									class="ant-btn ant-btn-button ant-btn photos-btn"
									onClick={() => {}}
								>
									<span>
										<Popconfirm
											title="Are you sure , you want to delete the event?"
											onConfirm={() => {
												dispatch(deleteEvent(params.id))
												navigate("/events")
											}}
											onCancel={() => {}}
											okText="Yes"
											cancelText="No"
										>
											{" "}
											<span style={{ cursor: "pointer" }}>
												<i
													class="fa-regular fa-trash-can"
													style={{
														color: "red",
														fontSize: "18px",
														marginLeft: "10px",
													}}
												></i>
												<span className="deleteIcon">Delete</span>
											</span>{" "}
										</Popconfirm>
									</span>
								</button>
							</div>
							<div className="event-rigt-btn">
								<button
									type="button"
									className="ant-btn ant-btn-button ant-btn photos-btn"
									onClick={() => {
										navigate(`/edit/event/${params.id}`)
									}}
								>
									<span>Edit</span>
								</button>
							</div>
						</div>
					</div>
					<div>
						<img
							className="coverimage1"
							width="100%"
							height={250}
							src={event.image ? event.image[0] : badurl}
							alt="cover-imag"
							onError={onImageError}
						/>
					</div>

					<div className="viewevent-title">
						<h2 className="event-title">{event.name}</h2>
						<p className="f-18">{event.description}</p>
					</div>
					<div className="locationDiv">
						<div className="event-icon-box">
							<img src={Location} alt="" />
							<p>{event.location}</p>
						</div>
						<div className="event-icon-box">
							<img src={calender} alt="" />
							<p>
								{event?.publish_date
									? moment(event.publish_date).format("MMMM D, YYYY h:mm A")
									: "Not Publish"}
							</p>
						</div>
					</div>
					<div className="event-start" style={{}}>
						<div>
							<p className="bold">Start Date</p>
							<p>{moment(event.start_date).format("MMMM D, YYYY ")}</p>
						</div>
						<div>
							<p className="bold">End Date</p>
							<p>{moment(event.end_date).format("MMMM D, YYYY ")}</p>
						</div>
						<div>
							<p className="bold">Start Time</p>
							<p>{moment(event.start_time, ["HH:mm"]).format("hh:mm A")}</p>
						</div>
						<div>
							<p className="bold">End Time</p>
							<p>{moment(event.end_time, ["HH:mm"]).format("hh:mm A")}</p>
						</div>
						<div>
							<p className="bold">Organizer</p>
							<img
								width={50}
								height={50}
								src={event?.userId?.image}
								onError={onImageError}
							/>
						</div>
					</div>
					<div className="viewevent-tables">
						<Table columns={column} dataSource={ticketData} pagination={false} />
						<Table columns={columns} dataSource={offersData} pagination={false} />
					</div>
					<div className="event-media">
						<h2 className="event-title">Event Media</h2>
						<div className="event-media-btn">
							<Button type="button" className="ant-btn photos-btn">
								<img style={{ marginRight: "10px" }} src={grid} alt="" /> Photos
							</Button>
							<Button type="button" className="border-btn">
								<img style={{ marginRight: "10px" }} src={videoicon} alt="" />{" "}
								Videos
							</Button>
						</div>
					</div>
					<div className="photos-grid">
						{event.image?.map((item) => (
							<img
								style={{ cursor: "pointer" }}
								onClick={() => handleDownloadModal(item)}
								className="eventImages"
								width="100%"
								height={180}
								src={item}
								alt=""
							/>
						))}
					</div>
					<Modal
						width={400}
						title={event?.name}
						open={showDownload}
						onCancel={() => setShowDownload(false)}
						footer={null}
					>
						<Image style={{ marginLeft: "7%" }} width={300} src={image} />
						<br />
						<Button
							onClick={handleDownload}
							style={{ marginTop: "5%", marginLeft: "30%" }}
							type="primary"
						>
							Download
						</Button>
					</Modal>
				</div>
			</ContentWrapper>
		</>
	)
}

export default ViewEvent
