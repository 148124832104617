import React from "react"
import { Button, Form, Select, Row, Col , Modal} from "antd"
import { loading } from "redux/app"
import { useSelector } from "react-redux"
import { ContentWrapper, FieldName, ActionButtons, LoadingSpinner, Card } from "./styles"
import { Spin, Space } from "antd"
import { useNavigate } from "react-router-dom"
import TextArea from "antd/lib/input/TextArea"
import { useDispatch } from "react-redux"
import { addFAQ } from "redux/app/actions/faq"
import { Option } from "antd/lib/mentions"
import { useState } from "react"
import "./style.css"

const AddFAQ = ({ open, handleCancel}) => {
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onFinish = (values) => {
		dispatch(addFAQ(values, navigate))
	}

	return (
		<>
		<div>
				<Modal
					open={open}
					onCancel={handleCancel}
					title="Add FAQ"
					width={900}
					height={500}
					footer={null}
				>
				<div className="main-modal faq-add">
						<Form
							className="addfaq-form"
							form={form}
							autoComplete="off"
							onFinish={onFinish}
						>
							<Row>
								<Col span={12}>
									<FieldName className="fieldName">Question</FieldName>
									<Form.Item
										name="question"
										rules={[
											{ required: true, message: "Please enter Question" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<TextArea />
									</Form.Item>
								</Col>
								<Col span={12}>
									<FieldName className="fieldName">Answer</FieldName>
									<Form.Item
										name="answer"
										rules={[{ required: true, message: "Please enter Answer" },
										{ whitespace: true },
										{ min: 3 },
									]}
									>
										<TextArea />
									</Form.Item>
								</Col>

								<Col span={24}>
									<FieldName className="fieldName">Select status</FieldName>
									<Form.Item
										name="status"
										rules={[
											{ required: true, message: "Please select Status" },
										]}
									>
										<Select
											placeholder="Please Select Status"
											options={[
												{
													value: true,
													label: "Active",
												},
												{
													value: false,
													label: "Inactive",
												},
											]}
										/>
									</Form.Item>
								</Col>
							</Row>
							<ActionButtons className="footer-btns">
								<Button
									className="action-btn cancel-btn"
									onClick={() => {
										navigate(-1)
									}}
								>
									Cancel
								</Button>
								<Button type="primary" style={{marginLeft: "10px"}}
									className="ant-btn ant-btn-button ant-btn action-btn save-btn" htmlType="submit">
									Submit
								</Button>
							</ActionButtons>
						</Form>
				</div>
			</Modal>
		</div>
		</>
	)
}

export default AddFAQ
