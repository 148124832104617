import { Button, Col, Dropdown, Form, Menu, Modal, Row, Select, Upload } from "antd"
import { TopHeaderLeftSide } from "./styles"
import Logout from "components/shared/Logout"
import { useSelector } from "react-redux"
import { interests, notifications, organizer, profileData, singleEvent } from "redux/app"
import { useEffect, useState } from "react"
import { AudioOutlined, UserOutlined } from "@ant-design/icons"
import { Input, Radio } from "antd"
import { useNavigate } from "react-router-dom"
import { FieldName } from "pages/AddUser/styles"
import { useDispatch } from "react-redux"
import ProfileNot from "../../../assets/icons/profile.png"
import { createEvent, getOrganizers } from "redux/app/actions/event"
import { Option } from "antd/lib/mentions"
import { GetAllInterest } from "redux/app/actions/interests"
import "./index.css"
import AddTicket from "pages/Tickets/AddTicket"
import { getAllNotification } from "redux/app/actions/notification"
import moment from "moment"
import { Location } from "./Location"
import PublishEvent from "pages/Offer Management/PublishEvent"

const Header = () => {
	const [showAddEvent, setAddEvent] = useState(false)
	const [form] = Form.useForm()

	const [data, setData] = useState([])
	const [cover, setCover] = useState([])
	const [image1, setImage1] = useState([])
	const [image3, setImage3] = useState([])
	const [image2, setImage2] = useState([])
	const [submitted, setSubmitted] = useState(false)
	const organizerData = useSelector(organizer)
	const dispatch = useDispatch()
	const tempInterests = useSelector(interests)
	const [genre, setGenre] = useState("")
	const profile = useSelector(profileData)
	const [orgnzr, setOrganizer] = useState("")
	const [ticketModal, setTicketModal] = useState(false)
	const eventData = useSelector(singleEvent)
	const [eventCreated, setEventCreated] = useState(false)
	const [isError, setIsError] = useState(false)
	const navigate = useNavigate()
	const notification = useSelector(notifications)
	const [eventType, setUserTypes] = useState(1)

	const [coordinatesError, setCoordinatesError] = useState(false)
	const [address, setAddress] = useState("")
	const [coordinate, setCoordinates] = useState({
		lat: null,
		lng: null,
	})

	const { Search } = Input
	const suffix = (
		<AudioOutlined
			style={{
				fontSize: 16,
				color: "#1890ff",
			}}
		/>
	)
	const onSearch = (value) => {}
	useEffect(() => {
		dispatch(GetAllInterest())
	}, [])
	useEffect(() => {
		dispatch(getOrganizers())
	}, [])
	useEffect(() => {
		dispatch(getAllNotification())
	}, [])

	const addUser = () => {
		setAddEvent(true)
	}
	const handleChange = (value) => {
		let temp = ""
		temp = value + ","
		setGenre(temp)
		setIsError(true)
	}
	const addEvent = (values) => {
		if (!coordinate.lat || !coordinate.lng) {
			setCoordinatesError(true)
			return
		}
		values.location = address
		values.latitude = coordinate?.lat ?? ""
		values.longitude = coordinate?.lng ?? ""
		const coverImage = cover["0"] ? cover["0"]["originFileObj"] : null
		const image_1 = image1["0"] ? image1["0"]["originFileObj"] : null
		const image_2 = image2["0"] ? image2["0"]["originFileObj"] : null
		const image_3 = image3["0"] ? image3["0"]["originFileObj"] : null
		const newGenre = genre.slice(0, genre.length - 1)
		let ticket_platform = ""
		if (eventType === 1) ticket_platform = "our_app"
		if (eventType === 2) ticket_platform = "other"
		if (eventType === 3) ticket_platform = "no_ticket"
		values.ticket_platform = ticket_platform

		dispatch(createEvent(values, coverImage, image_1, image_2, image_3, newGenre, orgnzr))
		if (eventType === 1) {
			setTicketModal(true)
		}
		setAddEvent(false)
		setIsError(false)
	}
	const beforeUpload = () => {
		return false
	}
	const handleOrgaizerChange = (value) => {
		setOrganizer(value)
		setIsError(true)
	}
	const readCount = notification.filter((item) => item.read === false)

	useEffect(() => {
		const readCount = notification.filter((item) => item.read === false)
	}, [readCount?.length])

	const setVal = (e) => {
		setUserTypes(e.target.value)
	}

	const handleCoordinates = (data) => {
		setCoordinatesError(false)
		setCoordinates(data)
	}

	const userMenu = (
		<Menu>
			<div className="top-notifcations">
				{notification?.slice(0, 3).map((value) => (
					<>
						<div className="notification-dropdown" onClick={() => value._id}>
							<div className="information-div">
								<div className="information-left-div">
									<img
										src={
											value?.user?.image?.length > 0
												? value?.user?.image
												: ProfileNot
										}
										onClick={() => navigate("/notification")}
										placeholder="Image"
									/>
								</div>
								<div className="info-rigth-div">
									<h6 className="blue m-0">{value?.user?.name}</h6>
									{/* <p className="gray mb-0">{value?.content}</p> */}
									<p className="gray mb-0">
										{value?.content.length > 20 &&
											value?.content.substring(0, 99) + "..."}
									</p>

									<p className="gray mb-0">
										{moment(value?.createdAt).format("MMMM D, YYYY")}
									</p>
								</div>
							</div>
						</div>
					</>
				))}
				<h3 className="blue m-0">
					<button
						class="ant-btn ant-btn-primary ant-btn-sm"
						style={{ marginLeft: "100px" }}
					>
						<Menu.Item
							key="0"
							style={{
								background: "#235cea",
								margin: "2px 0",
								borderRadius: "4px",
								color: "#fff",
							}}
							onClick={() => {
								navigate(`/notification`)
							}}
						>
							View All Notifications
						</Menu.Item>
					</button>
				</h3>
			</div>
		</Menu>
	)

	return (
		<>
			<Row
				className="header-toprow"
				style={{
					justifyContent: "space-between",
					padding: "10px",
				}}
			>
				<Col style={{ marginLeft: 30 }}>
					{/* <Search onSearch={onSearch} placeholder="Search" />  */}
				</Col>
				<Col style={{ marginRight: 30 }}>
					<TopHeaderLeftSide>
						<button
							type="button"
							class="ant-btn ant-btn-primary ant-btn-sm"
							onClick={addUser}
							style={{ marginRight: "15px" }}
						>
							<span>Create New Event</span>
						</button>
						<div className="notification">
							<Dropdown.Button
								style={{ float: "right" }}
								className="dropdown-btn"
								overlay={userMenu}
								trigger={["click"]}
								icon={
									<>
										<i
											class="fa-regular fa-bell"
											onClick={(e) => e.preventDefault()}
										></i>
										<span
											className="notification-icon"
											style={{ marginBottom: "10%" }}
										>
											{readCount?.length}
										</span>
									</>
								}
							></Dropdown.Button>
						</div>

						<div>
							<Logout />
						</div>
						<div className="super-admn">
							<h3>Hello {profile?.name}</h3>
							<p>Super Admin</p>
						</div>
					</TopHeaderLeftSide>
				</Col>
			</Row>
			<Modal
				onCancel={() => {
					setAddEvent(false)
					setSubmitted(false)
				}}
				title="Create New Event"
				width={900}
				height={500}
				open={showAddEvent}
				footer={null}
			>
				<div>
					<div className="event-upload">
						<Upload
							action={process.env.Action}
							listType="picture-card"
							fileList={cover}
							beforeUpload={beforeUpload}
							style={{ width: 900 }}
							onChange={({ fileList: newFileList }) => {
								setCover(newFileList)
							}}
						>
							{cover?.length < 1 && <span className="pls-icon">+</span>}
						</Upload>
					</div>
					<div className="upload-picture">
						<div className="event-upload">
							<Upload
								action={process.env.Action}
								listType="picture-card"
								fileList={image1}
								beforeUpload={beforeUpload}
								onChange={({ fileList: newFileList }) => {
									setImage1(newFileList)
								}}
							>
								{image1?.length < 1 && <span className="pls-icon">+</span>}
							</Upload>
						</div>
						<div className="event-upload">
							<Upload
								action={process.env.Action}
								listType="picture-card"
								fileList={image2}
								beforeUpload={beforeUpload}
								onChange={({ fileList: newFileList }) => {
									setImage2(newFileList)
								}}
							>
								{image2?.length < 1 && <span className="pls-icon">+</span>}
							</Upload>
						</div>
						<div className="event-upload">
							<Upload
								action={process.env.Action}
								listType="picture-card"
								fileList={image3}
								beforeUpload={beforeUpload}
								onChange={({ fileList: newFileList }) => {
									setImage3(newFileList)
								}}
							>
								{image3?.length < 1 && <span className="pls-icon">+</span>}
							</Upload>
						</div>
					</div>
					<Form onFinish={addEvent} autoComplete="off">
						<div style={{ margin: "20px" }}>
							<Radio.Group name="usertypes" onChange={setVal} defaultValue={1}>
								<Radio value={1}>Our Application & Website</Radio>
								<Radio value={2}>Other Ticket Platform</Radio>
								<Radio value={3}>Not Ticketed</Radio>
							</Radio.Group>
						</div>
						<div className="container createevent">
							<div className="addevent">
								{eventType === 2 && (
									<div>
										<FieldName className="fieldName">
											Link <span>*</span>
										</FieldName>
										<Form.Item
											name="link"
											rules={[
												{
													type: "url",
													message: "This field must be a valid url.",
												},
												{ required: true, message: "Please enter url" },
												{ whitespace: true },
												{ min: 3 },
											]}
										>
											<Input />
										</Form.Item>
									</div>
								)}
								<div>
									<FieldName className="fieldName">
										Event Name <span>*</span>
									</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Event Name" },
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Start Date <span>*</span>
									</FieldName>
									<Form.Item
										name="start_date"
										rules={[
											{ required: true, message: "Please enter Start Date" },
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										End Date <span>*</span>
									</FieldName>
									<Form.Item
										name="end_date"
										rules={[
											{ required: true, message: "Please enter End Date" },
										]}
									>
										<Input type="date" />
									</Form.Item>
								</div>
								<div>
									<Location
										setAddress={setAddress}
										setCoordinates={handleCoordinates}
										address={address}
									/>
									{coordinatesError && (
										<div role="alert" className="error coordinate text-red-500">
											Please choose a address from dropdown
										</div>
									)}
								</div>
								<div className="select-genrebox">
									<FieldName className="fieldName">
										Genre <span>*</span>
									</FieldName>
									<Select
										id="select-genre"
										mode="multiple"
										allowClear
										onChange={handleChange}
										style={{ width: "100%" }}
									>
										{tempInterests &&
											Array.isArray(tempInterests) &&
											tempInterests.map((item) => (
												<Option value={item._id}>{item.name}</Option>
											))}
									</Select>
								</div>
								{/* <span>
									{isError && <p className="text-danger">Please select genere</p>}
								</span> */}
							</div>

							<div>
								<div>
									<FieldName className="fieldName">
										Description <span>*</span>
									</FieldName>
									<Form.Item
										name="description"
										rules={[
											{ required: true, message: "Please enter Description" },
										]}
									>
										<Input.TextArea />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Start Time <span>*</span>
									</FieldName>
									<Form.Item
										name="start_time"
										rules={[
											{ required: true, message: "Please enter Start Time" },
										]}
									>
										<Input type="time" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										End Time <span>*</span>
									</FieldName>
									<Form.Item
										name="end_time"
										rules={[
											{ required: true, message: "Please enter End Time" },
										]}
									>
										<Input type="time" />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">
										Organizer <span>*</span>
									</FieldName>

									<Select
										id="select-organizer"
										onChange={handleOrgaizerChange}
										style={{ width: "100%" }}
									>
										{organizerData.map((item) => (
											<Option value={item._id}>{item.name}</Option>
										))}
									</Select>
									{/* <div>
										{isError && (
											<p className="text-danger">
												Please select atleast one organizer
											</p>
										)}
									</div> */}
								</div>
							</div>
						</div>
						<div className="modal-btns">
							<Button
								id="resetbtn"
								className="cancel-btn"
								onClick={() => {
									setAddEvent(false)
								}}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								className="ant-btn ant-btn-button ant-btn photos-btn"
								htmlType="submit"
								onClick={() => {
									setEventCreated(true)
									setSubmitted(true)
								}}
							>
								Next
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
			<AddTicket
				open={ticketModal}
				event_id={eventData?._id}
				handleCancel={() => {
					setTicketModal(false)
				}}
				eventCreated={eventCreated}
			/>
		</>
	)
}
export default Header
