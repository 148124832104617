import { notification } from "antd"
import {
	requestCompleted,
	requestFailure,
	requestStart,
	setAuthenticated,
	requestSuccess,
	setProfile,
	setMessage,
	setUsers,
	removeUser,
	updateContentStatus,
	updateStatus,
	setUserProfile,
	changeUser,
} from "redux/app"
import { axios } from "lib/axios"
import { axiosIntercepter } from "redux/axios"
import { useNavigate } from "react-router-dom"



export function LoginUser(payload) {
	return async (dispatch) => {
		dispatch(requestStart())
		var data = JSON.stringify({
			email: payload.email,
			password: payload.password,
			deviceToken: "test",
			deviceType: "test",
			fcmToken: "test",
		})

		var config = {
			method: "post",
			url: "/user/admin-login",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					localStorage.setItem("token", response.data.data.token)
					notification["success"]({
						message: "Logged in successfully",
						duration: 2,
					})
					dispatch(setAuthenticated(true))
					
				} else {
					console.log("LOgin error", response)
					notification["error"]({
						message: "Invalid Credetials",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				if (error.message) {
					notification["error"]({
						message: error.response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Error occured, Please try again",
						duration: 2,
					})
				}

				dispatch(requestCompleted())
			})
	}
}
export function registerNewUser(payload, image) {
	return async (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		data.append("image", image)
		data.append("name", payload.name)
		data.append("email", payload.email)
		data.append("password", payload.password)
		data.append("DOB", payload.DOB)
		data.append("phoneNumber", payload.phoneNumber)

		var config = {
			method: "post",
			url: "/user/signup",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "User added successfully",
						duration: 2,
					})
					dispatch(getAllUsers())
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "User not added",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function RegisterUser(payload, navigate) {
	return async (dispatch) => {
		var data = JSON.stringify({
			currentPassword: payload.currentPassword,
			newPassword: payload.newPassword,
		})

		var config = {
			method: "post",
			url: "/user/resetpassword",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Password changed successfully",
						duration: 2,
					})
					localStorage.removeItem("token")
					dispatch(setAuthenticated(false))
					navigate("/login")
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Password Does Not Reset Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function getAllUsers() {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: "/user",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setUsers(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function getUserProfile(setFormData) {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: "/user/profile",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					console.log("name", response.data.data.name)
					dispatch(setProfile(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function getUserProfileById(id) {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: `user/admin/user/${id}`,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					console.log("res", response.data.data)
					dispatch(setUserProfile(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function updateProfile(payload, image) {
	return async (dispatch) => {
		var FormData = require("form-data")
		var data = new FormData()
		if (image) {
			data.append("image", image)
		}
		data?.append("name", payload.name)
		data?.append("phoneNumber", payload.phoneNumber)

		var config = {
			method: "post",
			url: "/user/profile",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					console.log("name", response.data.data.name)
					dispatch(setProfile(response.data.data))
					notification["success"]({
						message: "Profile Updated  Successfully",
						duration: 2,
					})
				} else {
					notification["error"]({
						message: " Profile Does Not Update Successfully",
						duration: 2,
					})
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function removeDP(id) {
	return async (dispatch) => {
		var config = {
			method: "patch",
			url: `/user/removeDP/${id}`,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setProfile(response.data.data))
					notification["success"]({
						message: "Profile Picture Deleted Successfully",
						duration: 2,
					})
				} else {
					notification["error"]({
						message: " Profile Picture Does Not Deleted Successfully",
						duration: 2,
					})
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function ResetForgetPassword(payload, navigate) {
	return async (dispatch) => {
		var data = JSON.stringify(payload)

		var config = {
			method: "post",
			url: "/user/forgotPassword/verify",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Password changed successfully",
						duration: 2,
					})
					navigate("/login")
				} else {
					notification["error"]({
						message: "Password Does Not Reset Successfully",
						duration: 2,
					})
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function CheckEmail(payload) {
	return async (dispatch) => {
		var data = JSON.stringify(payload)

		var config = {
			method: "post",
			url: "/user/checkEmail",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setMessage(""))
				} else {
					dispatch(setMessage("Please enter registered email id."))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				dispatch(setMessage("Please enter registered email id."))
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function ForgetPassword(payload, navigate) {
	return async (dispatch) => {
		var data = JSON.stringify(payload)

		var config = {
			method: "post",
			url: "/user/forgotPassword",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message:
							"Email sent successfully on your registered email id, please check your email.",
						onClose: () => {
							navigate("/login")
						},
					})
				} else {
					notification["error"]({
						message: "Email doesn't sent successfully",
						duration: 2,
					})
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function EmailVerify(payload, navigate) {
	return async (dispatch) => {
		const data = JSON.stringify(payload)

		const config = {
			method: "post",
			url: "/user/email/verify",
			headers: {
				"Content-Type": "application/json",
			},
			data,
		}
		dispatch(requestStart())

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: response.data.status_message,
						duration: 2,
					})
					dispatch(requestSuccess(response.data))
					navigate("/login")
				} else {
					notification["error"]({
						message: "Password Does Not Reset Successfully",
						duration: 2,
					})
					dispatch(requestFailure())
				}
			})
			.catch(function (error) {
				notification["error"]({
					message: error.data.status_message,

					duration: 3,
				})
				dispatch(requestFailure())
			})
	}
}

export function getUsers(setFormData) {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: "/user",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					console.log("name", response.data.data.name)
					dispatch(setProfile(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function updateProfileById(payload, image, id) {
	return async (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (image) {
			data.append("image", image)
		}
		payload.artistDescription && data.append("artistDescription", payload.artistDescription)
        payload.organizerDescription && data.append("organizerDescription", payload.organizerDescription)
		data.append("name", payload.name)
		data?.append("email", payload.email)
		data?.append("phoneNumber", payload.phoneNumber)

		data?.append("socialLink1", payload.socialLink1)
		data?.append("socialLink2", payload.socialLink2)
		data?.append("socialLink3", payload.socialLink3)
		var config = {
			method: "patch",
			url: `/user/admin/updateProfile/${id}`,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllUsers())
					notification["success"]({
						message: "Profile Updated  Successfully",
						duration: 2,
					})
				} else {
					notification["error"]({
						message: " Profile Does Not Update Successfully",
						duration: 2,
					})
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteUserById(id) {
	return async (dispatch) => {
		var config = {
			method: "delete",
			url: `user/admin/user/${id}`,
			headers: {
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				dispatch(removeUser({ id }))

				notification["success"]({
					message: "Record Deleted Successfully.",
					duration: 2,
				})
			})
			.catch(function (error) {
				console.log(error)
			})
	}
}
export function activeUser(id) {
	return async (dispatch) => {
		await axiosIntercepter.patch(`user/admin/activeUser/${id}`)
		return dispatch(changeUser({ id }))
	}
}
export function updateRole(id, value) {
	return async (dispatch) => {
		await axiosIntercepter.patch(`user/admin/updateRole/${id}`, { value })
	}
}
