import React, { useState } from "react"
import { Button, Input, Form, Select, Row, Col } from "antd"
import { loading } from "redux/app"
import { useDispatch, useSelector } from "react-redux"
import { ContentWrapper, FieldName, ActionButtons, LoadingSpinner, Card } from "./styles"
import { Spin, Space } from "antd"
import { useNavigate } from "react-router-dom"
import TextArea from "antd/lib/input/TextArea"
import { addContent } from "redux/app/actions/content"
import "./style.css"
import { convertToHTML } from "draft-convert"
import { EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import "draft-js/dist/Draft.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
const stateFromHTML = require("draft-js-import-html").stateFromHTML

const AddContent = (props) => {
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [html, setHtml] = useState(null)
	const [form] = Form.useForm()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const dispatch = useDispatch()

	const onFinish = (values) => {
		dispatch(addContent(values, html, navigate))
	}
	const handleChange = (state) => {
		setEditorState(state)
		convertContentToHTML()
	}
	const convertContentToHTML = () => {
		const currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
		setHtml(currentContentAsHTML)
	}
	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Add Content Page</h2>
						</div>
					</div>
					<Card>
						<Form form={form} autoComplete="off" onFinish={onFinish}>
							<Row>
								<Col span={12}>
									<FieldName className="fieldName">
										Page Type <span>*</span>
									</FieldName>
									<Form.Item
										name="type"
										rules={[
											{ required: true, message: "Please enter page type" },
										]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<FieldName className="fieldName">
										Page Title <span>*</span>
									</FieldName>
									<Form.Item
										name="title"
										rules={[
											{ required: true, message: "Please enter Page Title" },
											{ whitespace: true },
											{ min: 3 },
										]}
										
									>

										<Input />
									</Form.Item>
								</Col>
								<Col span={12}>
									<FieldName className="fieldName">
										Status <span>*</span>
									</FieldName>
									<Form.Item
										name="status"
										rules={[
											{ required: true, message: "Please select Status" },
										]}
									>
										<Select
											placeholder="Please Select Status"
											style={{
												width: 400,
											}}
											options={[
												{
													value: true,
													label: "Active",
												},
												{
													value: false,
													label: "Inactive",
												},
											]}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<FieldName className="fieldName">
										Content <span>*</span>
									</FieldName>
									<Editor
										editorState={editorState}
										onEditorStateChange={handleChange}
									/>
								</Col>
							</Row>
							<ActionButtons className="footer-btns">
								<Button
									className="action-btn"
									onClick={() => {
										navigate(-1)
									}}
								>
									Cancel
								</Button>
								<Button className="action-btn save-btn " htmlType="submit">
									Submit
								</Button>
								<LoadingSpinner>
									{dataLoading && (
										<Space size="middle">
											<Spin size="large" />
										</Space>
									)}
								</LoadingSpinner>
							</ActionButtons>
						</Form>
					</Card>
				</div>
			</ContentWrapper>
		</>
	)
}

export default AddContent
