import { useEffect } from "react"
import { Form, Input, Button, Space, Spin } from "antd"
import { Card, Wrapper, Heading, FormWrapper, FlexCenter } from "./styles"
import { ResetForgetPassword } from "redux/app/actions/auth"
import { VerifyCode } from "redux/app/actions/oneTimeCode"
import { useSelector, useDispatch } from "react-redux"
import { loading, hasError } from "redux/app"
import { useNavigate } from "react-router-dom"

const ResetPassword = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const hasErrors = useSelector(hasError)
	const [form] = Form.useForm()
	const search = window.location.search
	const params = new URLSearchParams(search)
	const payload = {
		user_id: params.get("user_id"),
		code: params.get("code"),
		intent: params.get("intent"),
	}

	const onFinish = (values) => {
		console.log("Success:", values)
		payload.newPassword = values.newPassword
		dispatch(ResetForgetPassword(payload, navigate))
	}
	useEffect(() => {
		dispatch(VerifyCode(payload))
	}, [])
	useEffect(() => {
		form.setFieldsValue({
			newPassword:"",
			confirmPassword: "",
		})
	}, [form])

	return (
		<>
			<Wrapper>
				{!hasErrors && (
					<Card>
						<Heading>
							<h2
								className="pass-icon"
								style={{  fontSize: "30px", textAlign: "center" }}
							>
								Reset Password
							</h2>
						</Heading>
						<FormWrapper>
							<Form
								form={form}
								layout="vertical"
								className="FormWrapper"
								onFinish={onFinish}
							>
								<Form.Item
									label="New Password"
									style={{ color: "#000" }}
									name="newPassword"
									placeholder="New Password"
									rules={[
										{
											required: true,
											message: "Please enter new password.",
										},
										{
											pattern:
												/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
											message: "Incorrect password!",
										},
									]}
									hasFeedback
								>
									<Input.Password placeholder="New Password" />
								</Form.Item>

								<Form.Item
									label="Confirm Password"
									name="confirmPassword"
									style={{ color: "#fff" }}
									dependencies={["newPassword"]}
									hasFeedback
									rules={[
										{
											required: true,
											message: "Please enter password.",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													getFieldValue("newPassword") === value
												) {
													return Promise.resolve()
												}
												return Promise.reject(
													new Error(
														"Confirm password should be same as new password"
													)
												)
											},
										}),
									]}
								>
									<Input.Password placeholder="Confirm Password" />
								</Form.Item>

								<Form.Item wrapperCol={{span: 24 }}>
									<Button type="primary" htmlType="submit">
										Change Password
									</Button>
								</Form.Item>
							</Form>
						</FormWrapper>
						{dataLoading && (
							<FlexCenter>
								<Space size="medium">
									<Spin size="medium" />
								</Space>
							</FlexCenter>
						)}
					</Card>
				)}
			</Wrapper>
		</>
	)
}

export default ResetPassword
