import styled from "styled-components"

export const LoginWrapper = styled.div`
	height: 100vh;
	background: #e9eefd;
	display: flex;
	color: #fff;
	justify-content: center;
	align-items: center;
	.ant-spin-dot-item {
		background-color: #d91e49 !important;
	}
	.loginform-main .ant-input-password.login-pass {
		width: 100%;
		padding-right: 0 !important;
	}
	.login-pass span.ant-input-suffix {
		top: 16px;
	}
	.login-pass {
		border: none !important;
	}
	.login-pass.ant-input-affix-wrapper-status-error input#password {
		border: none !important;
	}
	img.right-logo {
		position: fixed;
		height: 245px;
		right: -48px;
		bottom: -52px;
		overflow: hidden;
	}
	img.left-logo {
		position: fixed;
		left: -34px;
		height: 245px;
		top: -52px;
		overflow: hidden;
	}
`
export const LoginCard = styled.div`
	flex-basis: 500px;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0 0 3px 4px #235cea12;
	padding: 30px 0;
`

export const LoginHeading = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0px;
	margin-bottom: 10px;
`
export const FormWrapper = styled.div`
	display: flex;
	justify-content: center;
	.ant-form {
		width: 80%;
	}
	.ant-input {
		/* Neutral/Astellas Gray */

		border: 1px solid #70707089;
		box-sizing: border-box;
		border-radius: 12px;
		height: auto;
		background: #fff;
		padding: 15px 12px;
		font-family: sans-serif;
		color: #000;
	}
	span.ant-input-suffix {
		position: absolute;
		right: 10px;
		top: 18px;
	}
	span.ant-input-affix-wrapper-status-error {
		border-radius: 12px !important;
	}
	.ant-btn {
		/* Primary/Astellas Red */

		background: #235cea;
		border-radius: 8px;
		font-size: 16px;
		margin-top: 40px;
		width: 100%;
		border: none;
		padding: 12px 40px;
		height: auto;
		font-family: sans-serif;
	}
	.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
	.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
	.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
	.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
	.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
		background: #fff !important;
		border: 1px solid #ff4d4f !important;
	}
	input::placeholder {
		color: #5a5e6b !important;
		font-family: sans-serif;
	}

	.ant-input-affix-wrapper > input.ant-input {
		height: auto;
		padding: 15px 12px;
		background: #fff;
		font-family: sans-serif;
		color: #000;
	}
	.ant-input-password-icon.anticon {
		color: #7b7e88 !important;
		margin-right: 10px;
	}
	.ant-input-affix-wrapper {
		height: auto;
		background: #fff;
		padding: 0;
		border: 1px solid #70707089;
		border-radius: 8px;
		font-family: sans-serif;
	}
`

export const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
`
