import { ContentWrapper } from "components/shared/Logout/styles"
import { Button, DatePicker, Form, Select, Upload } from "antd"
import { useSelector } from "react-redux"
import { interests, singleEvent } from "redux/app"
import { useEffect, useState } from "react"
import { Input } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import { FieldName } from "pages/AddUser/styles"
import { useDispatch } from "react-redux"
import { getEvent, getOrganizers, updateEvent, uploadImage } from "redux/app/actions/event"

import { Option } from "antd/lib/mentions"
import { Card } from "./style"
import React from "react"
import { GetAllInterest } from "redux/app/actions/interests"
import NoImage from "assets/icons/No Image.png"
import "../../components/shared/Header/index.css"
import "./styles.css"
import moment from "moment"
import { Checkbox } from "antd"

const EditEvent = () => {
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const tempInterests = useSelector(interests)
	const [images, setImages] = useState([])

	const [genre, setGenre] = useState([])
	const event = useSelector(singleEvent)
	const params = useParams()
	const [data, setData] = useState({})
	const [defaultValues, setDefaultValues] = useState([])
	const navigate = useNavigate()

	const [isMain, setIsMain] = useState(false)
	const [loading, setLoading] = useState(false)

	const onChange = (e) => {
		setIsMain((main) => !main)
	}

	useEffect(() => {
		setLoading(true)
		if (event && event.image) {
			setData(event)
			const images = event.image.map((i, index) => ({
				index: index,
				preview: {
					uid: ++index,
					name: "image.png",
					status: "done",
					url: i,
				},
			}))
			form.setFieldsValue({
				name: event?.name,
				location: event?.location,
				start_date: moment(event?.start_date).format("YYYY-MM-DD"),
				end_date: moment(event?.end_date).format("YYYY-MM-DD"),
				start_time: moment(event?.start_time, "hh:mm").format("HH:mm"),
				end_time: moment(event?.end_time, "hh:mm").format("HH:mm"),
				userId: event?.userId?.name,
				description: event?.description,
				genre: event?.genre,
				booking_fees: event?.booking_fees,
				interest: event?.interest?.map((value) => value.name),
			})
			setIsMain(event?.isMain)
			setImages(images)
			const defValues = []
			for (let i = 0; i < event?.interest?.length; i++) {
				defValues.push(event?.interest[i]?._id)
			}
			setDefaultValues(defValues)
		}
		setTimeout(() => setLoading(false), 100)
	}, [event])

	useEffect(() => {
		dispatch(GetAllInterest())
	}, [])
	useEffect(() => {
		dispatch(getOrganizers())
	}, [])

	useEffect(() => {
		if (params.id) {
			dispatch(getEvent(params.id))
		}
	}, [])

	const handleChange = (value) => {
		setDefaultValues(value)
	}

	const editEvent = async (values) => {
		const imgArray = []
		for (let img of images) {
			if (img.preview) {
				if (img.preview.status) {
					imgArray.push(img.preview.url)
				} else {
					const { data } = await uploadImage(img.preview.originFileObj)
					imgArray.push(data.image)
				}
			}
		}
		values.image = imgArray
		values.isMain = isMain ? "1" : "0"
		values.interest = defaultValues
		dispatch(updateEvent(values, params.id))
		navigate("/events")
	}
	const beforeUpload = () => {
		return false
	}

	const clearPreview = (index) => () => {
		const imgs = [...images]
		const findImg = imgs.find((i) => i.index === index)
		if (findImg) {
			findImg.preview = null
		}
		setImages(imgs)
	}

	const handleFile =
		(index) =>
		({ fileList: newFileList }) => {
			const imgs = [...images]
			const findImg = imgs.find((i) => i.index === index)
			if (!findImg) {
				imgs.push({
					index,
					preview: newFileList[0],
				})
			} else {
				findImg.preview = newFileList[0]
			}
			setImages(imgs)
		}

	return (
		<>
			<ContentWrapper>
				{!loading && (
					<div className="main-contnt">
						<div className="top-header">
							<div className="left-in">
								<h2 className="mb-0">Edit Event</h2>
							</div>
						</div>
						<Card>
							<div className="event-upload">
								{(() => {
									const isImage = !!(
										images &&
										images.length > 0 &&
										images[0]?.preview
									)
									return (
										<Upload
											action={process.env.Action}
											listType="picture-card"
											fileList={isImage ? [images[0].preview] : []}
											beforeUpload={beforeUpload}
											onChange={handleFile(0)}
											onRemove={clearPreview(0)}
										>
											{!isImage && <img className="imagge" src={NoImage} />}
										</Upload>
									)
								})()}
							</div>
							<div className="upload-picture">
								<div className="event-upload">
									{(() => {
										const isImage = !!(
											images &&
											images.length > 1 &&
											images[1]?.preview
										)
										return (
											<Upload
												action={process.env.Action}
												listType="picture-card"
												fileList={isImage ? [images[1].preview] : []}
												beforeUpload={beforeUpload}
												onChange={handleFile(1)}
												onRemove={clearPreview(1)}
											>
												{!isImage && (
													<img className="imagge" src={NoImage} />
												)}
											</Upload>
										)
									})()}
								</div>
								<div className="event-upload">
									{(() => {
										const isImage = !!(
											images &&
											images.length > 2 &&
											images[2]?.preview
										)
										return (
											<Upload
												action={process.env.Action}
												listType="picture-card"
												fileList={isImage ? [images[2].preview] : []}
												beforeUpload={beforeUpload}
												onChange={handleFile(2)}
												onRemove={clearPreview(2)}
											>
												{!isImage && (
													<img className="imagge" src={NoImage} />
												)}
											</Upload>
										)
									})()}
								</div>
								<div className="event-upload">
									{(() => {
										const isImage = !!(
											images &&
											images.length > 3 &&
											images[3]?.preview
										)
										return (
											<Upload
												action={process.env.Action}
												listType="picture-card"
												fileList={isImage ? [images[3].preview] : []}
												beforeUpload={beforeUpload}
												onChange={handleFile(3)}
												onRemove={clearPreview(3)}
											>
												{!isImage && (
													<img className="imagge" src={NoImage} />
												)}
											</Upload>
										)
									})()}
								</div>
							</div>
							<Form onFinish={editEvent} form={form} autoComplete="off">
								<div className="container createevent">
									<div className="addevent">
										<div>
											<FieldName className="fieldName">Name</FieldName>
											<Form.Item
												name="name"
												rules={[
													{
														required: true,
														message: "Please enter Name",
													},
													{ whitespace: true },
													{ min: 3 },
												]}
											>
												<Input />
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">Start Date</FieldName>
											<Form.Item
												name="start_date"
												rules={[
													{
														required: true,
														message: "Please enter Start Date",
													},
												]}
											>
												<Input
													type="date"
													defaultValue={event.start_date}
												/>
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">End Date</FieldName>
											<Form.Item
												name="end_date"
												rules={[
													{
														required: true,
														message: "Please enter End Date",
													},
												]}
											>
												<Input type="date" />
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">Location</FieldName>
											<Form.Item
												name="location"
												rules={[
													{
														required: true,
														message: "Please enter Location",
													},
												]}
											>
												<Input />
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">
												Booking Fees Percent
											</FieldName>
											<Form.Item
												name="bookingFees"
												rules={[
													{
													required: true,
													message: "Booking fees is required",
													},
													{
													pattern: /^[0-9]+$/,
													message: "Booking fees must be a number",
													},
													{
													validator: (_, value) =>
														value && parseInt(value, 10) < 100
														? Promise.resolve()
														: Promise.reject("Booking fees must be less than 100"),
													},
												]}
												>
												<Input />
												</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">Genre</FieldName>
											<Select
												value={defaultValues}
												mode="multiple"
												onChange={handleChange}
												className="imagge"
											>
												{tempInterests.length > 0 &&
													tempInterests.map((item) => (
														<Option value={item._id}>
															{item.name}
														</Option>
													))}
											</Select>
										</div>
									</div>
									<div className="box">
										<div>
											<FieldName className="fieldName">Description</FieldName>
											<Form.Item
												name="description"
												rules={[
													{
														required: true,
														message: "Please enter Description",
													},
												]}
											>
												<Input />
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">Start Time</FieldName>
											<Form.Item
												name="start_time"
												rules={[
													{
														required: true,
														message: "Please enter Start Time",
													},
												]}
											>
												<Input
													type="time"
													defaultField={event.start_time}
												/>
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">End Time</FieldName>
											<Form.Item
												name="end_time"
												rules={[
													{
														required: true,
														message: "Please enter End Time",
													},
												]}
											>
												<Input
													type="time"
													defaultValue={event.start_time}
												/>
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">Organizer</FieldName>
											<Form.Item name="userId">
												<Input disabled />
											</Form.Item>
										</div>
										<div>
											<FieldName className="fieldName">
												Show on landing page
											</FieldName>
											<Form.Item name="isMain">
												<Checkbox onChange={onChange} checked={isMain}>
													Main Event
												</Checkbox>
											</Form.Item>
										</div>
									</div>
								</div>
								<div className="modal-btns edit-event">
									<Button
										className="cancel-btn"
										onClick={() => {
											navigate(-1)
										}}
									>
										Cancel
									</Button>
									<Button
										type="primary"
										className="ant-btn ant-btn-primary ant-btn ant-btn-button ant-btn photos-btn"
										htmlType="submit"
									>
										Update
									</Button>
								</div>
							</Form>
						</Card>
					</div>
				)}
			</ContentWrapper>
		</>
	)
}

export default EditEvent
