import React from "react"
import { Input, Form, Modal, Select, Upload } from "antd"
import { coupon, ticket } from "redux/app"
import { useSelector } from "react-redux"
import { FieldName } from "./style"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { singleTicket } from "redux/app/actions/ticket"
import moment from "moment"
import "./style.css"
import { singleCoupon } from "redux/app/actions/coupon"
import badurl from "assets/icons/badurl.png"

const ViewCoupon = ({ open, handleOk, ticketId }) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	const couponData = useSelector(coupon)

	useEffect(() => {
		dispatch(singleCoupon(ticketId))
	}, [ticketId])

	useEffect(() => {
		form.setFieldsValue({
			name: couponData?.name,
			description: couponData?.description,
			max_users: couponData?.max_users,
			coupon_code: couponData?.coupon_code,
			coupon_discount: couponData?.coupon_discount,
			status: couponData?.status === true ? "Active" : "Inactive",
			expiry_date: moment(couponData?.expiry_date).format("DD/MM/YYYY"),
			image: couponData?.image
		})
	}, [couponData])

	const onImageError = (e) => {
		e.target.src = badurl
	}

	return (
		<>
			<Modal
				open={open}
				onCancel={handleOk}
				title="View Coupon"
				width={900}
				height={500}
				footer={null}
			>
				<div>
				<div className="event-upload">
						<img
							className="coverimage1"
							width="100%"
							height={250}
							src={couponData.image ? couponData.image : badurl}
							alt="cover-imag"
							onError={onImageError}
						/>
					</div>
					<Form form={form} autoComplete="off">
						<div className="container createevent">
							<div className="addevent">
								<div>
									<FieldName className="fieldName">Name</FieldName>
									<Form.Item
										name="name"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div className="organizer-div">
									<FieldName className="fieldName">Max users</FieldName>
									<Form.Item
										name="max_users"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Expiry date</FieldName>
									<Form.Item
										name="expiry_date"
										rules={[
											{
												required: true,
												message: "Please enter Qunatity",
											},
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Coupon Discount</FieldName>
									<Form.Item
										name="coupon_discount"
										rules={[
											{
												required: true,
												message: "Please enter coupon_discount",
											},
											{ whitespace: true },
											{ min: 3 },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
							</div>
							<div>
								<div className="event-div">
									<FieldName className="fieldName">Description</FieldName>
									<Form.Item
										name="description"
										rules={[
											{ required: true, message: "Please enter Batch Name" },
										]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Coupon code</FieldName>
									<Form.Item
										name="coupon_code"
										rules={[{ required: true, message: "Please enter Price" }]}
									>
										<Input disabled />
									</Form.Item>
								</div>
								<div>
									<FieldName className="fieldName">Status</FieldName>
									<Form.Item
										name="status"
										rules={[
											{
												required: true,
												message: "Please enter status",
											},
										]}
									>
										<Input type="text" disabled />
									</Form.Item>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}

export default ViewCoupon
