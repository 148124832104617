import React, { useEffect } from "react"
import { notification } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { profileData } from "redux/app"
import { Form, Input, Row, Col, Upload } from "antd"
import { ContentWrapper, Card } from "./style"
import { FieldName } from "pages/AddInterest/style"
import { removeDP, updateProfile } from "redux/app/actions/auth"
import { useState } from "react"
import "./styles.css"
import Placeholder from "assets/icons/placeholder.png"
import { axiosIntercepter } from "redux/axios"
const Profile = () => {
	const userData = useSelector(profileData)
	const [data, setData] = useState()
	const [imgFile, setImgFile] = useState([])
	const beforeUpload = () => {
		return false
	}
	const dispatch = useDispatch()
	useEffect(() => {
		setData(userData)
		if (userData?.image) {
			setImgFile([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: userData?.image,
				},
			])
		}
	}, [userData])

	const [form] = Form.useForm()
	const [settingForm] = Form.useForm()
	useEffect(() => {
		form.setFieldsValue({
			name: data?.name,
			phoneNumber: data?.phoneNumber,
			email: data?.email,
		})
		if (data?.image !== "") {
			form.setFieldsValue({
				image: data?.image,
			})
		}
	}, [data])

	useEffect(() => {
		axiosIntercepter.put("/content/setting").then(({ data }) => {
			if (!data.bookingFees) {

				data.bookingFees = 8;  // Or any default value you'd like
			}
			settingForm.setFieldsValue(data);
		})
	}, [])	

	const onFinish = (values) => {
		dispatch(updateProfile(values, imgFile["0"] ? imgFile["0"]["originFileObj"] : null))
	}

	const onFinishSettings = async (values) => {
		await axiosIntercepter.put("/content/setting", values)
		notification["success"]({
			message: "Booking fees updated successfully!",
			duration: 2,
		})
	}

	return (
		<>
			<ContentWrapper>
				<div className="main-contnt">
					<div className="top-header">
						<div className="left-in">
							<h2 className="mb-0">Personal Settings</h2>
							<p>
								Lorem ipsum dolor sit amet iciatis necessitatibus. Commodi, vitae
								debitis?
							</p>
						</div>
					</div>
					<Card className="debities-card">
						<Form onFinish={onFinishSettings} form={settingForm} autoComplete="off">
							<div className="personal-box">
								<div
									className="avatar-wrapper per-settings"
									style={{ justifyContent: "center" }}
								>
									<div className="upload-conetent">
										<h6>
											<strong>Please enter booking fees percentage</strong>
										</h6>
										<div style={{ marginTop: "10px" }}>
										<Form.Item
											name="bookingFees"
											rules={[
												{
												required: true,
												message: "Booking fees is required",
												},
												{
												pattern: /^[0-9]+$/,
												message: "Booking fees must be a number",
												},
												{
												validator: (_, value) =>
													value && parseInt(value, 10) < 100
													? Promise.resolve()
													: Promise.reject("Booking fees must be less than 100"),
												},
											]}
											>
											<Input />
											</Form.Item>
										</div>
									</div>
								</div>
								<div className="personal-btn">
									<button class="ant-btn ant-btn-default border-btn">
										<span>Update</span>
									</button>
								</div>
							</div>
						</Form>
					</Card>
					<Card className="debities-card">
						<div className="personal-box">
							<div
								className="avatar-wrapper per-settings"
								style={{ justifyContent: "center" }}
							>
								<Upload
									beforeUpload={beforeUpload}
									action={process.env.Action}
									listType="picture-card"
									fileList={imgFile}
									onChange={({ fileList: newFileList }) => {
										setImgFile(newFileList)
									}}
									onRemove={() => {
										setImgFile([])
									}}
								>
									{imgFile?.length < 1 && (
										<img
											style={{ width: "100%", borderRadius: "100%" }}
											src={Placeholder}
										/>
									)}
								</Upload>
								<div className="upload-conetent">
									<h6>
										<strong>Upload a New Photo</strong>
									</h6>
									<p className="image-title">Profile-pic.jpg</p>
								</div>
							</div>
							<div className="personal-btn">
								<button type="button" class="ant-btn ant-btn-default border-btn">
									<span>Update</span>
								</button>
							</div>
						</div>
					</Card>
					<Card>
						{/* <div className="avatar-wrapper" style={{ justifyContent: "center" }}>
							<Upload
								beforeUpload={beforeUpload}
								action={process.env.Action}
								listType="picture-card"
								fileList={imgFile}
								onChange={({ fileList: newFileList }) => {
									setImgFile(newFileList)
								}}
								onRemove={() => {
									setImgFile([])
								}}
							>
								{imgFile?.length < 1 && (
									<img style={{ width: "100%" }} src={Placeholder} />
								)}
							</Upload>
						</div> */}
						<div className="mt-30">
							<Form onFinish={onFinish} form={form} autoComplete="off">
								<Row className="profile-form">
									<Col span={12}>
										<FieldName>Name</FieldName>
										<Form.Item
											name="name"
											rules={[
												{ required: true, message: "Name is required" },
											]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<FieldName>Email</FieldName>
										<Form.Item name="email" rules={[{ required: true }]}>
											<Input disabled />
										</Form.Item>
									</Col>
									<Col span={24}>
										<FieldName>Phone Number</FieldName>
										<Form.Item
											name="phoneNumber"
											rules={[
												{
													required: true,
													message: "Phone number is required",
												},
												{
													pattern: /^[0-9]+$/,
													message: "Incorrect Phone Number",
												},
												{
													min: 5,
													message:
														"Phone Number must be minimum 9 characters.",
												},
												{
													max: 15,
													message:
														"Phone Number must be minimum 12 characters.",
												},
											]}
										>
											<Input type="text" />
										</Form.Item>
									</Col>
									<Col
										className="footer-btns"
										span={24}
										style={{ textAlign: "right", paddingTop: "20px" }}
									>
										<button
											type="button"
											class="ant-btn ant-btn-default cancel-btn"
										>
											<span>Cancel</span>
										</button>
										<button
											type="submit"
											className="sbmt-btn ant-btn ant-btn-primary ant-btn ant-btn-button ant-btn photos-btn"
										>
											Update Details
										</button>
									</Col>
								</Row>
							</Form>
						</div>
					</Card>
				</div>
			</ContentWrapper>
		</>
	)
}

export default Profile
