import { useEffect } from "react"
import { Form, Input, Button, Space, Spin } from "antd"
import { LoginCard, LoginWrapper, LoginHeading, FormWrapper, FlexCenter } from "./styles"
import { ForgotPassword, ActionLinks } from "pages/Register/styles"
import { getUserProfile, LoginUser } from "redux/app/actions/auth"
import { useSelector, useDispatch } from "react-redux"
import { loading, userProfile } from "redux/app"
import { useNavigate, Link } from "react-router-dom"
import leftLogo from "assets/icons/loginleft.png"
import rightLogo from "assets/icons/loginright.png"

const Login = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const dataLoading = useSelector(loading)
	const [form] = Form.useForm()

	const onFinish = (values) => {
		dispatch(LoginUser(values))
	}

	useEffect(() => {
		form.setFieldsValue({
			email: "",
			password: "",
		})
	}, [form])

	return (
		<LoginWrapper>
			<LoginCard>
				<img className="left-logo" src={leftLogo} alt="" />
				<img className="right-logo" src={rightLogo} alt="" />
				<LoginHeading
					className="d-flex"
					style={{ flexFlow: "column", textAlign: "center" }}
				>
					<h2 style={{ color: "#000", fontSize: "32px", marginBottom: "0px" }}>
						Welcome Admin!
					</h2>
					<p style={{ color: "#5a5e6b", fontSize: "20px", marginBottom: "10px" }}>
						Please Login to your Account
					</p>
				</LoginHeading>

				<FormWrapper>
					<Form
						form={form}
						layout="vertical"
						className="FormWrapper loginform-main"
						onFinish={onFinish}
					>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please enter Email",
								},
								{
									pattern:
										/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])/,
									message: "Invalid email",
								},
							]}
						>
							<Input placeholder="Email Address" />
						</Form.Item>

						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please enter Password",
								},
							]}
						>
							<Input.Password placeholder="Password" className="login-pass" />
						</Form.Item>

						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								style={{ background: "#235cea" }}
							>
								Login
							</Button>
						</Form.Item>
					</Form>
				</FormWrapper>
				{dataLoading && (
					<FlexCenter>
						<Space size="medium">
							<Spin size="medium" />
						</Space>
					</FlexCenter>
				)}
			</LoginCard>
		</LoginWrapper>
	)
}

export default Login
