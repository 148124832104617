import { Col, Layout, Row, Image, Table, Card, Popconfirm } from "antd"
import { LayoutWrapper } from "./styles"
import theme from "styles/Theme"
import plannerImg from "../../assets/icons/eventicon.svg"
import eventTotal from "../../assets/icons/totalevents.svg"
import usersImg from "../../assets/icons/users.svg"
import user from "../../assets/icons/user1.png"
import location from "../../assets/icons/location.svg"
import organizerImg from "../../assets/icons/organizer.svg"
import Chart from "./chart"
import Chart1 from "./chart1"
import Chart2 from "./chart2"
import Chart3 from "./chart3"
import { useEffect, useState } from "react"
import Eye from "assets/icons/eye.png"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { bookings, events, gender, revenue, tickets, upcomingEvents, users } from "redux/app"
import { getAllTickets, getTickets } from "redux/app/actions/ticket"
import { deleteUserById, getAllUsers } from "redux/app/actions/auth"
import { getAllEvents, getUpcomingEvents, upComingEvents } from "redux/app/actions/event"
import {
	getAllBookings,
	getGenders,
	getReturningCustomers,
	getRevenue,
} from "redux/app/actions/booking"
import { StatusBtn1, StatusBtn2 } from "pages/Bookings/style"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
const { Content } = Layout

const Dashboard = ({ children }) => {
	const eventData = useSelector(events)
	const userData = useSelector(users)
	const bookingData = useSelector(bookings)
	const ticketData = useSelector(tickets)
	const upcomingeventData = useSelector(upcomingEvents)
	const revenueData = useSelector(revenue)
	const genderData = useSelector(gender)

	const [page, setPage] = useState(1)
	const [value, setValue] = useState("days")
	const [paginationSize, setPaginationSize] = useState(5)
	const [showRevenue, setRevenue] = useState([])
	const navigate = useNavigate()
	const [viewTicket, setViewTicket] = useState(false)
	const [ticketId, setTicketId] = useState("")
	const [viewEditTicket, setViewEditTicket] = useState(false)
	const dispatch = useDispatch()
	const fetchData = () => {
		var config = {
			method: "get",
			url: "http://localhost:2022/event?email= tester123@yopmail.com&password= Welcome@123&deviceToken= test&deviceType= test&fcmToken= test",
			headers: {
				Authorization:
					"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MzYyMzk1NmRlZTFlNDI2MzBmZDY0NzAiLCJuYW1lIjoidGVzdCIsImVtYWlsIjoidGVzdHVzZXIxMjNAeW9wbWFpbC5jb20iLCJwaG9uZU51bWJlciI6IjAxMjM0NTY3IiwicGFzc3dvcmQiOiJzaGExJDM1OThjMWM5JDEkZGE0YzNkNGZjOTRkODQwMmE5OWZjODRmNjUwOTdlMjNkZDIyNTVjZCIsImRldmljZVRva2VuIjoidGVzdCIsImRldmljZVR5cGUiOiJ0ZXN0IiwiZmNtVG9rZW4iOiJ0ZXN0IiwiaXNFbWFpbFZlcmlmaWVkIjp0cnVlLCJjb3VudHJ5Q29kZSI6InRlc3QiLCJjYXRlZ29yeUlkIjpbIjYyZmY1ZWEzZjBmN2Y2NGQwY2U0MDk0MiIsIjYyZmY1ZWEzZjBmN2Y2NGQwY2UyNDA0MiJdLCJfX3YiOjAsInJvbGUiOiJ1c2VyIiwidXBkYXRlZF9hdCI6IjIwMjItMTEtMTBUMDQ6Mjg6MjUuMTQwWiIsImlhdCI6MTY2ODA3OTc2M30.smYwXCH7lhvTtd3OvXOpcgNONGXZKNdK2QNmvpAXHEc",
			},
		}
		axios(config)
			.then(function (response) {
				console.log(response.data.data)
			})
			.catch(function (error) {
				console.log(error)
			})
	}
	useEffect(() => {
		const data = revenueData?.ticketSold?.map((value) => value.revenue)
		setRevenue(data)
	}, [])

	useEffect(() => {
		dispatch(getAllTickets())
	}, [])

	useEffect(() => {
		dispatch(getAllUsers())
	}, [])

	useEffect(() => {
		dispatch(getAllEvents())
	}, [])

	useEffect(() => {
		dispatch(getAllBookings())
	}, [])

	const handleChange = (e) => {
		setValue(e.target.value)
	}
	useEffect((value)=>{
		dispatch(getRevenue(value))
	},[value])

	// useEffect(() => {
	// 	if (value === "days") {
	// 		// const data = revenueData?.ticketSold?.map((value) => value.day_name)
	// 		// setValue("days")
	// 		dispatch(getRevenue(null))
	// 	} else if (value === "month"){
	// 		// const data = revenueData?.ticketSold?.map((value) => value.month_name)
	// 		// setValue("month")
	// 		dispatch(getRevenue('data'))
	// 	}
	// }, [value])

	useEffect(() => {
		fetchData()
	}, [])

	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => {
				return a.name.localeCompare(b.name)
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				return a.email.localeCompare(b.email)
			},
		},
		{
			title: "User Type",
			dataIndex: "currentRole",
			key: "currentRole",
			sorter: (a, b) => {
				return a.currentRole.localeCompare(b.currentRole)
			},
		},

		{
			title: "Status",
			dataIndex: "active",
			render: (_, row) => (
				<Popconfirm
					title="Are you sure you want to change the status?"
					onConfirm={() => {
						dispatch(activeUser(row._id))
					}}
					onCancel={() => {}}
					okText="Yes"
					cancelText="No"
				>
					{" "}
					<span style={{ cursor: "pointer" }}>
						{row.active === true ? (
							<StatusBtn1 style={{ cursor: "pointer" }}>Active</StatusBtn1>
						) : (
							<StatusBtn2 style={{ cursor: "pointer" }}>Inactive</StatusBtn2>
						)}
					</span>{" "}
				</Popconfirm>
			),
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<i
							onClick={() => {
								setEditModal(true)
								setUserId(row._id)
							}}
							class="fa-solid fa-pencil"
							style={{
								color: "#000",
								fontSize: "20px",
								marginRight: "10px",
								cursor: "pointer",
							}}
						></i>

						<Popconfirm
							title="Are you sure , you want to delete the user?"
							onConfirm={() => {
								dispatch(deleteUserById(row._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							{" "}
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "20px",
										marginRight: "10px",
									}}
								></i>
							</span>{" "}
						</Popconfirm>
						<img
							src={Eye}
							alt=""
							style={{ height: "24px", cursor: "pointer" }}
							onClick={() => {
								setViewProfileModal(true)
								setUserId(row._id)
							}}
						/>
					</div>
				</>
			),
		},
	]
	const columns2 = [
		{
			title: "Sr. No.",
			dataIndex: "1",
			key: "number",
			render: (text, record, index) => (page - 1) * paginationSize + index + 1,
		},
		{
			title: "Ticket Batch",
			dataIndex: "ticket_batch_name",
			key: "ticket_batch_name",
		},
		{
			title: "Event Name",
			dataIndex: "eventId",
			key: "name",
			render: (item) => item?.name,
			sorter: (a, b) => a.eventId?.name.localeCompare(b.eventId?.name),
		},
		{
			title: "Quantity",
			dataIndex: "ticket_quantity",
			key: "ticket_quantity",
			sorter: (a, b) => a.ticket_quantity - b.ticket_quantity,
		},
		{
			title: "Price ($)",
			dataIndex: "price",
			key: "price",
			sorter: (a, b) => a.price - b.price,
		},
		{
			title: "Actions",
			render: (row) => (
				<>
					<div className="table-action">
						<img
							style={{ marginRight: "10px" }}
							src={Eye}
							alt=""
							className="eye"
							onClick={() => {
								setTicketId(row._id)
								setViewTicket(true)
							}}
						/>
						<Link
							onClick={() => {
								setTicketId(row._id)
								setViewEditTicket(true)
							}}
						>
							<i
								class="fa-solid fa-pencil"
								style={{
									color: "#000",
									fontSize: "20px",
									marginRight: "10px",
								}}
							></i>
						</Link>
						<Popconfirm
							title="Are you sure , you want to delete the record?"
							onConfirm={() => {
								dispatch(deleteTicket(row._id))
							}}
							onCancel={() => {}}
							okText="Yes"
							cancelText="No"
						>
							{" "}
							<span style={{ cursor: "pointer" }}>
								<i
									class="fa-regular fa-trash-can"
									style={{
										color: "#000",
										fontSize: "20px",
										marginRight: "10px",
									}}
								></i>
							</span>{" "}
						</Popconfirm>
					</div>
				</>
			),
		},
	]
	return (
		<LayoutWrapper>
			<Layout className="layout-container">
				<Layout
					className="site-layout"
					style={{
						transition: "0.15s ease all",
						minHeight: "100vh",
						background: "#fafafa",
						padding: 0,
					}}
				>
					<Content>
						{children}
						<div className="main-contnt">
							<div className="top-header">
								<div className="left-in">
									<h2 className="mb-0">Dashboard</h2>
									<p>Welcome to Fomo</p>
								</div>
							</div>
							<Row>
								<Col span={6} onClick={()=>navigate("/userManagement")}>
									<div className="card-dashboard first" >
										<div className="right-cnt">
											<Image src={usersImg} />
										</div>
										<div className="left-cnt">
											<h5 className="events-total">{userData.length}</h5>
											<p>Total Users</p>
										</div>
									</div>
								</Col>
								<Col span={6} onClick={()=>navigate("/tickets")}>
									<div className="card-dashboard second">
										<div className="right-cnt">
											<Image src={organizerImg} />
										</div>
										<div className="left-cnt">
											<h5 className="events-total">{ticketData.length}</h5>
											<p>Total Tickets</p>
										</div>
									</div>
								</Col>
								<Col span={6} onClick={()=>navigate("/booking")}>
									<div className="card-dashboard third" >
										<div className="right-cnt">
											<Image src={plannerImg} />
										</div>
										<div className="left-cnt">
											<h5 className="events-total">{bookingData?.length}</h5>
											<p>Total Bookings</p>
										</div>
									</div>
								</Col>
								<Col span={6} onClick={()=>navigate("/events")}>
									<div className="card-dashboard fourth">
										<div className="right-cnt">
											<Image src={eventTotal} />
										</div>
										<div className="left-cnt">
											<h5 className="events-total">{eventData?.length}</h5>
											<p>Total Events</p>
										</div>
									</div>
								</Col>
							</Row>
							<Row className="revenuet-row">
								<Col span={12}>
									<Card>
										<div className="total-revenue">
											<div className="revenue-top">
												<div className="revenue-left">
													<h4 className="revenue">Revenue</h4>
													<p>
														Lorem ipsum dolor sit amet, consectetur
														adipiscing elit.
													</p>
												</div>
												<div className="revenue-right">
													<select
														className="ant-select"
														// name="value"
														value={value}
														onChange={handleChange}
														// defaultValue="days"
													>
														<option value="days">Weekly</option>
														<option value="month">Monthly</option>
													</select>
												</div>
											</div>
											{value === "days" ? <Chart1 /> : <Chart />}
										</div>
									</Card>
								</Col>
								<Col span={12}>
									<Card>
										<div className="upcoming-events">
											<div className="revenue-top">
												<div className="revenue-left">
													<h3 className="revenue">Upcoming Events</h3>
													{/* <p>
														Lorem ipsum dolor sit amet, consectetur
														adipiscing elit.
													</p> */}
												</div>
												<div className="revenue-right">
													<a
														href="#"
														className="see-all"
														onClick={() => navigate("/events")}
													>
														See All
													</a>
												</div>
											</div>
											<ul className="upcomming-events">
												{eventData.slice(0, 3).map((value) => (
													<>
														<li>
															<div className="upcome-col">
																<img
																	class="event-locimg"
																	src={value.image[0]}
																	alt="user"
																/>
																<div>
																	<h6 className="mb-1">
																		<strong>
																			{value.name}
																		</strong>
																	</h6>
																	<p className="location-events">
																		<img src={location} />
																		{value.location}
																	</p>
																</div>
															</div>
														</li>
													</>
												))}
											</ul>
										</div>
									</Card>
								</Col>
							</Row>
							{/* <Row>
              <Col span={12} className="chart-first">
                  <Card>
                    <div className="card-header user-manage-search">
                      <h3>People</h3>
                      <div className="blue m-0">
                        <button
                          class="ant-btn ant-btn-primary ant-btn-sm"
                          style={{ marginLeft: "750px" }}
                          onClick={() => navigate("/userManagement")}
                        >
                          View all
                        </button>
                      </div>
                    </div>

                    <Table
                      columns={columns}
                      dataSource={userData?.slice(0, 5)}
                    />
                  </Card>
                </Col>
                </Row> */}
							<Row>
								<Col span={24} className="chart-first first">
									<Card>
										<div className="card-header user-manage-search">
											<h3>People</h3>
											<div className="blue m-0">
												<button
													class="ant-btn ant-btn-primary ant-btn-sm"
													style={{ marginLeft: "750px" }}
													onClick={() => navigate("/userManagement")}
												>
													View all
												</button>
											</div>
										</div>

										<Table
											columns={columns}
											dataSource={userData?.slice(0, 5)}
										/>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<Card>
										<div className="total-revenue">
											<div className="revenue-top">
												<div className="revenue-left">
													<h4 className="revenue">Returning Customers</h4>
													<p>
														Lorem ipsum dolor sit amet, consectetur
														adipiscing elit.
													</p>
												</div>
											</div>
											<Chart3 />
										</div>
									</Card>
								</Col>
								<Col span={12}>
									<Card>
										<div className="total-gender">
											<div className="revenue-top">
												<div className="revenue-left">
													<h4 className="revenue">Total Genders</h4>
													<p>
														Lorem ipsum dolor sit amet, consectetur
														adipiscing elit.
													</p>
												</div>
											</div>
											<Chart2 />
										</div>
									</Card>
								</Col>
							</Row>
							<Row>
								<Col span={24} className="chart-first second mt-0 pt-0">
									<Card>
										<div className="card-header">
											<h3>Tickets</h3>
											<div className="blue m-0">
												<button
													class="ant-btn ant-btn-primary ant-btn-sm"
													style={{ marginLeft: "750px" }}
													onClick={() => navigate("/tickets")}
												>
													View all
												</button>
											</div>
										</div>
										<Table
											columns={columns2}
											dataSource={ticketData?.slice(0, 3)}
										/>
									</Card>
								</Col>
							</Row>
						</div>
					</Content>
				</Layout>
			</Layout>
		</LayoutWrapper>
	)
}

export default Dashboard
