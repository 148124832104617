import styled from "styled-components"

export const Wrapper = styled.div`
	max-width: 100%;
	margin: 0 auto;
	.ant-spin-dot-item {
		background-color: #235cea !important;
	}
`
export const Card = styled.div`
	flex-basis: 700px;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 3px 4px #eee;
`

export const FieldName = styled.p`
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 100%;
	/* identical to box height, or 16px */
	color: #5e5e5e;
`

export const Heading = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 0px;
	margin-bottom: 10px;
`
export const FormWrapper = styled.div`
	.ant-form {
		width: 100%;
	}
	.ant-input-affix-wrapper {
		height: 44px;
		border-radius: 4px;
		background: #fff !important;
		border: 1px solid #70707089;
	}

	.ant-input-password-icon.anticon {
		color: #000 !important;
	}
	.ant-input {
		/* Neutral/Astellas Gray */

		border: 0px solid #939597;
		background: #fff !important;
		box-sizing: border-box;
		border-radius: 4px;
		height: 44px;
		background: none;
		color: #000;
	}
	label {
		font-size: 16px !important;
	}

	span.ant-input-affix-wrapper {
		padding: 10px;
		height: auto !important;
		border-radius: 10px;
	}

	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}
	.ant-btn {
		/* Primary/Astellas Red */

		background: #235cea;
		border-radius: 8px;
		margin-top: 20px;
		width: 128px;
		border: none;
		height: 43px;
	}

	.ant-input-affix-wrapper > input.ant-input {
		height: 56px;
		background: #fff !important;
		color: #000 !important;
	
		box-sizing: border-box !important;
		border-radius: 10px !important;
	}
	.ant-input-affix-wrapper {
		height: 44px;
		border-radius: 4px;
	}
`
export const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
`

export const ForgotPassword = styled.div`
	margin-top: 12px;
	font-family: DM Sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 112.5%;
	/* identical to box height, or 15px */
	/* Gray 4 */
	color: #bdbdbd;
`

export const ActionLinks = styled.div`
	display: flex;
	flex-basis: 438px;
	justify-content: start;
`
