import { notification } from "antd"
import moment from "moment"
import {
	removeTicket,
	removeTicketScanner,
	requestCompleted,
	requestStart,
	setActiveTicketScanner,
	setSingleTicketScanner,
	setTicket,
	setTickets,
	setTicketScanner,
} from "redux/app"
import { axiosIntercepter } from "redux/axios"
import { axios } from "lib/axios"
import { getAllTickets } from "../ticket"

export function getAllTicketScanner() {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/ticket-scanner`)
		return dispatch(setTicketScanner(response.data))
	}
}
export function singleTicketScanner(id) {
	return async (dispatch) => {
		let response = await axiosIntercepter.get(`/ticket-scanner/${id}`)
		return dispatch(setSingleTicketScanner(response.data))
	}
}
export function addTicketScanner(payload, eventId, image) {
	return (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (image) {
			data.append("image", image)
		}
		data.append("name", payload.name)
		data.append("email", payload.email)
		data.append("events", eventId)

		var config = {
			method: "post",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: "/ticket-scanner",
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTickets())
					notification["success"]({
						message: "Ticket added successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message:
							response?.data.status_message ==
							"Ticket Scanner validation failed: image: Path `image` is required."
								? "PLease add image ."
								: "Ticket not created",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function editTicketScanner(payload, Event_id, id, image) {
	return (dispatch) => {
		var formData = require("form-data")
		var data = new formData()
		if (image) {
			data.append("image", image)
		}
		data.append("name", payload.name)
		data.append("email", payload.email)
		data.append("events", Event_id)
		var config = {
			method: "put",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			url: `/ticket-scanner/${id}`,
			data: data,
		}
		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(requestCompleted())
					dispatch(getAllTicketScanner())
					notification["success"]({
						message: "Ticket Scanner Updated successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Ticket Scanner not Updated",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/delete/${id}`)
		return dispatch(removeTicketScanner({ id }))
	}
}
export function activeTicketScanner(id) {
	return async (dispatch) => {
		await axiosIntercepter.put(`/ticket-scanner/active/${id}`)
		return dispatch(setActiveTicketScanner({ id }))
	}
}
export function getTickets(id) {
	return async (dispatch) => {
		const response = await axiosIntercepter.get(`/ticket/event/${id}`)
		return dispatch(setTickets(response.data))
	}
}
