import { Menu, Dropdown, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { profileData, setAuthenticated } from "redux/app"
import { useDispatch, useSelector } from "react-redux"
import downArrow from "assets/icons/downblck.png"
const Logout = () => {
	const userData = useSelector(profileData)
	const navigate = useNavigate()
	const dispatch = useDispatch()
   
   
	let menu = (
		<Menu className="main-menu" style={{ padding: "10px" }}>
			<Menu.Item
				key="0"
				style={{
					background: "#235cea",
					margin: "2px 0",
					borderRadius: "4px",
					color: "#fff",
				}}
				onClick={() => {
					navigate("/profile")
				}}
			>
				Profile
			</Menu.Item>

			<Menu.Item
				key="0"
				style={{
					background: "#235cea",
					margin: "2px 0",
					borderRadius: "4px",
					color: "#fff",
				}}
				onClick={() => {
					navigate("/reset")
				}}
			>
				Change Password
			</Menu.Item>
			<Menu.Item
				key="0"
				style={{
					background: "#235cea",
					margin: "2px 0",
					borderRadius: "4px",
					color: "#fff",
				}}
				onClick={() => {
					dispatch(setAuthenticated(false))
					localStorage.removeItem("token")
					navigate("/login")
				}}
			>
				Logout
			</Menu.Item>
		</Menu>
	)
	return (
		<div style={{ marginRight: 10 }}>
			<Avatar src={userData.image} size={32} icon={<UserOutlined />} />
			<Dropdown overlay={menu} trigger={["hover"]}>
				<img src={downArrow} alt="" />
			</Dropdown>
		</div>
	)
}

export default Logout
