import { notification } from "antd"
import {
	requestCompleted,
	requestFailure,
	requestStart,
	setFAQs,
	addNewFAQ,
	removeFAQ,
	updateFAQStatus,
	setFaq,
} from "redux/app"
import { axios } from "lib/axios"
import { axiosIntercepter } from "redux/axios"

export function addFAQ(payload,navigate) {
	return async (dispatch) => {
		var data = JSON.stringify({
			question: payload.question,
			answer: payload.answer,
			status:payload.status,
		})

		var config = {
			method: "post",
			url: "/faq",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data: data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: response.data.status_message,
						duration: 2,
					})
					dispatch(addNewFAQ(response.data.data))
					navigate("/faq")
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "FAQ Does Not Added Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function getAllFAQs() {
	return async (dispatch) => {
		var config = {
			method: "get",
			url: "/faq",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(setFAQs(response.data.data))
				}
				dispatch(requestFailure())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function deleteFAQ(id) {
	return async (dispatch) => {
		var config = {
			method: "patch",
			url: `/faq/delete/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "FAQ Deleted Successfully",
						duration: 2,
					})
					dispatch(removeFAQ(id ))
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "FAQ Does Not Delete Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function updateFAQ(id, status) {
	return async (dispatch) => {
		var data = JSON.stringify({ status })
		var config = {
			method: "patch",
			url: `/faq/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					notification["success"]({
						message: "Status Updated Successfully",
						duration: 2,
					})
					dispatch(updateFAQStatus({ id, status }))
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Status Does Not Updated Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}

export function updateFaq(id, payload) {
	return async (dispatch) => {
		var data = JSON.stringify(payload)
		var config = {
			method: "patch",
			url: `/faq/${id}`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
				"Content-Type": "application/json",
			},
			data,
		}

		axios(config)
			.then(function (response) {
				if (response.data.status_code === 200) {
					dispatch(getAllFAQs())
					notification["success"]({
						message: "Faq Updated Successfully",
						duration: 2,
					})
				} else if (response.data.status_code === 401) {
					notification["error"]({
						message: response.data.status_message,
						duration: 2,
					})
				} else {
					notification["error"]({
						message: "Faq Does Not Updated Successfully",
						duration: 2,
					})
				}
				dispatch(requestCompleted())
			})
			.catch(function (error) {
				notification["error"]({
					message: "Error Occured",
					duration: 2,
				})
				dispatch(requestCompleted())
			})
		dispatch(requestStart())
	}
}
export function singleFaq(id) {
	return async (dispatch) => {
		dispatch(requestStart())
		let response = await axiosIntercepter.get(`/faq/${id}`)
		return dispatch(setFaq(response.data))
	}
}
